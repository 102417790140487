export const saudacao = (showEmoji) => {
  let agora = new Date();
  let hora = agora.getHours();

  if (hora < 12) {
    return `Bom dia! ${showEmoji ? '😎' : ''}`;
  } else if (hora < 18) {
    return `Boa tarde! ${showEmoji ? '😊' : ''}`;
  } else if (hora <= 23) {
    return `Boa noite! ${showEmoji ? '😄' : ''}`;
  }
};
