export const currencyDisplay = (value = 0.0, digits) => {
    //console.log('[currencyDisplay]', value);
    if (typeof value == "string") {
        value = value.replace(",", ".");
    }

    if (typeof digits === "undefined") {
        digits = 0
    }

    /* console.log("[currencyDisplay]", digits) */
    return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: digits, maximumFractionDigits: digits })
}