/**
 * @param {String} link link to open in a new tab
 */

const openLinkInNewTab = (link) => {
  const anchor = document.querySelector('#anchor-link');

  anchor.href = link;
  anchor.click();
  anchor.href = '#';
};

export default openLinkInNewTab;
