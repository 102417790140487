import React from 'react';
import Loader from 'react-loader-spinner';
import { theme } from './theme';
// import { Container } from './styles';

function LoaderZelo({ className = '', close }) {
  return (
    <div className={`loader-container ${close ? 'close' : ''}`} style={{backgroundColor: theme.headerBgColor}}>
      <img src="/11/logo-400x.png" alt="logo" style={{ marginBottom: '3rem', width: 150 }}/>
      <Loader
        type="Oval"
        color={theme.botBubbleColor}
        height={30}
        width={30}
        // timeout={20000} //20 secs max
      />
    </div>
  );
}

export default LoaderZelo;
