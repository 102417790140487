import React, { useMemo, useState } from 'react';

import { Container } from './styles';
import { AiFillCamera } from 'react-icons/ai';

function ImagePicker({ triggerNextStep, step }) {
  const [file, setFile] = useState(null);

  const preview = useMemo(() => (file ? URL.createObjectURL(file) : null), [
    file,
  ]);

  const handleChange = (file) => {
    step.value = file
    setFile(file)
    triggerNextStep()
  }

  return (
    <Container>
      <label
        htmlFor="file-picker"
        style={{
          backgroundImage: `url(${preview})`,
        }}
        className={file ? 'has-thumbnail' : ''}
      >
        <AiFillCamera size={30} color="#d3d3d3" />
      </label>
      <input
        type="file"
        name="file-picker"
        id="file-picker"
        accept="image/*"
        onChange={(e) => handleChange(e.target.files[0])}
      />
    </Container>
  );
}

export default ImagePicker;
