import { format } from 'date-fns';
import axios from 'axios';

export default class GrupoZelo {
  retry = 1;
  useDev = false;

  static async init(options = { useDev: false }) {
    const {
      data: { token },
    } = await axios.post(
      `${options.useDev
        ? process.env.REACT_APP_GZ_API_DEV
        : process.env.REACT_APP_GZ_API
      }/login`,
      {
        email: process.env.REACT_APP_GZ_EMAIL,
        senha: process.env.REACT_APP_GZ_PASSWORD,
      }
    );

    return new GrupoZelo(token, options.useDev);
  }

  constructor(token, useDev) {
    if (!token) {
      throw new Error('Invalid token');
    }

    this.useDev = useDev;

    this.transporter = axios.create({
      baseURL: useDev
        ? process.env.REACT_APP_GZ_API_DEV
        : process.env.REACT_APP_GZ_API,
      headers: {
        'x-auth-token': token,
      },
    });

    this.transporter.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error?.response?.status >= 500 && this.retry < 10) {
          this.retry++;
          return this.transporter(error.config);
        }

        return Promise.reject(error);
      }
    );

    this.paymentTransporter = axios.create({
      baseURL: useDev
        ? process.env.REACT_APP_GZ_API_PAYMENT
        : process.env.REACT_APP_GZ_API_PAYMENT,
      headers: {
        'x-auth-token': token,
      },
    });
  }

  async getAssociadoAtrasado({
    incricao = null,
    page = 1,
    itemsPerPage = 25,
    code = [],
  }) {
    const { data } = await this.transporter.post(
      '/associados-vendedor/todos-atrasados',
      {
        codigo: code,
        pagina: page,
        itens: itemsPerPage,
        dataReferencia: format(new Date(), 'dd/MM/yyyy'),
        inscricao: incricao,
      }
    );

    return data;
  }

  async getInscricao(insc) {
    const { data } = await this.transporter.get(`/inscricao/${insc}`);

    return data;
  }

  async getAssociado(insc) {
    const {
      data: [associado],
    } = await this.transporter.get(`/associados/${insc}`);

    return associado;
  }

  async getCityByCode(code) {
    const { data } = await this.transporter.get(`/cidades/${code}`);

    return data;
  }



  async generateBillet(params) {
    if (this.useDev) {
      const { data } = await axios.post(
        `${process.env.REACT_APP_GZ_API_PAYMENT_DEV}/boleto/`,
        params
      );

      return {
        fileUrl: data.action.downloadUrl,
        barCode: data.action.reference,
      };
    }

    // const {
    //   data: { accessToken },
    // } = await this.paymentTransporter.post('/auth', {
    //   email: process.env.REACT_APP_GZ_EMAIL,
    //   password: process.env.REACT_APP_GZ_PASSWORD_PAYMENT,
    // });

    // const { data } = await this.paymentTransporter.post('/boleto/', params, {
    //   headers: { Authorization: accessToken },
    // });

    const { data } = await this.paymentTransporter.post('/boleto/', params);

    return { fileUrl: data.action.downloadUrl, barCode: data.action.reference };
  }
}
