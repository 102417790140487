export const truncateText = (text, charLimit) =>
    text && text.length > charLimit ? text.slice(0, charLimit).concat('...') : text

export const splitText = (text, charLimit) => {
    console.log('[splitText]', text);
    if (text.length > 0) {
        return text.match(/.{1,12}/g).join(' ');
    } else {
        return '';
    }
}

/*eslint no-useless-escape: "off"*/
export const formatCnpj = (text = '') => {
    if (text.length > 12) return text.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/g, '$1.$2.$3/$4-')
    if (text.length > 8) return text.replace(/(\d{2})(\d{3})(\d{3})/g, '$1.$2.$3/')
    if (text.length > 5) return text.replace(/(\d{2})(\d{3})/g, '$1.$2.')
    if (text.length > 2) return text.replace(/(\d{2})(\d)/g, '$1.$2')

    return text.replace(/\D/g, '')
}

/*eslint no-useless-escape: "off"*/
export const formatPhone = (text = '') => text.replace(/(\d{2})(\d{4,5})(\d{4})/g, '($1) $2-$3')

export const extractNumber = text => text.replace(/\D/g, '')

// returns text with the first capital letter (all words, works with all caps)
export const textCapitalize = text => {
    if (!text) return ''
    let lower = text.toLowerCase()

    let words = lower.split(' ')

    return words.reduce((prev, w, index, arr) => {
        let word = w.charAt(0).toUpperCase() + w.slice(1)
        if (index < arr.length - 1) {
            word += ' '
        }
        return prev + word
    }, '')
}

export const textCapitalizeCamelCase = (text) => {
    if (!text) return ''
    let lower = text.toLowerCase()
    return lower.charAt(0).toUpperCase() + lower.slice(1)
}

export const firstWord = (text) => {
    if (!text) return ''
    return text.split(' ').slice(0, 1).join('')
}
