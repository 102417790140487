import { isWeekend } from 'date-fns';
import { addDays, isFriday, isDate, isSaturday } from 'date-fns/esm';

/**
 * Add days to a date
 * @param {Date} date initial date to add days
 * @param {Number} days number of days that will be added to initial date
 * @param {Boolean} onlyTheLastDay Determine if the days number will be added only if the final date is week end
 */

function addUtilDays(date, days, onlyTheLastDay) {
  if (!isDate(date)) {
    throw new TypeError('Date param must be a date');
  }
  let addDaysDate = date;

  if (onlyTheLastDay) {
    let addedDays = addDays(date, days);

    if (isWeekend(addedDays)) {
      const additionals = isSaturday(addedDays) ? 2 : 1;
      addedDays = addDays(addedDays, additionals);
    }

    return addedDays;
  }

  for (let i = 1; i < days; i++) {
    if (isFriday(addDaysDate)) {
      addDaysDate = addDays(addDaysDate, 2);
    }
    addDaysDate = addDays(addDaysDate, 1);
  }

  return addDaysDate;
}

export default addUtilDays;
