import { Component, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
// import axios from 'axios';
import { format, parseISO } from 'date-fns';
import moment from 'moment';
import Chatbot, { Loading } from 'react-simple-chatbot';

//import WaitResponse from '../../../components/WaitResponse';
import Spinner from '../../../components/loaders/spinner';
import Ops from '../../../components/Ops';
// import { saudacao } from '../../../components/chat/saudacao';
// import ExportOptions from '../../../components/ExportOptions';
import { header } from './Header';

import { ResourcesContext } from '../../../context/ResourcesContext';
// import { textCapitalize } from '../../../utils/text';
// import addUtilDays from '../../../utils/addUtilDays';
import { currencyDisplay } from '../../../utils/currency';
import vyaApi, { billetApi } from '../../../services/api';
import validateId from '../../../utils/validateId';
import { sendPutRequest } from '../../../lib/sendPutRequest';
import { alterMetaTag } from '../../../components/chat/metaTag';

import { theme } from './theme';
import ExportV2 from '../../../components/ExportV2';
import WaitBillet from './WaitBillet';
import CustomOptions from '../../../components/CustomOptions';
import { flexFormat } from '../../../utils/flexFormat';
// import verifyCpf from '../../../utils/verifyCpf';

class BradescoLpNegociacao extends Component {
  static contextType = ResourcesContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loadingMessage: true,
      error: false,
      result: undefined,
      selectedOptionValue: null,
      data: {},
      typeCpfTries: 0,
      userEmailIsCorrect: true,
    };

    this.sendPutRequest = sendPutRequest(this);
  }

  componentWillMount() {
    alterMetaTag(
      'Bradesco LP negociação',
      /\/[0-9]/.exec(this.props.match.path),
      '#e52645'
    );
    this.handleEnd = this.handleEnd.bind(this);
  }

  async componentDidMount() {
    // obrigatório
    billetApi.post('/code-start');
    if (!validateId(this.props.match.params.id)) {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage:
          'Identificamos informações incorretas. Tente novamente mais tarde.',
      });
      return;
    }
    let urlParameters = this.props.match.params.id.split('_');
    urlParameters = 'id=' + urlParameters[0] + '&idAcao=' + urlParameters[1];
    let sessionChat =
      this.props.match.params.id + moment().format('YYYYMMDD_HHMMSS');

    this.setState({ sessionChat: sessionChat });

    try {
      const { data } = await vyaApi.get(`Contato?${urlParameters}`);

      let result = JSON.parse(data.Data);
      console.log('[resultVyaApi]', result);
      if (result.hasOwnProperty('Nome') || result.hasOwnProperty('nome')) {
        this.setState({
          result: {
            ...result,
            emails: result.emails || result.email,
            contratos: result.contratos || result.contrato
          },
        });
        this.setState({ isLoading: false, error: false });
        this.sendPutRequest({
          id: 'API',
          message: data.Message,
          value: data.Status,
        });
      } else {
        this.setState({ isLoading: false, error: true });
        this.sendPutRequest({
          id: 'API',
          message: 'erro api',
          value: 'error',
        });
      }
    } catch (error) {
      console.log('[Contato-error]', error);
      this.setState({ isLoading: false, error: true });
      this.sendPutRequest({
        id: 'API',
        message: 'erro api',
        value: 'error',
      });
    }
  }

  handleEnd({ steps, values }) {
    if (!this.state.stepFinal) {
      return;
    }


    var data = JSON.stringify({
      id: this.state.result.id,
      idAcao: this.state.result.idAcao,
      session: this.state.sessionChat,
      step: steps.agradecimento.id,
      frase: steps.agradecimento.message,
      resposta: '',
      status: this.state.status,
      statusCode: this.state.statusCode,
    });

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi
      .post('/ContatoMidia', data)
      .then(function (response) {
        alert('Obrigado. Terminamos seu atendimento.');
        console.log(
          '[sendPutRequest-' + steps.id + ']',
          JSON.stringify(response.data)
        );
      })
      .catch(function (error) {
        console.log('[sendPutRequest-' + steps.id + ']', error);
      });
  }

  InfoContato = () => (
    <p>
      Para conseguir maiores informações, ligue para:{' '}
      <a href="tel:08007275120" className="link">
        0800 727 5120
      </a>
      , temos algo muito importante para falar com você.
    </p>
  );

  Export = ({ steps }) => (
    <ExportV2
      eventTitle="Pagar Débito Bradesco"
      eventDetailsLabel={`Débito Bradesco no valor de ${currencyDisplay(
        steps.initial.metadata.valor_total,
        2
      )}`}
      eventDetails={`Débito Bradesco no valor de <strong>${currencyDisplay(
        steps.initial.metadata.valor_total,
        2
      )}</strong>`}
      eventDay={
        parseISO(flexFormat(steps.initial.metadata.vencimento, 'YMD')) ||
        steps.escolher_data?.value ||
        new Date()
      }
      barCode={steps.initial.metadata.bol_linha_digitavel || ''}
      handleEnd={(id, message) => {
        let stepFinal = { id, message, value: '' };
        this.setState({ stepFinal });
        this.handleEnd({ steps });
      }}
      onDownloadBillet={() => {
        this.setState({ statusCode: '116', status: 'DBOL' }, () => this.sendPutRequest(steps.exportar_boleto));
      }}
      billet={steps.gerar_boleto.value.openFile}
    />
  );

  render() {
    const { isLoading, result, error } = this.state;

    if (isLoading) {
      return (
        <div className="App">
          <Spinner color={theme.botBubbleColor} />
        </div>
      );
    }

    if (error) {
      return (
        <Ops
          theme={theme}
          headerComponent={header}
          botAvatar="/1/avatar.png"
          message={this.state.errorMessage ? this.state.errorMessage : null}
        />
      );
    }
    const steps = [
      {
        id: 'initial',
        message:
          'Olá! Sou eu novamente, Paulo agente virtual da System, por aqui vou disponilizar o boleto do seu acordo.',
        metadata: result,
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '80', status: 'HUMA' }, () => this.sendPutRequest(steps.initial));

          return 'solicitar_cpf';
        },
      },
      {
        id: 'solicitar_cpf',
        message: 'Para sua segurança, confirme os 4 últimos dígitos do seu CPF',
        trigger: () => {
          this.setState({ typeCpfTries: this.state.typeCpfTries + 1 });
          return 'get_cpf';
        },
      },
      {
        id: 'get_cpf',
        user: true,
        validator: (value) => {
          if (isNaN(value)) {
            return 'Por favor digite apenas número';
          }
          return true;
        },
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '83', status: 'CPFD' }, () => this.sendPutRequest(steps.get_cpf));

          if (value.slice(-4) === steps.initial.metadata.CPFCNPJ.slice(-4)) {
            return 'info_negociacao';
          } else {
            return 'cpf_nao_encontrado';
          }
        },
      },
      {
        id: 'info_negociacao',
        message: ({ prev, steps }) =>
          `${result.nome}, para confirmar, você escolheu pagar ${result.quantidade_parcelas
          } parcela(s), no valor de ${currencyDisplay(
            result.valor_total,
            2
          )} no dia ${result.vencimento}`,
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '78', status: 'SIM' }, () =>
            this.sendPutRequest(steps.info_negociacao)
          );

          return 'gerar_boleto';
        },
      },
      {
        id: 'gerar_boleto',
        component: <WaitBillet data={this.state.result} />,
        replace: true,
        waitAction: true,
        asMessage: true,
        trigger: 'receber_email',
      },

      // s-send-email
      {
        id: 'receber_email',
        message: 'Posso te enviar o boleto por e-mail?',
        delay: 0,
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.receber_email);

          return 'receber_email_options';
        },
      },
      {
        id: 'receber_email_options',
        component: (
          <CustomOptions
            options={['sim', 'não']}
            primaryColor={theme.headerBgColor}
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '116', status: 'ACP1' }, () =>
            this.sendPutRequest(steps.receber_email_options)
          );
          //this.sendPutRequest(steps.receber_email_options);
          if (value === 'sim') {
            if (
              steps.initial.metadata['emails'] &&
              this.state.userEmailIsCorrect
            ) {
              return 'confirmar_email';
            }

            return 'perguntar_email';
          } else {
            return 'recusa_email';
          }
        },
      },

      {
        id: 'perguntar_email',
        message: 'Qual o seu email?',
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.perguntar_email);

          return 'digitar_email';
        },
      },
      {
        id: 'digitar_email',
        user: true,
        validator: (value) => {
          if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value.trim())) {
            return 'Email inválido!';
          }
          return true;
        },
        trigger: 'confirmar_email',
      },
      {
        id: 'confirmar_email',
        message: ({ previousValue, steps }) => {
          return `O email esta correto?
${!this.state.userEmailIsCorrect
              ? previousValue
              : steps.initial.metadata['emails'] || previousValue
            }`;
        },
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.confirmar_email);
          return 'opcoes_confirmar_email';
        },
      },
      {
        id: 'opcoes_confirmar_email',
        component: (
          <CustomOptions
            options={['sim', 'não']}
            primaryColor={theme.headerBgColor}
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          if (value === 'sim') {
            this.setState({ statusCode: '116', status: 'NEMAIL' }, () => this.sendPutRequest(steps.opcoes_confirmar_email));

            return 'enviar_email';
          } else {
            if (
              steps.initial.metadata['emails'] &&
              this.state.userEmailIsCorrect
            ) {
              this.setState({ userEmailIsCorrect: false });
            }
            return 'perguntar_email';
          }
        },
      },
      {
        id: 'enviar_email',
        message: ({ _, steps }) =>
          `Seu boleto será enviado em até 24 horas para o ${!this.state.userEmailIsCorrect
            ? steps.digitar_email?.value
            : steps.initial.metadata['emails'] || steps.digitar_email?.value
          }`, // o numero tem que ser uma variavel recebida da api aqui ta mosdo de demonstraçao
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '116', status: 'SEMAIL' }, () => this.sendPutRequest(steps.enviar_email));
          const serializeProduct = result.produtos ? result.produtos.split('|').map(p => p.trim()) : []

          billetApi({
            method: 'post',
            url: '/email/send',
            data: {
              sender: {
                name: 'System Interact',
                //logo: 'https://i.ibb.co/3rXVrF2/vya-logo-black.png',
              },
              destination: {
                name: result.nome_cliente,
                email: !this.state.userEmailIsCorrect
                  ? steps.digitar_email?.value
                  : steps.initial.metadata['emails'] ||
                  steps.digitar_email?.value,
              },
              content: {
                template: 'bradesco',
                subject: 'Boleto Acordo Bradesco',
                dueDate: result.vencimento,
                value: currencyDisplay(result.valor_total, 2),
                templateValues: {
                  qtdParcelas: result.quantidade_parcelas,
                  cpf: result.CPFCNPJ,
                  produtos: serializeProduct
                },
                attachments: [steps.gerar_boleto.value.openFile],
              },
            },
          }).catch((error) => {
            console.log(error.response.data);
          });

          return 'verificar_spam';
        },
      },

      {
        id: 'verificar_spam',
        message:
          'Confira na caixa de entrada ou spam. Pague até o vencimento para não perder os descontos!',
        trigger: 'parabenizar_acordo',
      },

      // e-send-email

      {
        id: 'recusa_email',
        message:
          'Tudo bem, mas lembre se, pague até o vencimento para não perder descontos!',
        trigger: 'duvidas',
      },

      {
        id: 'duvidas',
        component: (
          <p>
            Caso tenha alguma dúvida sobre o acordo ligue:{' '}
            <a href="tel:08007275120" className="link">
              0800 727 5120
            </a>
          </p>
        ),
        asMessage: true,
        trigger: 'parabenizar_acordo',
      },

      {
        id: 'parabenizar_acordo',
        message:
          'Parabéns pelo seu acordo! Você também pode baixar seu boleto no menu abaixo. O seu boleto possui uma senha, que é os 4 últimos dígitos do seu CPF. Para lembrar a data do vencimento do seu boleto podemos colocar lembrete em sua agenda.',
        trigger: 'exportar_boleto',
      },
      {
        id: 'exportar_boleto',
        component: <this.Export />,
        trigger: 'agradecimento',
      },

      {
        id: 'info_contato',
        component: <this.InfoContato />,
        asMessage: true,
        trigger: ({ steps }) => {
          this.setState({ statusCode: '91', status: 'NCPF' }, () => this.sendPutRequest(steps.info_contato));

          return 'agradecimento';
        },
        delay: 2000,
      },

      {
        id: 'agradecimento',
        message: 'A System agradece sua atenção!',
        end: true,
      },
      {
        id: 'cpf_nao_encontrado',
        message: 'Desculpe, não localizei o seu cpf.',
        trigger: ({ steps }) => {
          this.setState({ statusCode: '79', status: 'NAO' }, () => this.sendPutRequest(steps.cpf_nao_encontrado));

          if (this.state.typeCpfTries > 2) {
            return 'info_contato';
          }
          return 'solicitar_cpf';
        },
      },
    ];

    const optionStyles = {
      background: 'transparent',
      border: `1.5px solid ${theme.botBubbleColor}`,
      borderRadius: 25,
      color: theme.botBubbleColor,
      cursor: 'pointer',
    };

    return (
      <ThemeProvider theme={theme}>
        <Chatbot
          className="bradesco --no-padding-top"
          headerComponent={header}
          recognitionEnable={false}
          placeholder="digite aqui..."
          botDelay={500}
          floating={false}
          handleEnd={this.handleEnd}
          {...this.props}
          steps={steps}
          botAvatar={'/1/avatar.png'}
          bubbleOptionStyle={optionStyles}
          submitButtonStyle={{ fill: theme.botBubbleColor }}
        />
      </ThemeProvider>
    );
  }
}

export default BradescoLpNegociacao;
