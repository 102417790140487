import Discuss from '../../assets/images/loaders/discuss.svg';

import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    margin-bottom: 10px;
  }

  span {
    font-weight: bold;
    color: #333;
  }

  @media screen and (max-width: 568px) {
    & {
      height: calc(var(--vh, 100vh) * 100) !important;
    }
  }
`;

const phrases = ['Carregando', 'Aguarde um momento', 'Só mais um pouco'];

class DiscussLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phrase: phrases[0],
      index: 0,
    };
  }

  componentDidMount() {
    this.timeoutID = setInterval(() => {
      this.setState({ index: this.state.index + 1 });

      if (this.state.index < 3) {
        this.setState({ phrase: phrases[this.state.index] });
      } else {
        clearInterval(this.timeoutID);
      }
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.timeoutID);
  }
  render() {
    return (
      <Container>
        <img src={Discuss} alt="Carregando..." />
        <span>{this.state.phrase}</span>
      </Container>
    );
  }
}

export default DiscussLoader;
