import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import Spinner from '../../../components/loaders/spinner';
import { alterMetaTag } from '../../../components/chat/metaTag';
import Ops from '../../../components/Ops';
import vyaApi from '../../../services/api';
import moment from 'moment';
import { getPassedMinutes } from '../../../utils/date';
import { theme } from './theme';
import { header } from './Header';
import { sendPutRequest } from '../../../lib/sendPutRequest';
import validateId from '../../../utils/validateId';

class InfoContato extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      gender: '',
      age: '',
    };
  }

  componentWillMount() {
    const { steps } = this.props;
    const { name, gender, age } = steps;

    this.setState({ name, gender, age });
  }

  render() {
    //const { name, gender, age } = this.state;
    return (
      <div style={{ width: '100%' }}>
        {/* <h3></h3> */}
        <table>
          <tbody>
            <tr>
              <td>Para capitais e regiões metropolitanas</td>
            </tr>

            <tr>
              <td>
                <a
                  href="tel:40033356"
                  style={{ color: '#fff', textDecoration: 'none' }}
                >
                  <strong>4003-3356</strong>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <br />
              </td>
            </tr>
            <tr>
              <td>Para demais localidades</td>
            </tr>
            <tr>
              <td>
                <a
                  href="tel:08007232960"
                  style={{ color: '#fff', textDecoration: 'none' }}
                >
                  <strong>0800 723 2960</strong>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  Nosso horário de atendimento é de segunda a sexta das 7h às
                  20h e aos sábados das 9h às 15h20
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

InfoContato.propTypes = {
  steps: PropTypes.object,
};

InfoContato.defaultProps = {
  steps: undefined,
};

class BradescoNegociacao extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, error: false, result: undefined };

    this.sendPutRequest = sendPutRequest(this);
  }

  componentWillMount() {
    alterMetaTag(
      'Bradesco negociação',
      /\/[0-9]/.exec(this.props.match.path),
      '#e52645'
    );
    this.handleEnd = this.handleEnd.bind(this);
  }

  componentDidMount() {
    // obrigatório
    if (!validateId(this.props.match.params.id)) {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage:
          'Identificamos informações incorretas. Tente novamente mais tarde.',
      });
      return;
    }
    let urlParameters = this.props.match.params.id.split('_');
    let sessionChat =
      urlParameters[0] +
      '_' +
      urlParameters[1] +
      '_' +
      moment().format('YYYYMMDD_HHMMSS');
    urlParameters = 'id=' + urlParameters[0] + '&idAcao=' + urlParameters[1];
    //console.debug('sessionChat', sessionChat)
    this.setState({ sessionChat: sessionChat });

    vyaApi
      .get(`Contato?${urlParameters}`)
      .then((response) => {
        //console.log('[Contato-response]', response);
        let result = JSON.parse(response.data.Data);
        console.log('[resultVyaApi]', result);
        if (result.hasOwnProperty('Nome') || result.hasOwnProperty('nome')) {
          this.setState({ result: JSON.parse(response.data.Data) });
          this.setState({ isLoading: false, error: false });
          this.sendPutRequest({
            id: 'API',
            message: response.data.Message,
            value: response.data.Status,
          });
        } else {
          this.setState({ isLoading: false, error: true });
          this.sendPutRequest({
            id: 'API',
            message: 'erro api',
            value: 'error',
          });
        }
      })
      .catch((error) => {
        // handle error
        console.log('[Contato-error]', error);
        this.setState({ isLoading: false, error: true });
        this.sendPutRequest({
          id: 'API',
          message: 'erro api',
          value: 'error',
        });
      });
    // .then(function () {
    //   // always executed
    // });
  }

  handleEnd({ steps, values }) {
    var data = JSON.stringify({
      id: this.state.result.id,
      idAcao: this.state.result.idAcao,
      session: this.state.sessionChat,
      step: this.state.stepFinal.id,
      frase: this.state.stepFinal.message,
      resposta: this.state.stepFinal.value,
      status: this.state.status,
      statusCode: this.state.statusCode,
    });

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi
      .post('/ContatoMidia', data)
      .then(function(response) {
        alert('Obrigado. Terminamos seu atendimento.');
        console.log(
          '[sendPutRequest-' + steps.id + ']',
          JSON.stringify(response.data)
        );
      })
      .catch(function(error) {
        console.log('[sendPutRequest-' + steps.id + ']', error);
      });
  }

  render() {
    const { isLoading, result, error } = this.state;

    if (isLoading) {
      return (
        <div className="App">
          <Spinner />
        </div>
      );
    }

    if (error) {
      return (
        <Ops
          theme={theme}
          headerComponent={header}
          botAvatar="/1/avatar.png"
          message={this.state.errorMessage ? this.state.errorMessage : null}
        />
      );
    }

    let steps = [];

    steps = [
      {
        id: 'initial',
        message: 'Olá',
        metadata: this.state.result,
        delay: 350,

        trigger: ({ value, steps }) => {
          sendPutRequest(steps.initial);
          return 'saudacao';
        },
      },
      {
        id: 'saudacao',
        message: ({ previousValue, steps }) => {
          if (steps.initial.metadata.hasOwnProperty('Nome')) {
            return 'Seja bem vindo ' + steps.initial.metadata.Nome.trim();
          } else {
            return 'Seja bem vindo ' + steps.initial.metadata.nome.trim();
          }
        },
        delay: 350,
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.saudacao);
          this.setState({ status: 'OCSV', statusCode: '82' });

          if (steps.initial.metadata.hasOwnProperty('ValidadeLink')) {
            let validadeLink = moment(
              steps.initial.metadata.ValidadeLink,
              'DD/MM/YYYY HH:mm:ss'
            );
            if (getPassedMinutes(validadeLink) > 0) {
              return 'informacao_link_expirado';
            }
          }
          return 'informacao_importante';
        },
      },
      {
        id: 'informacao_link_expirado',
        message: 'Infelizmente esse link de negociação expirou :(',

        trigger: ({ value, steps }) => {
          sendPutRequest(steps.informacao_link_expirado);
          return 'entre_contato_link_expirado';
        },
        delay: 350,
      },
      {
        id: 'informacao_importante',
        message: 'Tenho informações importantes para você',

        trigger: ({ value, steps }) => {
          sendPutRequest(steps.informacao_importante);
          return 'solicita_cpf';
        },
        delay: 350,
      },
      {
        id: 'solicita_cpf',
        message:
          'Para que possamos garantir o sigilo de suas informações, por favor digite os 3 primeiros digitos do seu CPF.',
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.solicita_cpf);
          return 'coletar_cpf';
        },
      },
      {
        id: 'coletar_cpf',
        user: true,
        validator: (value) => {
          //console.log('[value]', value)
          if (isNaN(value)) {
            this.setState({ status: 'OCHZ', statusCode: '76' });
            return 'Por favor digite apenas número';
          }

          return true;
        },
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.coletar_cpf);
          return 'validar_cpf';
        },
      },
      {
        id: 'validar_cpf',
        message: ({ previousValue, steps }) => {
          if (steps.initial.metadata.hasOwnProperty('CpfCnpj')) {
            if (
              previousValue.substring(0, 3) ===
              steps.initial.metadata.CpfCnpj.substring(0, 3)
            ) {
              this.setState({ status: 'OCHA', statusCode: '77' });
              return 'Identificamos que existem débitos pendentes em seu nome.';
            } else {
              this.setState({ status: 'OCHZ', statusCode: '76' });
              return 'Ops!! CPF incorreto';
            }
          }
          if (steps.initial.metadata.hasOwnProperty('cpf')) {
            if (
              previousValue.substring(0, 3) ===
              steps.initial.metadata.cpf.substring(0, 3)
            ) {
              this.setState({ status: 'OCHA', statusCode: '77' });
              return 'Identificamos que existem débitos pendentes em seu nome.';
            } else {
              this.setState({ status: 'OCHZ', statusCode: '76' });
              return 'Ops!! CPF incorreto';
            }
          } else {
            if (
              previousValue.substring(0, 3) ===
              steps.initial.metadata.cpfcnpj.substring(0, 3)
            ) {
              this.setState({ status: 'OCHA', statusCode: '77' });
              return 'Identificamos que existem débitos pendentes em seu nome.';
            } else {
              this.setState({ status: 'OCHZ', statusCode: '76' });
              return 'Ops!! CPF incorreto';
            }
          }
        },
        delay: 350,
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.validar_cpf);
          if (steps.initial.metadata.hasOwnProperty('CpfCnpj')) {
            if (
              steps.coletar_cpf.value.substring(0, 3) ===
              steps.initial.metadata.CpfCnpj.substring(0, 3)
            ) {
              return 'debitos_pendentes';
            } else {
              return 'solicita_cpf';
            }
          }
          if (steps.initial.metadata.hasOwnProperty('cpf')) {
            if (
              steps.coletar_cpf.value.substring(0, 3) ===
              steps.initial.metadata.cpf.substring(0, 3)
            ) {
              return 'debitos_pendentes';
            } else {
              return 'solicita_cpf';
            }
          } else {
            if (
              steps.coletar_cpf.value.substring(0, 3) ===
              steps.initial.metadata.cpfcnpj.substring(0, 3)
            ) {
              return 'debitos_pendentes';
            } else {
              return 'solicita_cpf';
            }
          }
        },
      },

      {
        id: 'debitos_pendentes',
        options: [
          {
            value: 'nao_paguei',
            label: 'Não paguei',
            trigger: ({ value, steps }) => {
              sendPutRequest(steps.debitos_pendentes);
              return 'opcao_pagamento_1';
            },
          },
          {
            value: 'ja_paguei',
            label: 'Já paguei',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'OCHA', statusCode: '77' });
              sendPutRequest(steps.debitos_pendentes);
              return 'japagou_mensagem';
            },
          },
          {
            value: 'desconhece_divida',
            label: 'Desconhece dívida',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'OCHZ', statusCode: '76' });
              sendPutRequest(steps.debitos_pendentes);
              return 'entre_contato_desconhece_divida';
            },
          },
        ],
      },
      {
        id: 'opcao_pagamento_1',
        message: 'Podemos agendar o pagamento para amanhã?',
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.opcao_pagamento_1);
          return 'coletar_opcao_pagamento_1';
        },
      },
      {
        id: 'coletar_opcao_pagamento_1',
        options: [
          {
            value: 'opcao_pagamento_1_sim',
            label: 'Sim',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'OCHA', statusCode: '78' });
              sendPutRequest(steps.coletar_opcao_pagamento_1);
              return 'finaliza_opcao_pagamento_1';
            },
          },
          {
            value: 'opcao_pagamento_1_nao',
            label: 'Não',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'OCHR', statusCode: '80' });
              sendPutRequest(steps.coletar_opcao_pagamento_1);
              return 'opcao_pagamento_5';
            },
          },
        ],
      },

      {
        id: 'opcao_pagamento_5',
        message:
          'Entendi. Podemos agendar o pagamento para os próximos 5 dias?',
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.opcao_pagamento_5);
          return 'coletar_opcao_pagamento_5';
        },
      },
      {
        id: 'coletar_opcao_pagamento_5',
        options: [
          {
            value: 'opcao_pagamento_5_sim',
            label: 'Sim',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'OCHA', statusCode: '78' });
              sendPutRequest(steps.coletar_opcao_pagamento_5);
              return 'finaliza_opcao_pagamento_5';
            },
          },
          {
            value: 'opcao_pagamento_5_nao',
            label: 'Não',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'OCHR', statusCode: '80' });
              sendPutRequest(steps.coletar_opcao_pagamento_5);
              return 'opcao_pagamento_7';
            },
          },
        ],
      },
      {
        id: 'opcao_pagamento_7',
        message:
          'Que pena. Eu consigo agendar o pagamento para no máximo 7 dias?',
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.opcao_pagamento_7);
          return 'coletar_opcao_pagamento_7';
        },
      },
      {
        id: 'coletar_opcao_pagamento_7',
        options: [
          {
            value: 'opcao_pagamento_7_sim',
            label: 'Sim',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'OCHA', statusCode: '78' });
              sendPutRequest(steps.coletar_opcao_pagamento_7);
              return 'finaliza_opcao_pagamento_7';
            },
          },
          {
            value: 'opcao_pagamento_7_nao',
            label: 'Não',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'OCHR', statusCode: '80' });
              sendPutRequest(steps.coletar_opcao_pagamento_7);
              return 'info_recusa';
            },
          },
        ],
      },
      {
        id: 'entre_contato_link_expirado',
        message: 'Por gentileza, entre em contato com a nossa central',
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.entre_contato_link_expirado);
          return 'info_para_contato';
        },
      },
      {
        id: 'entre_contato_desconhece_divida',
        message:
          'Por gentileza, entre em contato com nossa central para maiores informações.',
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.entre_contato_desconhece_divida);
          return 'info_para_contato';
        },
      },
      {
        id: 'info_para_contato',
        component: <InfoContato />,
        asMessage: true,
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.info_para_contato);
          return 'agradecimento_simples';
        },
      },

      {
        id: 'info_recusa',
        message:
          'Como não conseguimos fechar um acordo, por favor, entre em contato com a nossa central de atendimento nos telefones.',
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.info_recusa);
          return 'review';
        },
      },

      {
        id: 'review',
        component: <InfoContato />,
        asMessage: true,
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.review);
          return 'lembrando';
        },
        delay: 1300,
      },

      {
        id: 'lembrando',
        message: ({ previousValue, steps }) => {
          let message =
            'Lembrando que os juros e encargos são diários e seu nome poderá ser incluso nos orgãos de proteção ao crédito. O Bradesco agradece sua atenção!';
          let stepFinal = { id: 'lembrando', message: message, value: '' };
          this.setState({ stepFinal });
          return message;
        },
        end: true,
      },

      {
        id: 'japagou_mensagem',
        message:
          'Ótimo! A confirmação do pagamento é registrada em nosso sistema em até 48 horas.',
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.japagou_mensagem);
          return 'agradecimento';
        },
      },
      {
        id: 'agradecimento',
        message: ({ previousValue, steps }) => {
          let stepFinal = {
            id: 'agradecimento',
            message: 'O banco Bradesco agradece sua atenção.',
            value: '',
          };
          this.setState({ stepFinal });
          return 'O banco Bradesco agradece sua atenção.';
        },
        end: true,
      },
      {
        id: 'agradecimento_simples',
        message: ({ previousValue, steps }) => {
          let message = 'O Bradesco agradece sua atenção.';
          let stepFinal = {
            id: 'agradecimento_simples',
            message: message,
            value: '',
          };
          this.setState({ stepFinal });
          return message;
        },
        end: true,
        delay: 1800,
      },
      {
        id: 'end-error-message',
        message: ({ previousValue, steps }) => {
          let message = 'Desculpe! O Bradesco agradece sua atenção!';
          let stepFinal = {
            id: 'agradecimento_simples',
            message: message,
            value: '',
          };
          this.setState({ stepFinal });
          return message;
        },
        end: true,
      },
      {
        id: 'finaliza_opcao_pagamento_1',
        message:
          'Por gentileza, antes do pagamento retirar um extrato de sua conta corrente para verificar o valor atualizado de sua pendência. Agradecemos o compromisso firmado com o banco Bradesco e somente para informá-lo o agendamento da promessa não inibi a notificação nos órgãos de proteção ao crédito.',
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.finaliza_opcao_pagamento_1);
          return 'agradecimento';
        },
      },
      {
        id: 'finaliza_opcao_pagamento_5',
        message:
          'Por gentileza, antes do pagamento retirar um extrato de sua conta corrente para verificar o valor atualizado de sua pendência. Agradecemos o compromisso firmado com o banco Bradesco e somente para informá-lo o agendamento da promessa não inibi a notificação nos órgãos de proteção ao crédito.',
        trigger: 'agradecimento',
      },
      {
        id: 'finaliza_opcao_pagamento_7',
        message:
          'Por gentileza, antes do pagamento retirar um extrato de sua conta corrente para verificar o valor atualizado de sua pendência. Agradecemos o compromisso firmado com o banco Bradesco e somente para informá-lo o agendamento da promessa não inibi a notificação nos órgãos de proteção ao crédito.',
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.finaliza_opcao_pagamento_7);
          return 'agradecimento';
        },
      },
    ];

    return (
      <ThemeProvider theme={theme}>
        <ChatBot
          headerComponent={header}
          // Avatar do Bot e do usuário //
          botAvatar={/\/[0-9]/.exec(this.props.match.path) + '/avatar.png'}
          // userAvatar='https://i.imgur.com/6sm2DYv.png'

          handleEnd={this.handleEnd}
          recognitionEnable={false}
          recognitionPlaceholder="Escutando ..."
          placeholder="digite aqui..."
          botDelay={700}
          userDelay={500}
          headerTitle="Bradesco"
          floating={false}
          steps={steps}

          // Estilo do Rodapé (Footer) //
          /* footerStyle={{ backgroundColor: '#fff', margin: 5, padding: 1, borderRadius: 7, elevation: 2, }}
        submitButtonStyle={{ backgroundColor: '#E89984', borderRadius: 4, width: 63, margin: 2 }} */
        />
      </ThemeProvider>
    );
  }
}

export default BradescoNegociacao;
