import logo from '../../../../assets/vya_logo.png';
import bg from '../../../../assets/bg.png';

export const header = (
  <a href="https://vya.digital" target="_blank" rel="noreferrer noopened">
    <div
      className="sc-iqHYGH ieNPiA rsc-header"
      style={{
        background: '#343a40',
        height: '53px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '10px',
      }}
    >
      <img
        src={logo}
        alt="vya.digital"
        style={{
          width: '125px',
        }}
      />
      <img src={bg} alt="background" style={{ width: '100px' }} />
    </div>
  </a>
);
