import { Component } from 'react';
import { FaRegFilePdf, FaRegCopy, FaRegEnvelope } from 'react-icons/fa';
import { FiShare2, FiCheck } from 'react-icons/fi';
import { ResourcesContext } from '../../context/ResourcesContext';
import openLinkInNewTab from '../../utils/openLinkInNewTab';

import transferTextToClipboard from '../../utils/transferTextToClipboard';

import './styles.css';

import { splitText } from '../../utils/text';

class ExportOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  static contextType = ResourcesContext;

  share = async () => {
    if (navigator.share) {
      await navigator.share({
        text:
          this.props.shareMessage ||
          `Segue a linha digitável e link para o boleto.
linha digitável: ${splitText(
            this.context.digitableLine || this.props.digitableLine,
            12
          )}
${
  this.context.fileUrl || this.props.fileUrl
    ? `link para boleto: ${this.context.fileUrl || this.props.fileUrl}`
    : ''
}`,
        //url: 'https://fatura.io',
        title: 'Negociação Fatura.io',
      });
      if (this.props.waitAction && !this.context.email) {
        this.props.triggerNextStep();
      }
    }
  };

  copiedCb = () => this.setState({ copied: true });

  resetCopied = () => {
    if (this.state.copied) {
      this.setState({ copied: false });
    }
  };

  render() {
    const copied = this.state.copied;
    const disabled = {
      opacity: this.context.ended ? 0.7 : 1,
      pointerEvents: this.context.ended ? 'none' : 'all',
    };
    return (
      <div className="export-container">
        {(this.context.fileUrl || this.props.fileUrl) && (
          <button
            onClick={() => {
              if (this.props.waitAction && !this.context.email) {
                this.props.triggerNextStep();
              }
              openLinkInNewTab(this.context.fileUrl || this.props.fileUrl);
            }}
            style={this.props.optionStyle}
          >
            <FaRegFilePdf size={18} color="#fff" />
            <span>Abrir PDF</span>
          </button>
        )}
        {this.props.email && (
          <button
            onClick={() => {
              if (this.props.waitAction && !this.context.email) {
                this.props.triggerNextStep();
              }
              this.context.sendEmail();
            }}
            style={{ ...this.props.optionStyle, ...disabled }}
          >
            <FaRegEnvelope size={18} color="#fff" />
            <span>Receber por email</span>
          </button>
        )}
        <button
          onClick={() => {
            if (this.props.waitAction && !this.context.email) {
              this.props.triggerNextStep();
            }
            transferTextToClipboard(
              this.context.digitableLine || this.props.digitableLine,
              this.copiedCb
            );
          }}
          onMouseEnter={this.resetCopied}
          style={this.props.optionStyle}
        >
          {copied ? (
            <FiCheck size={18} color="#fff" />
          ) : (
            <FaRegCopy size={18} color="#fff" />
          )}
          <span>
            {copied
              ? 'Copiado!'
              : this.props.copyText || 'Copiar linha digitável'}
          </span>
        </button>
        {navigator.share && (
          <button onClick={this.share} style={this.props.optionStyle}>
            <FiShare2 size={18} color="#fff" />
            <span>Compartilhar</span>
          </button>
        )}
      </div>
    );
  }
}

export default ExportOptions;
