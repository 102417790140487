import { theme } from "./theme";

export const header = (
  <a href="https://grupozelo.com/" target="_blank" rel="noreferrer noopened">
    <div
      className="sc-iqHYGH ieNPiA rsc-header"
      style={{
        background: theme.headerBgColor,
        height: '53px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <img
        src="/11/logo.png"
        alt="Grupo Zelo"
        style={{
          width: '120px',

          paddingLeft: '18px',
          //paddingTop: '4px',
        }}
      />
    </div>
  </a>
);
