import React, { useEffect, useState } from 'react';

import { Container } from './loaderStyles';

import SiscomLogo from '../../../assets/siscom.png';
import itauLogo from '../../../assets/itau.png';
import Poster from '../../../assets/poster.jpg';
import BgVideo from '../../../assets/bg.mp4';
import Spinner from 'react-loader-spinner';
import { classNames } from 'vya.utilities';


function Loader({ close }) {
  const [focus, setFocus] = useState(1)
  const [ended, setEnded] = useState(false)
  const [closeLoader, setCloseLoader] = useState(false)
  const [unmount, setUnmount] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setFocus(2), 1000)
    const handleEndedTimeout = setTimeout(() => setEnded(true), 4000)

    return () => {
      clearTimeout(timeout)
      clearTimeout(handleEndedTimeout)
    }
  }, [])

  useEffect(() => {
    let timeout
    if (close && ended) {
      setCloseLoader(true)
      timeout = setTimeout(() => setUnmount(true), 500)
    }

    return () => clearTimeout(timeout)
  }, [close, ended])

  if (unmount) {
    return null
  }

  return (
    <Container className={classNames({
      close: closeLoader
    })}>
      <div className="video-container">
        <video autoPlay loop muted preload="none">
          <source src={BgVideo} />
        </video>
      </div>
      <div className="slider">
        <div className={classNames('slide', {
          blur: focus !== 1
        })} style={{ backgroundColor: '#ffffffCC' }}>
          <img className="siscom-logo" src={SiscomLogo} alt="Logo siscom" />
        </div>
        <div className="slide" style={{ backgroundColor: '#F27405CC' }}>
          <img className="itau-logo" src={itauLogo} alt="Logo itaú" />
          <span>#IssoMudaOGame</span>
          <Spinner color="#fff" height={60} width={60} type="Rings"/>
        </div>
      </div>
    </Container>
  );
}

export default Loader;
