//Versao A Vista / Parcelado e com opção de segunda via

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';

import { saudacao } from '../../../components/chat/saudacao';
import { alterMetaTag } from '../../../components/chat/metaTag';
import {
  calcular,
  consultarDivida,
  emitirBoleto,
  consultarParcelamento,
  calcularParcelamento,
} from '../../../services/credsystem-cyber/webserviceController';

import {
  extractNumber,
  textCapitalizeCamelCase,
  textCapitalize,
  firstWord,
  splitText,
} from '../../../utils/text';

import { formatDate, FORMAT, addDays } from '../../../utils/date';
import { currencyDisplay } from '../../../utils/currency';

import DiscussLoader from '../../../components/loaders/discussLoader';
import Ops from '../../../components/Ops';
import vyaApi from '../../../services/api';
import moment from 'moment';
import ExportOptions from '../../../components/ExportOptions';
import { ResourcesContext } from '../../../context/ResourcesContext';
import DatePI from '../../../components/DatePI';
import { format } from 'date-fns/esm';
import { ptBR } from 'date-fns/esm/locale';
import WaitResponse from '../../../components/WaitResponse';
import { theme } from './theme';
import { header } from './Header';
import { sendPutRequest } from '../../../lib/sendPutRequest';
import validateId from '../../../utils/validateId';
import { flexFormat } from '../../../utils/flexFormat';
import verifyCpf from '../../../utils/verifyCpf';

class InfoContato extends Component {
  /*   constructor(props) {
      super(props);
    } */

  componentWillMount() {
    const { steps } = this.props;
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        {/* <h3></h3> */}
        <table>
          <tbody>
            <tr>
              <td>No telefone</td>
            </tr>
            <tr>
              <td>
                <a
                  href="tel:08002836111"
                  style={{ color: '#fff', textDecoration: 'none' }}
                >
                  <strong>0800 283 6111</strong>
                </a>
              </td>
            </tr>
            <tr>
              <td>e escolha a opção 1</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

InfoContato.propTypes = {
  steps: PropTypes.object,
};

InfoContato.defaultProps = {
  steps: undefined,
};
class CredSystemNegociacao extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, error: false, result: undefined };
    this.sendPutRequest = sendPutRequest(this);
  }

  static contextType = ResourcesContext;

  componentWillMount() {
    alterMetaTag(
      'Credsystem negociação',
      /\/[0-9]/.exec(this.props.match.path),
      '#413f43'
    );
    this.handleEnd = this.handleEnd.bind(this);
  }

  async componentDidMount() {
    if (!validateId(this.props.match.params.id)) {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage:
          'Identificamos informações incorretas. Tente novamente mais tarde.',
      });
      return;
    }
    let urlParameters = this.props.location.pathname.split('/')[2].split('_');
    let sessionChat =
      urlParameters[0] +
      '_' +
      urlParameters[1] +
      '_' +
      moment().format('YYYYMMDD_HHMMSS');
    urlParameters = 'id=' + urlParameters[0] + '&idAcao=' + urlParameters[1];
    this.setState({ sessionChat: sessionChat });

    let obj = {};
    let resultVyaApi = {};
    //Coletando dados do contato na plataforma
    try {
      const response = await vyaApi.get(`Contato?${urlParameters}`);

      if (JSON.parse(response.data.Status == false)) {
        this.setState({
          isLoading: false,
          error: true,
          errorMessage: 'Os dados não foram localizados no sistema principal.',
        });
        return;
      }
      resultVyaApi = JSON.parse(response.data.Data);
      console.log('[resultVyaApi]', resultVyaApi);
      this.sendPutRequest({
        idUser: resultVyaApi.id,
        id: 'API',
        idAcao: resultVyaApi.idAcao,
        message: response.data.Message,
        value: response.data.Status,
      });
    } catch (err) {
      console.log('Erro: API ', err);
      this.setState({
        isLoading: false,
        error: true,
        errorMessage: 'Os dados não foram localizados no sistema principal.',
      });
      return;
    }

    //split

    //Loop nos contratos
    //pegará o primeiro com divida
    for (let auxCount = 1; auxCount <= 5; auxCount++) {
      if (
        resultVyaApi['des_contr_' + auxCount] === '' ||
        resultVyaApi['des_contr_' + auxCount] === 'NULL'
      ) {
        continue;
      }

      obj = {
        id: resultVyaApi.id, //informações da API vya
        idAcao: resultVyaApi.idAcao, //informações da API vya
        idProduto: resultVyaApi.idProduto, //informações da API vya
        idUsuario: resultVyaApi.idUsuario, //informações da API vya
        contratoSequencia: auxCount, //informa qual posicao estava o contrato no mailing
        contrato: resultVyaApi['des_contr_' + auxCount], //contrato do cliente
        produto: resultVyaApi['cod_produt_' + auxCount], //codigo do produto do cliente
        regis: resultVyaApi.des_regis, //ientificador do cliente na base credsystem
        vencimentoOriginal: flexFormat(
          resultVyaApi['vencida_' + auxCount],
          'YMD'
        ), //data de vencimento original no formato yyyy-mm-dd
        vencimento: flexFormat(resultVyaApi['vencida_' + auxCount], 'DMY'), // convertendo data de vencimento em formato dd/mm/yyyy
        nome: textCapitalizeCamelCase(firstWord(resultVyaApi.nome_cliente)), //Nome do cliente porém coletando apenas o primeiro nome
        nomeCompleto: textCapitalize(resultVyaApi.nome_cliente), //Nome completo do cliente
        cpf: extractNumber(resultVyaApi.cpf), //cpf do cliente
        nomeRede: resultVyaApi['nome_rede_' + auxCount], //nome da loja em que o cliente está devendo
        acordoAtivo: 0, //indica se já possui um acordo ativo para o contrato
        retornoTexto: '',
      };

      /* obj = {
        id: 1,
        idAcao: 21867,
        idProduto: 70,
        idUsuario: 57,
        contratoSequencia: auxCount,
        contrato: "142448532960331", //contrato do cliente
        produto: "MA", //codigo do produto do cliente
        regis: "142448532", //identificador do cliente na base credsystem
        vencimentoOriginal: "2019-03-15", //data de vencimento original no formato yyyy-mm-dd
        vencimento: formatDate("2019-03-15", "YYYY-MM-DD", FORMAT.DATE_FULL_YEAR), // convertendo data de vencimento em formato dd/mm/yyyy
        nome: textCapitalizeCamelCase(firstWord("ADAIR CARDOSO DOS SANTOS")), //Nome do cliente porém coletando apenas o primeiro nome
        nomeCompleto: textCapitalize("ADAIR CARDOSO DOS SANTOS"), //Nome completo do cliente
        cpf: extractNumber("80701302704"), //cpf do cliente
        nomeRede: "MAIS GIRO LAR E LAZER", //nome da loja em que o cliente está devendo
        acordoAtivo: 0 //indica se já possui um acordo ativo para o contrato
      } */
      console.log('[vya-obj-inicial]', obj);
      console.log('[contratoSequencia]', auxCount);
      //console.log('[obj.contrato]', obj.contrato)

      try {
        const responseConsultarDivida = await consultarDivida(
          obj.regis,
          obj.contrato,
          obj.produto,
          obj.vencimentoOriginal
        );
        if (responseConsultarDivida.quantidadeDivida !== 0) {
          obj = {
            ...obj,
            quantidadeDivida: responseConsultarDivida.quantidadeDivida, //A quantidade de dividas que foi consultado
            valorPrincipalOriginal: responseConsultarDivida.valorTotal, //Valor original/principal da divida
            valorPrincipal: currencyDisplay(
              responseConsultarDivida.valorTotal.toFixed(2),
              2
            ), //Valor original/principal da divida formatado
            valorOriginal: responseConsultarDivida.valorTotal, //Valor original/principal da divida
            valor: currencyDisplay(
              responseConsultarDivida.valorTotal.toFixed(2),
              2
            ), //Valor da divida que será sado para informar ao cliente
          };
          console.log('[consultarDivida-obj]', obj);
        } else {
          obj = {
            ...obj,
            retornoTexto: responseConsultarDivida.retornoTexto,
          };
          console.log(
            '[consultarDivida-quantidadeDivida]',
            responseConsultarDivida.quantidadeDivida
          );
          this.setState({
            status: obj.retornoTexto,
            statusCode: 'FAPI',
          });
          this.sendPutRequest({
            idUser: obj.id,
            idAcao: obj.idAcao,
            id: 'credsystem API',
            message: this.state.status,
            value: this.state.status,
          });
          continue;
        }
      } catch (err) {
        console.log('Erro: API ', err);
        obj = {
          ...obj,
          retornoTexto: err,
        };
        this.setState({
          isLoading: false,
          error: true,
          errorMessage:
            'Estamos com instabilidade no sistema para consultar seus dados. Tente novamente mais tarde.',
        });
        break;
      }

      try {
        let dataAcordo = new Date();
        dataAcordo = addDays(dataAcordo, 1);
        dataAcordo =
          dataAcordo.getFullYear() +
          '-' +
          (dataAcordo.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          dataAcordo
            .getDate()
            .toString()
            .padStart(2, '0');
        obj = {
          ...obj,
          dataAcordoOriginal: dataAcordo,
          dataAcordo: formatDate(
            dataAcordo,
            'YYYY-MM-DD',
            FORMAT.DATE_FULL_YEAR
          ),
        };
        const responseCalcular = await calcular(
          obj.regis,
          obj.contrato,
          obj.produto,
          obj.vencimentoOriginal,
          obj.dataAcordoOriginal
        );
        if (responseCalcular.valorTotal !== 0) {
          obj = {
            ...obj,
            valorOriginal: responseCalcular.valorAtual,
            valorPrincipal: responseCalcular.valorPrincipal,
            valorEncargos: parseFloat(responseCalcular.valorEncargos).toFixed(
              2
            ),
            valor: currencyDisplay(
              parseFloat(responseCalcular.valorAtual).toFixed(2),
              2
            ),
          };
          console.log('[calcular-obj]', obj);
        } else {
          console.log('[calcular-valorTotal]', responseCalcular.valorTotal);
          continue;
        }
      } catch (err) {
        console.log('Erro: API ', err);
        this.setState({
          isLoading: false,
          error: true,
          errorMessage:
            'Estamos com instabilidade no sistema para calcular os dados. Tente novamente mais tarde.',
        });
        break;
      }

      try {
        let qtdParcelas = 1;
        const responseConsultarParcelamento = await consultarParcelamento(
          obj.regis,
          obj.contrato,
          obj.produto,
          obj.vencimentoOriginal,
          obj.dataAcordoOriginal,
          qtdParcelas
        );
        if (responseConsultarParcelamento.tipoAcordo !== '') {
          obj = {
            ...obj,
            tipoAcordo: responseConsultarParcelamento.tipoAcordo, //Tipo de acordo codigo usado na geração do acordo
            codParcela: responseConsultarParcelamento.codParcela, //informativo ainda não utilizado
            qtdParcelas: responseConsultarParcelamento.qtdParcelas, //qtd de parcelas solicitada para consulta de parcelamento
            tiposAcordoOriginal:
              responseConsultarParcelamento.tiposAcordoOriginal, //Tipos de Acordo para uso em outras negociações alterando a quantidade de parcelas
            maxDiscountValorPrincipal:
              responseConsultarParcelamento.maxDiscountValorPrincipal,
          };
          console.log('[consultarParcelamento-obj]', obj);
        } else {
          console.log(
            '[consultarParcelamento-tipoAcordo]',
            responseConsultarParcelamento.tipoAcordo
          );
          continue;
        }
      } catch (err) {
        console.log('Erro: API ', err);
        this.setState({
          isLoading: false,
          error: true,
          errorMessage:
            err.message ||
            'Estamos com instabilidade no sistema para consultar parcelamento dos seus dados. Tente novamente mais tarde.',
        });
        break;
      }

      try {
        const responseCalcularParcelamento = await calcularParcelamento(
          obj.regis,
          obj.contrato,
          obj.produto,
          obj.vencimentoOriginal,
          obj.dataAcordoOriginal,
          obj.valorOriginal,
          obj.tipoAcordo,
          obj.valorEncargos,
          obj.valorPrincipal,
          obj.qtdParcelas,
          obj.maxDiscountValorPrincipal
        );
        if (responseCalcularParcelamento.valorTotal !== 0) {
          obj = {
            ...obj,
            valorParcelarOriginal: responseCalcularParcelamento.valorParcelar,
            valorParcelar: currencyDisplay(
              parseFloat(responseCalcularParcelamento.valorParcelar).toFixed(2),
              2
            ),
            valorBoletoOriginal: responseCalcularParcelamento.valorTotal,
            valorBoleto: currencyDisplay(
              parseFloat(responseCalcularParcelamento.valorTotal).toFixed(2),
              2
            ),
            valorDesconto: parseFloat(
              responseCalcularParcelamento.valorDesconto
            ).toFixed(2),
            valorEntradaSemTaxaAdm: parseFloat(
              responseCalcularParcelamento.valorEntradaSemTaxaAdm
            ).toFixed(2),
            parcelas: responseCalcularParcelamento.parcelas,
          };
          console.log('[calcularParcelamento-obj]', obj);
        } else {
          if (responseCalcularParcelamento.acordoAtivo === 1) {
            obj = {
              ...obj,
              acordoAtivo: responseCalcularParcelamento.acordoAtivo,
              codParcela: responseCalcularParcelamento.codParcela,
            };
            //Temos que adicionar o obj na result porque pode
            //ser o ultimo contrato e devemos informar o cliente que ele ja
            //tem acordo ativo
            this.setState({ result: obj });
          }
          console.log('[calcularParcelamento-obj]', obj);
          continue;
        }
      } catch (err) {
        console.log('Erro: API ', err);
        this.setState({
          isLoading: false,
          error: true,
          errorMessage:
            'Estamos com instabilidade no sistema para consultar parcelamento dos seus dados. Tente novamente mais tarde.',
        });
        break;
      }

      this.setState({ result: obj });
      this.setState({ isLoading: false, error: false });
      break;
    }

    if (this.state.result === undefined) {
      this.setState({ isLoading: false, error: true });
      this.setState({
        result: obj,
        status: `Falha de api CredSystem${
          this.state.errorMessage ? ` - ${this.state.errorMessage}` : ''
        }`,
        message: this.state.errorMessage || '',
        statusCode: 'FAPI',
      });
    } else {
      this.setState({ isLoading: false, error: false });
      this.setState({ status: 'API CredSystem OK', statusCode: 'SAPI' });
    }

    var step = {
      id: 'credsystem API',
      message: this.state.errorMessage,
      value: this.state.errorMessage,
    };
    //Registrar dados iniciais
    this.sendPutRequest(step);
  }

  handleEnd({ steps, values }) {
    if (!this.state.stepFinal) {
      return;
    }

    var data = JSON.stringify({
      id: this.state.result.id,
      idAcao: this.state.result.idAcao,
      session: this.state.sessionChat,
      step: this.state.stepFinal.id,
      frase: this.state.stepFinal.message,
      resposta: this.state.stepFinal.value,
      status: this.state.status,
      statusCode: this.state.statusCode,
    });

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi
      .post('/ContatoMidia', data)
      .then(function(response) {
        //alert('Obrigado. Terminamos seu atendimento.');
      })
      .catch(function(error) {
        console.log('[sendPutRequest-' + steps.id + ']', error);
      });
  }

  render() {
    const { isLoading, result, error } = this.state;

    //Insere passo a passo do chatbot
    /* const sendPutRequest = async (step) => {
      var data = JSON.stringify(
        {
          "id": this.state.result.id,
          "idAcao": this.state.result.idAcao,
          "session": this.state.sessionChat,
          "step": step.id,
          "frase": step.message,
          "resposta": step.value,
          "status": this.state.status ? this.state.status : "",
          "statusCode": this.state.statusCode ? this.state.statusCode : ""
        });

      vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
      vyaApi.post('/ContatoMidia', data)
        .then(function (response) {
          //console.log('[sendPutRequest]', JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log('[sendPutRequest]', error);
        });
    }; */

    if (isLoading) {
      return (
        <div className="App">
          <DiscussLoader />
        </div>
      );
    }

    if (error) {
      return (
        <Ops
          theme={theme}
          headerComponent={header}
          botAvatar="/2/avatar.png"
          message={this.state.errorMessage ? this.state.errorMessage : null}
        />
      );
    }

    let steps = [];
    steps = [
      {
        id: 'initial',
        message: `${saudacao()}`,
        metadata: this.state.result,
        delay: 800,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.initial);
          return 'apresentacao';
        },
      },
      {
        id: 'apresentacao',
        message: ({ previousValue, steps }) => {
          return (
            'Olá ' +
            steps.initial.metadata.nome +
            ', meu nome é Ricardo agente virtual da credsystem.'
          );
        },
        delay: 600,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.apresentacao);
          return 'info_importantes';
        },
      },
      {
        id: 'info_importantes',
        message: 'Tenho informações importantes para você',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.info_importantes);
          return 'solicitar_cpf';
        },
        delay: 600,
      },
      {
        id: 'solicitar_cpf',
        message:
          'Para que possamos garantir o sigilo de suas informações, por favor digite os 3 primeiros digitos do seu CPF.',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.solicitar_cpf);
          return 'get_cpf';
        },
      },
      {
        id: 'get_cpf',
        user: true,
        validator: (value) => {
          if (isNaN(value)) {
            this.setState({ status: 'NCPF', statusCode: 'NCPF' });
            return 'Por favor digite apenas número';
          }
          return true;
        },
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.get_cpf);
          return 'validate_cpf';
        },
      },
      {
        id: 'validate_cpf',
        message: ({ previousValue, steps }) => {
          if (
            verifyCpf(previousValue.substring(0, 3), steps.initial.metadata.cpf)
          ) {
            this.setState({ status: 'CPFD', statusCode: 'CPFD' });
            if (steps.initial.metadata.acordoAtivo === 1) {
              return (
                textCapitalizeCamelCase(steps.initial.metadata.nome) +
                `, identificamos que existe um acordo ativo para a loja ${steps.initial.metadata.nomeRede}. Caso necessite entre em contato conosco.`
              );
            } else {
              return (
                'Obrigado pela confirmação dos dados. ' +
                textCapitalizeCamelCase(steps.initial.metadata.nome) +
                ` identificamos que existem débitos em seu nome na loja ${steps.initial.metadata.nomeRede} no valor de ${steps.initial.metadata.valor} que venceu no dia ${steps.initial.metadata.vencimento}. Você efetuou esse pagamento?`
              );
            }
          } else {
            this.setState({ status: 'NCPF', statusCode: 'NCPF' });
            return 'Ops! CPF incorreto vamos tentar novamente.';
          }
        },
        delay: 1200,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.validate_cpf);
          if (
            verifyCpf(
              steps.get_cpf.value.substring(0, 3),
              steps.initial.metadata.cpf
            )
          ) {
            if (steps.initial.metadata.acordoAtivo === 1) {
              //return 'pagamento_1_sim_nao'
              return 'info_contato';
            } else {
              return 'debitos_pendentes';
            }
          } else {
            return 'solicitar_cpf';
          }
        },
      },
      {
        id: 'segundavia_boleto',
        options: [
          { value: 'SIM', label: 'Sim', trigger: 'pagamento_1' },
          { value: 'NAO', label: 'Não', trigger: 'desconhece_divida_mensagem' },
        ],
      },
      {
        id: 'debitos_pendentes',
        options: [
          {
            value: 'NAOPAGOU',
            label: 'Não paguei',
            trigger: ({ value, steps }) => {
              this.sendPutRequest(steps.debitos_pendentes);
              return 'pagamento_1';
            },
          },
          {
            value: 'JAPAGOU',
            label: 'Já paguei',
            trigger: ({ value, steps }) => {
              this.sendPutRequest(steps.debitos_pendentes);
              return 'japagou_mensagem';
            },
          },
          {
            value: 'DESC',
            label: 'Desconheço esta dívida',
            trigger: ({ value, steps }) => {
              this.sendPutRequest(steps.debitos_pendentes);
              return 'desconhece_divida_mensagem';
            },
          },
        ],
      },
      {
        id: 'pagamento_1',
        message: ({ previousValue, steps }) => {
          return +steps.initial.metadata.maxDiscountValorPrincipal === 0
            ? `O valor para pagamento está em ${steps.initial.metadata.valorBoleto}. Podemos agendar este pagamento para amanhã?`
            : `Apliquei um desconto, o valor para pagamento ficará apenas ${steps.initial.metadata.valorBoleto}. Podemos agendar este pagamento para amanhã?`;
        },
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_1);
          return 'pagamento_1_sim_nao';
        },
      },
      {
        id: 'pagamento_1_sim_nao',
        options: [
          {
            value: 'NAO',
            label: 'Não',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'RECU', statusCode: 'RECU' });
              this.sendPutRequest(steps.pagamento_1_sim_nao);

              return 'prazo_pagamento';
            },
          },

          {
            value: 'SIM',
            label: 'Sim',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACAV', statusCode: 'ACAV' });
              this.sendPutRequest(steps.pagamento_1_sim_nao);

              emitirBoleto(
                this.state.result.regis,
                this.state.result.contrato,
                this.state.result.produto,
                this.state.result.vencimentoOriginal,
                this.state.result.dataAcordoOriginal,
                this.state.result.valorOriginal,
                this.state.result.tipoAcordo,
                this.state.result.acordoAtivo,
                this.state.result.valorDesconto,
                this.state.result.valorEntradaSemTaxaAdm,
                this.state.result.qtdParcelas
              )
                .then((response) => {
                  //console.log('[vya-responseConsultarDivida]', response)

                  this.setState({
                    result: {
                      ...this.state.result,
                      linhaDigitavel: response.linhaDigitavel,
                      pdf: response.pdf,
                      valorBoletoOriginal: response.valorBoleto,
                      valorBoleto: currencyDisplay(
                        parseFloat(response.valorBoleto).toFixed(2),
                        2
                      ),
                      dataAcordoOriginal: response.dataAcordo,
                      dataAcordo: formatDate(
                        response.dataAcordo,
                        'YYYY-MM-DD',
                        FORMAT.DATE_FULL_YEAR
                      ),
                    },
                  });
                  this.context.setValues(response.pdf, response.linhaDigitavel);
                })
                .catch((error) => {
                  // handle error
                  console.log('[emitirBoleto]', error);
                  return 'end_error_message';
                });
              return 'escolha_forma_pagamento';
            },
          },
        ],
      },
      {
        id: 'prazo_pagamento',
        message: 'Quando você pode pagar a primeira parcela?',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.prazo_pagamento);

          return 'escolha_prazo_pagamento';
        },
      },
      {
        id: 'escolha_prazo_pagamento',
        component: <DatePI />,
        waitAction: true,
        delay: 4000,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.escolha_prazo_pagamento);
          //Verifica se será possivel ter parcelamento
          // 3 parcelas e a parcela deve ser maior que 25 reais
          // if (+this.state.result.maxDiscountValorPrincipal === 0) {
          //   return 'pagamento_formas_parcelamento_1';
          // }
          if (this.state.result.valorBoletoOriginal / 3 <= 25) {
            return 'pagamento_formas_parcelamento_1';
          } else if (this.state.result.valorBoletoOriginal / 5 <= 25) {
            return 'pagamento_formas_parcelamento_3';
          } else {
            return 'pagamento_formas_parcelamento';
          }
        },
      },
      {
        id: 'escolha_forma_pagamento',
        message:
          'Ótima escolha! Aguarde um instante que estou gerando o boleto',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.escolha_forma_pagamento);

          return 'aguarde_processando';
        },
      },
      {
        id: 'aguarde_processando',
        message: 'Aguarde mais um pouco.',
        delay: 5000,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.aguarde_processando);

          return 'pagamento_1_sim_processando_aguarde';
        },
      },
      {
        id: 'pagamento_1_sim_processando',
        delay: 500,
        message: 'Aguarde um instante que estou gerando o boleto',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_1_sim_processando);
          return 'pagamento_1_sim_processando_aguarde';
          // }
        },
      },
      {
        id: 'pagamento_1_sim_processando_aguarde',
        component: <WaitResponse />,
        asMessage: true,
        waitAction: true,
        replace: true,
        // message: 'Só mais um pouquinho estou gerando o boleto',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_1_sim_processando_aguarde);

          if (this.context.digitableLine) {
            return 'pagamento_linha_digitavel';
          } else {
            return 'tempo_resposta_ultrapassado';
          }
          // } else {
          //   console.log(
          //     '[this.state.result.linhaDigitavel]',
          //     'pagamento_1_sim_processando'
          //   );
          //   return 'pagamento_1_sim_processando';
          // }
        },
      },
      {
        id: 'tempo_resposta_ultrapassado',
        delay: 0,
        message:
          'Verificamos que o sistema demorou mais que o esperado. Mas não se preocupe. Assim que conseguirmos gerar sua fatura, enviaremos a linha digitável por SMS.',
        trigger: ({ value, steps }) => {
          this.setState({ status: 'ACSMS', statusCode: 'ACSMS' });
          this.sendPutRequest(steps.tempo_resposta_ultrapassado);

          return 'lembrando';
        },
      },
      {
        id: 'pagamento_parcelamento_sim_processando',
        delay: 4500,
        message:
          'Aguarde um instante que estou gerando o boleto do seu parcelamento.',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_parcelamento_sim_processando);
          console.log(
            '[this.state.result.linhaDigitavel]',
            this.state.result.linhaDigitavel
          );
          if (this.state.result.linhaDigitavel !== undefined) {
            console.log(
              '[this.state.result.linhaDigitavel]',
              'pagamento_linha_digitavel'
            );
            return 'pagamento_linha_digitavel';
          } else {
            console.log(
              '[this.state.result.linhaDigitavel]',
              'pagamento_parcelamento_sim_processando_aguarde'
            );
            return 'pagamento_parcelamento_sim_processando_aguarde';
          }
        },
      },
      {
        id: 'pagamento_parcelamento_sim_processando_aguarde',
        delay: 2000,
        message: 'Só mais um pouquinho estou gerando o boleto',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(
            steps.pagamento_parcelamento_sim_processando_aguarde
          );
          console.log(
            '[this.state.result.linhaDigitavel]',
            this.state.result.linhaDigitavel
          );
          if (this.state.result.linhaDigitavel !== undefined) {
            console.log(
              '[this.state.result.linhaDigitavel]',
              'pagamento_linha_digitavel'
            );
            return 'pagamento_linha_digitavel';
          } else {
            console.log(
              '[this.state.result.linhaDigitavel]',
              'pagamento_parcelamento_sim_processando'
            );
            return 'pagamento_parcelamento_sim_processando';
          }
        },
      },
      {
        id: 'pagamento_linha_digitavel',
        delay: 0,
        message: ({ previousValue, steps }) => {
          return (
            'Pronto! Aqui está o seu boleto no valor de ' +
            this.state.result.valorBoleto +
            ' e vencimento para o dia ' +
            this.state.result.dataAcordo
          );
        },
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_linha_digitavel);
          steps.initial.metadata = this.state.result;
          return 'boleto_opcoes';
        },
      },
      {
        id: 'pagamento_linha_digitavel_continua',
        delay: 1000,
        message: ({ previousValue, steps }) => {
          return (
            'Copie a linha digitável para efetuar o pagamento ' +
            splitText(this.state.result.linhaDigitavel, 12)
          );
        },
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_linha_digitavel_continua);
          return 'agradecimento';
        },
      },
      {
        id: 'pagamento_linha_digitavel_continua_old',
        delay: 1000,
        message: ({ previousValue, steps }) => {
          return (
            'Copie a linha digitável para efetuar o pagamento ' +
            splitText(this.state.result.linhaDigitavel, 12)
          );
        },
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_linha_digitavel_continua);
          return 'agradecimento';
        },
      },
      {
        id: 'pagamento_formas_parcelamento',
        message:
          'Vamos parcelar? É só escolher uma dessas opções de parcelamento com desconto.',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_formas_parcelamento);
          console.log(this.context.date);
          this.setState({
            result: {
              ...this.state.result,
              dataAcordoOriginal: format(this.context.date, 'yyyy-MM-dd', {
                locale: ptBR,
              }),
              dataAcordo: format(this.context.date, 'dd/MM/yyyy', {
                locale: ptBR,
              }),
            },
          });

          return 'pagamento_formas_parcelamento_opcoes_5';
        },
      },
      {
        id: 'pagamento_formas_parcelamento_3',
        message:
          'Vamos parcelar? É só escolher uma dessas opções de parcelamento com desconto.',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_formas_parcelamento_3);

          this.setState({
            result: {
              ...this.state.result,
              dataAcordoOriginal: format(this.context.date, 'yyyy-MM-dd', {
                locale: ptBR,
              }),
              dataAcordo: format(this.context.date, 'dd/MM/yyyy', {
                locale: ptBR,
              }),
            },
          });

          return 'pagamento_formas_parcelamento_opcoes_3';
        },
      },
      {
        id: 'pagamento_formas_parcelamento_1',
        message: 'Ok, data registrada. Escolha a opção de pagamento à vista',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_formas_parcelamento_1);

          this.setState({
            result: {
              ...this.state.result,
              dataAcordoOriginal: format(this.context.date, 'yyyy-MM-dd', {
                locale: ptBR,
              }),
              dataAcordo: format(this.context.date, 'dd/MM/yyyy', {
                locale: ptBR,
              }),
            },
          });

          return 'pagamento_formas_parcelamento_opcoes_1';
        },
      },

      {
        id: 'pagamento_formas_parcelamento_opcoes_5',
        options: [
          {
            value: 'parcelamento_nenhuma',
            label: 'Nenhuma',
            trigger: ({ value, steps }) => {
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes_5);
              return 'parcelamento_nenhuma';
            },
          },
          {
            value: 'parcelamento_1_0',
            label: '1 Parcela (À vista)',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACAV', statusCode: 'ACAV' });
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes_5);
              let obj = this.state.result;
              calcular(
                obj.regis,
                obj.contrato,
                obj.produto,
                obj.vencimentoOriginal,
                obj.dataAcordoOriginal
              ).then((responseCalcular) => {
                if (responseCalcular.valorTotal !== 0) {
                  obj = {
                    ...obj,
                    valorOriginal: responseCalcular.valorAtual,
                    valorPrincipal: responseCalcular.valorPrincipal,
                    valorEncargos: parseFloat(
                      responseCalcular.valorEncargos
                    ).toFixed(2),
                    valor: currencyDisplay(
                      parseFloat(responseCalcular.valorAtual).toFixed(2),
                      2
                    ),
                  };
                  console.log('[calcular-obj]', obj);
                }

                let responseConsultarParcelamento = null;
                obj.qtdParcelas = 1;
                consultarParcelamento(
                  obj.regis,
                  obj.contrato,
                  obj.produto,
                  obj.vencimentoOriginal,
                  obj.dataAcordoOriginal,
                  obj.qtdParcelas
                ).then((response) => {
                  responseConsultarParcelamento = response;

                  if (responseConsultarParcelamento.tipoAcordo !== '') {
                    obj = {
                      ...obj,
                      tipoAcordo: responseConsultarParcelamento.tipoAcordo, //Tipo de acordo codigo usado na geração do acordo
                      codParcela: responseConsultarParcelamento.codParcela, //informativo ainda não utilizado
                      qtdParcelas: responseConsultarParcelamento.qtdParcelas, //qtd de parcelas solicitada para consulta de parcelamento
                      tiposAcordoOriginal:
                        responseConsultarParcelamento.tiposAcordoOriginal, //Tipos de Acordo para uso em outras negociações alterando a quantidade de parcelas
                    };
                    console.log('[consultarParcelamento-obj]', obj);

                    let responseCalcularParcelamento = null;
                    calcularParcelamento(
                      obj.regis,
                      obj.contrato,
                      obj.produto,
                      obj.vencimentoOriginal,
                      obj.dataAcordoOriginal,
                      obj.valorOriginal,
                      obj.tipoAcordo,
                      obj.valorEncargos,
                      obj.valorPrincipal,
                      obj.qtdParcelas,
                      obj.maxDiscountValorPrincipal
                    ).then((response) => {
                      responseCalcularParcelamento = response;

                      if (responseCalcularParcelamento.valorTotal !== 0) {
                        obj = {
                          ...obj,
                          valorParcelarOriginal:
                            responseCalcularParcelamento.valorParcelar,
                          valorParcelar: currencyDisplay(
                            parseFloat(
                              responseCalcularParcelamento.valorParcelar
                            ).toFixed(2),
                            2
                          ),
                          valorBoletoOriginal:
                            responseCalcularParcelamento.valorTotal,
                          valorBoleto: currencyDisplay(
                            parseFloat(
                              responseCalcularParcelamento.valorTotal
                            ).toFixed(2),
                            2
                          ),
                          valorDesconto: parseFloat(
                            responseCalcularParcelamento.valorDesconto
                          ).toFixed(2),
                          valorEntradaSemTaxaAdm: parseFloat(
                            responseCalcularParcelamento.valorEntradaSemTaxaAdm
                          ).toFixed(2),
                          parcelas: responseCalcularParcelamento.parcelas,
                        };
                        console.log('[calcularParcelamento-obj]', obj);
                      } else {
                        if (responseCalcularParcelamento.acordoAtivo === 1) {
                          obj = {
                            ...obj,
                            acordoAtivo:
                              responseCalcularParcelamento.acordoAtivo,
                          };
                        }
                      }
                      emitirBoleto(
                        obj.regis,
                        obj.contrato,
                        obj.produto,
                        obj.vencimentoOriginal,
                        obj.dataAcordoOriginal,
                        obj.valorOriginal,
                        obj.tipoAcordo,
                        obj.acordoAtivo,
                        obj.valorDesconto,
                        obj.valorEntradaSemTaxaAdm,
                        obj.qtdParcelas
                      )
                        .then((response) => {
                          //console.log('[vya-responseConsultarDivida]', response)

                          this.setState({
                            result: {
                              ...this.state.result,
                              linhaDigitavel: response.linhaDigitavel,
                              pdf: response.pdf,
                              valorBoletoOriginal: response.valorBoleto,
                              valorBoleto: currencyDisplay(
                                parseFloat(response.valorBoleto).toFixed(2),
                                2
                              ),
                              dataAcordoOriginal: response.dataAcordo,
                              dataAcordo: formatDate(
                                response.dataAcordo,
                                'YYYY-MM-DD',
                                FORMAT.DATE_FULL_YEAR
                              ),
                            },
                          });
                          this.context.setValues(
                            response.pdf,
                            response.linhaDigitavel
                          );
                        })
                        .catch((error) => {
                          // handle error
                          console.log('[emitirBoleto]', error);
                          return 'end_error_message';
                        });
                    });
                  }
                });
              });

              this.setState({ result: obj });
              return 'escolha_forma_pagamento';
            },
          },
          {
            value: 'parcelamento_1_2',
            label: '3 Parcelas (1+2)',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACPA', statusCode: 'ACPA' });
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes_5);
              console.log(this.state.result);
              let obj = this.state.result;
              calcular(
                obj.regis,
                obj.contrato,
                obj.produto,
                obj.vencimentoOriginal,
                obj.dataAcordoOriginal
              ).then((responseCalcular) => {
                if (responseCalcular.valorTotal !== 0) {
                  obj = {
                    ...obj,
                    valorOriginal: responseCalcular.valorAtual,
                    valorPrincipal: responseCalcular.valorPrincipal,
                    valorEncargos: parseFloat(
                      responseCalcular.valorEncargos
                    ).toFixed(2),
                    valor: currencyDisplay(
                      parseFloat(responseCalcular.valorAtual).toFixed(2),
                      2
                    ),
                  };
                  console.log('[calcular-obj]', obj);
                }

                let responseConsultarParcelamento = null;
                obj.qtdParcelas = 3;
                consultarParcelamento(
                  obj.regis,
                  obj.contrato,
                  obj.produto,
                  obj.vencimentoOriginal,
                  obj.dataAcordoOriginal,
                  obj.qtdParcelas
                ).then((response) => {
                  responseConsultarParcelamento = response;

                  if (responseConsultarParcelamento.tipoAcordo !== '') {
                    obj = {
                      ...obj,
                      tipoAcordo: responseConsultarParcelamento.tipoAcordo, //Tipo de acordo codigo usado na geração do acordo
                      codParcela: responseConsultarParcelamento.codParcela, //informativo ainda não utilizado
                      qtdParcelas: responseConsultarParcelamento.qtdParcelas, //qtd de parcelas solicitada para consulta de parcelamento
                      tiposAcordoOriginal:
                        responseConsultarParcelamento.tiposAcordoOriginal, //Tipos de Acordo para uso em outras negociações alterando a quantidade de parcelas
                    };
                    console.log('[consultarParcelamento-obj]', obj);

                    let responseCalcularParcelamento = null;
                    calcularParcelamento(
                      obj.regis,
                      obj.contrato,
                      obj.produto,
                      obj.vencimentoOriginal,
                      obj.dataAcordoOriginal,
                      obj.valorOriginal,
                      obj.tipoAcordo,
                      obj.valorEncargos,
                      obj.valorPrincipal,
                      obj.qtdParcelas,
                      obj.maxDiscountValorPrincipal
                    ).then((response) => {
                      responseCalcularParcelamento = response;

                      if (responseCalcularParcelamento.valorTotal !== 0) {
                        obj = {
                          ...obj,
                          valorParcelarOriginal:
                            responseCalcularParcelamento.valorParcelar,
                          valorParcelar: currencyDisplay(
                            parseFloat(
                              responseCalcularParcelamento.valorParcelar
                            ).toFixed(2),
                            2
                          ),
                          valorBoletoOriginal:
                            responseCalcularParcelamento.valorTotal,
                          valorBoleto: currencyDisplay(
                            parseFloat(
                              responseCalcularParcelamento.valorTotal
                            ).toFixed(2),
                            2
                          ),
                          valorDesconto: parseFloat(
                            responseCalcularParcelamento.valorDesconto
                          ).toFixed(2),
                          valorEntradaSemTaxaAdm: parseFloat(
                            responseCalcularParcelamento.valorEntradaSemTaxaAdm
                          ).toFixed(2),
                          parcelas: responseCalcularParcelamento.parcelas,
                        };
                        console.log('[calcularParcelamento-obj]', obj);
                      } else {
                        if (responseCalcularParcelamento.acordoAtivo === 1) {
                          obj = {
                            ...obj,
                            acordoAtivo:
                              responseCalcularParcelamento.acordoAtivo,
                          };
                        }
                      }
                      emitirBoleto(
                        obj.regis,
                        obj.contrato,
                        obj.produto,
                        obj.vencimentoOriginal,
                        obj.dataAcordoOriginal,
                        obj.valorOriginal,
                        obj.tipoAcordo,
                        obj.acordoAtivo,
                        obj.valorDesconto,
                        obj.valorEntradaSemTaxaAdm,
                        obj.qtdParcelas
                      )
                        .then((response) => {
                          //console.log('[vya-responseConsultarDivida]', response)
                          this.setState({
                            result: {
                              ...this.state.result,
                              linhaDigitavel: response.linhaDigitavel,
                              pdf: response.pdf,
                              valorBoletoOriginal: response.valorBoleto,
                              valorBoleto: currencyDisplay(
                                parseFloat(response.valorBoleto).toFixed(2),
                                2
                              ),
                              dataAcordoOriginal: response.dataAcordo,
                              dataAcordo: formatDate(
                                response.dataAcordo,
                                'YYYY-MM-DD',
                                FORMAT.DATE_FULL_YEAR
                              ),
                            },
                          });
                          this.context.setValues(
                            response.pdf,
                            response.linhaDigitavel
                          );
                        })
                        .catch((error) => {
                          // handle error
                          console.log('[emitirBoleto]', error);
                          return 'end_error_message';
                        });
                    });
                  }
                });
              });

              this.setState({ result: obj });
              return 'escolha_forma_pagamento';
            },
          },
          {
            value: 'parcelamento_1_4',
            label: '5 Parcelas (1+4)',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACPA', statusCode: 'ACPA' });
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes_5);
              let obj = this.state.result;
              calcular(
                obj.regis,
                obj.contrato,
                obj.produto,
                obj.vencimentoOriginal,
                obj.dataAcordoOriginal
              ).then((responseCalcular) => {
                if (responseCalcular.valorTotal !== 0) {
                  obj = {
                    ...obj,
                    valorOriginal: responseCalcular.valorAtual,
                    valorPrincipal: responseCalcular.valorPrincipal,
                    valorEncargos: parseFloat(
                      responseCalcular.valorEncargos
                    ).toFixed(2),
                    valor: currencyDisplay(
                      parseFloat(responseCalcular.valorAtual).toFixed(2),
                      2
                    ),
                  };
                  console.log('[calcular-obj]', obj);
                }

                let responseConsultarParcelamento = null;
                obj.qtdParcelas = 5;
                consultarParcelamento(
                  obj.regis,
                  obj.contrato,
                  obj.produto,
                  obj.vencimentoOriginal,
                  obj.dataAcordoOriginal,
                  obj.qtdParcelas
                ).then((response) => {
                  responseConsultarParcelamento = response;

                  if (responseConsultarParcelamento.tipoAcordo !== '') {
                    obj = {
                      ...obj,
                      tipoAcordo: responseConsultarParcelamento.tipoAcordo, //Tipo de acordo codigo usado na geração do acordo
                      codParcela: responseConsultarParcelamento.codParcela, //informativo ainda não utilizado
                      qtdParcelas: responseConsultarParcelamento.qtdParcelas, //qtd de parcelas solicitada para consulta de parcelamento
                      tiposAcordoOriginal:
                        responseConsultarParcelamento.tiposAcordoOriginal, //Tipos de Acordo para uso em outras negociações alterando a quantidade de parcelas
                    };
                    console.log('[consultarParcelamento-obj]', obj);

                    let responseCalcularParcelamento = null;
                    calcularParcelamento(
                      obj.regis,
                      obj.contrato,
                      obj.produto,
                      obj.vencimentoOriginal,
                      obj.dataAcordoOriginal,
                      obj.valorOriginal,
                      obj.tipoAcordo,
                      obj.valorEncargos,
                      obj.valorPrincipal,
                      obj.qtdParcelas,
                      obj.maxDiscountValorPrincipal
                    ).then((response) => {
                      responseCalcularParcelamento = response;

                      if (responseCalcularParcelamento.valorTotal !== 0) {
                        obj = {
                          ...obj,
                          valorParcelarOriginal:
                            responseCalcularParcelamento.valorParcelar,
                          valorParcelar: currencyDisplay(
                            parseFloat(
                              responseCalcularParcelamento.valorParcelar
                            ).toFixed(2),
                            2
                          ),
                          valorBoletoOriginal:
                            responseCalcularParcelamento.valorTotal,
                          valorBoleto: currencyDisplay(
                            parseFloat(
                              responseCalcularParcelamento.valorTotal
                            ).toFixed(2),
                            2
                          ),
                          valorDesconto: parseFloat(
                            responseCalcularParcelamento.valorDesconto
                          ).toFixed(2),
                          valorEntradaSemTaxaAdm: parseFloat(
                            responseCalcularParcelamento.valorEntradaSemTaxaAdm
                          ).toFixed(2),
                          parcelas: responseCalcularParcelamento.parcelas,
                        };
                        console.log('[calcularParcelamento-obj]', obj);
                      } else {
                        if (responseCalcularParcelamento.acordoAtivo === 1) {
                          obj = {
                            ...obj,
                            acordoAtivo:
                              responseCalcularParcelamento.acordoAtivo,
                          };
                        }
                      }
                      emitirBoleto(
                        obj.regis,
                        obj.contrato,
                        obj.produto,
                        obj.vencimentoOriginal,
                        obj.dataAcordoOriginal,
                        obj.valorOriginal,
                        obj.tipoAcordo,
                        obj.acordoAtivo,
                        obj.valorDesconto,
                        obj.valorEntradaSemTaxaAdm,
                        obj.qtdParcelas
                      )
                        .then((response) => {
                          //console.log('[vya-responseConsultarDivida]', response)
                          this.setState({
                            result: {
                              ...this.state.result,
                              linhaDigitavel: response.linhaDigitavel,
                              pdf: response.pdf,
                              valorBoletoOriginal: response.valorBoleto,
                              valorBoleto: currencyDisplay(
                                parseFloat(response.valorBoleto).toFixed(2),
                                2
                              ),
                              dataAcordoOriginal: response.dataAcordo,
                              dataAcordo: formatDate(
                                response.dataAcordo,
                                'YYYY-MM-DD',
                                FORMAT.DATE_FULL_YEAR
                              ),
                            },
                          });
                          this.context.setValues(
                            response.pdf,
                            response.linhaDigitavel
                          );
                        })
                        .catch((error) => {
                          // handle error
                          console.log('[emitirBoleto]', error);
                          return 'end_error_message';
                        });
                    });
                  }
                });
              });
              console.table(obj, this.state.result);
              this.setState({ result: obj });
              return 'escolha_forma_pagamento';
            },
          },
        ],
      },
      {
        id: 'pagamento_formas_parcelamento_opcoes_1',
        options: [
          {
            value: 'parcelamento_nenhuma',
            label: 'Nenhuma',
            trigger: ({ value, steps }) => {
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes_1);
              return 'parcelamento_nenhuma';
            },
          },
          {
            value: 'parcelamento_1_0',
            label: '1 Parcela (À vista)',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACAV', statusCode: 'ACAV' });
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes_1);
              let obj = this.state.result;
              calcular(
                obj.regis,
                obj.contrato,
                obj.produto,
                obj.vencimentoOriginal,
                obj.dataAcordoOriginal
              ).then((responseCalcular) => {
                if (responseCalcular.valorTotal !== 0) {
                  obj = {
                    ...obj,
                    valorOriginal: responseCalcular.valorAtual,
                    valorPrincipal: responseCalcular.valorPrincipal,
                    valorEncargos: parseFloat(
                      responseCalcular.valorEncargos
                    ).toFixed(2),
                    valor: currencyDisplay(
                      parseFloat(responseCalcular.valorAtual).toFixed(2),
                      2
                    ),
                  };
                  console.log('[calcular-obj]', obj);
                }

                let responseConsultarParcelamento = null;
                obj.qtdParcelas = 1;
                consultarParcelamento(
                  obj.regis,
                  obj.contrato,
                  obj.produto,
                  obj.vencimentoOriginal,
                  obj.dataAcordoOriginal,
                  obj.qtdParcelas
                ).then((response) => {
                  responseConsultarParcelamento = response;

                  if (responseConsultarParcelamento.tipoAcordo !== '') {
                    obj = {
                      ...obj,
                      tipoAcordo: responseConsultarParcelamento.tipoAcordo, //Tipo de acordo codigo usado na geração do acordo
                      codParcela: responseConsultarParcelamento.codParcela, //informativo ainda não utilizado
                      qtdParcelas: responseConsultarParcelamento.qtdParcelas, //qtd de parcelas solicitada para consulta de parcelamento
                      tiposAcordoOriginal:
                        responseConsultarParcelamento.tiposAcordoOriginal, //Tipos de Acordo para uso em outras negociações alterando a quantidade de parcelas
                    };
                    console.log('[consultarParcelamento-obj]', obj);

                    let responseCalcularParcelamento = null;
                    calcularParcelamento(
                      obj.regis,
                      obj.contrato,
                      obj.produto,
                      obj.vencimentoOriginal,
                      obj.dataAcordoOriginal,
                      obj.valorOriginal,
                      obj.tipoAcordo,
                      obj.valorEncargos,
                      obj.valorPrincipal,
                      obj.qtdParcelas,
                      obj.maxDiscountValorPrincipal
                    ).then((response) => {
                      responseCalcularParcelamento = response;

                      if (responseCalcularParcelamento.valorTotal !== 0) {
                        obj = {
                          ...obj,
                          valorParcelarOriginal:
                            responseCalcularParcelamento.valorParcelar,
                          valorParcelar: currencyDisplay(
                            parseFloat(
                              responseCalcularParcelamento.valorParcelar
                            ).toFixed(2),
                            2
                          ),
                          valorBoletoOriginal:
                            responseCalcularParcelamento.valorTotal,
                          valorBoleto: currencyDisplay(
                            parseFloat(
                              responseCalcularParcelamento.valorTotal
                            ).toFixed(2),
                            2
                          ),
                          valorDesconto: parseFloat(
                            responseCalcularParcelamento.valorDesconto
                          ).toFixed(2),
                          valorEntradaSemTaxaAdm: parseFloat(
                            responseCalcularParcelamento.valorEntradaSemTaxaAdm
                          ).toFixed(2),
                          parcelas: responseCalcularParcelamento.parcelas,
                        };
                        console.log('[calcularParcelamento-obj]', obj);
                      } else {
                        if (responseCalcularParcelamento.acordoAtivo === 1) {
                          obj = {
                            ...obj,
                            acordoAtivo:
                              responseCalcularParcelamento.acordoAtivo,
                          };
                        }
                      }
                      emitirBoleto(
                        obj.regis,
                        obj.contrato,
                        obj.produto,
                        obj.vencimentoOriginal,
                        obj.dataAcordoOriginal,
                        obj.valorOriginal,
                        obj.tipoAcordo,
                        obj.acordoAtivo,
                        obj.valorDesconto,
                        obj.valorEntradaSemTaxaAdm,
                        obj.qtdParcelas
                      )
                        .then((response) => {
                          //console.log('[vya-responseConsultarDivida]', response)

                          this.setState({
                            result: {
                              ...this.state.result,
                              linhaDigitavel: response.linhaDigitavel,
                              pdf: response.pdf,
                              valorBoletoOriginal: response.valorBoleto,
                              valorBoleto: currencyDisplay(
                                parseFloat(response.valorBoleto).toFixed(2),
                                2
                              ),
                              dataAcordoOriginal: response.dataAcordo,
                              dataAcordo: formatDate(
                                response.dataAcordo,
                                'YYYY-MM-DD',
                                FORMAT.DATE_FULL_YEAR
                              ),
                            },
                          });
                          this.context.setValues(
                            response.pdf,
                            response.linhaDigitavel
                          );
                        })
                        .catch((error) => {
                          // handle error
                          console.log('[emitirBoleto]', error);
                          return 'end_error_message';
                        });
                    });

                    this.setState({ result: obj });
                    return 'escolha_forma_pagamento';
                  }
                });
              });

              this.setState({ result: obj });
              return 'escolha_forma_pagamento';
            },
          },
        ],
      },

      {
        id: 'pagamento_formas_parcelamento_opcoes_3',
        options: [
          {
            value: 'parcelamento_nenhuma',
            label: 'Não quero',
            trigger: ({ value, steps }) => {
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes_3);
              return 'parcelamento_nenhuma';
            },
          },
          {
            value: 'pagamento_formas_parcelamento_opcoes_0',
            label: '1 Parcela (À vista)',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACAV', statusCode: 'ACAV' });
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes_3);

              let responseConsultarParcelamento = null;
              let obj = this.state.result;
              obj.qtdParcelas = 1;
              consultarParcelamento(
                obj.regis,
                obj.contrato,
                obj.produto,
                obj.vencimentoOriginal,
                obj.dataAcordoOriginal,
                obj.qtdParcelas
              ).then((response) => {
                responseConsultarParcelamento = response;

                if (responseConsultarParcelamento.tipoAcordo !== '') {
                  obj = {
                    ...obj,
                    tipoAcordo: responseConsultarParcelamento.tipoAcordo, //Tipo de acordo codigo usado na geração do acordo
                    codParcela: responseConsultarParcelamento.codParcela, //informativo ainda não utilizado
                    qtdParcelas: responseConsultarParcelamento.qtdParcelas, //qtd de parcelas solicitada para consulta de parcelamento
                    tiposAcordoOriginal:
                      responseConsultarParcelamento.tiposAcordoOriginal, //Tipos de Acordo para uso em outras negociações alterando a quantidade de parcelas
                  };
                  console.log('[consultarParcelamento-obj]', obj);

                  let responseCalcularParcelamento = null;
                  calcularParcelamento(
                    obj.regis,
                    obj.contrato,
                    obj.produto,
                    obj.vencimentoOriginal,
                    obj.dataAcordoOriginal,
                    obj.valorOriginal,
                    obj.tipoAcordo,
                    obj.valorEncargos,
                    obj.valorPrincipal,
                    obj.qtdParcelas,
                    obj.maxDiscountValorPrincipal
                  ).then((response) => {
                    responseCalcularParcelamento = response;

                    if (responseCalcularParcelamento.valorTotal !== 0) {
                      obj = {
                        ...obj,
                        valorParcelarOriginal:
                          responseCalcularParcelamento.valorParcelar,
                        valorParcelar: currencyDisplay(
                          parseFloat(
                            responseCalcularParcelamento.valorParcelar
                          ).toFixed(2),
                          2
                        ),
                        valorBoletoOriginal:
                          responseCalcularParcelamento.valorTotal,
                        valorBoleto: currencyDisplay(
                          parseFloat(
                            responseCalcularParcelamento.valorTotal
                          ).toFixed(2),
                          2
                        ),
                        valorDesconto: parseFloat(
                          responseCalcularParcelamento.valorDesconto
                        ).toFixed(2),
                        valorEntradaSemTaxaAdm: parseFloat(
                          responseCalcularParcelamento.valorEntradaSemTaxaAdm
                        ).toFixed(2),
                        parcelas: responseCalcularParcelamento.parcelas,
                      };
                      console.log('[calcularParcelamento-obj]', obj);
                    } else {
                      if (responseCalcularParcelamento.acordoAtivo === 1) {
                        obj = {
                          ...obj,
                          acordoAtivo: responseCalcularParcelamento.acordoAtivo,
                        };
                      }
                    }
                    emitirBoleto(
                      obj.regis,
                      obj.contrato,
                      obj.produto,
                      obj.vencimentoOriginal,
                      obj.dataAcordoOriginal,
                      obj.valorOriginal,
                      obj.tipoAcordo,
                      obj.acordoAtivo,
                      obj.valorDesconto,
                      obj.valorEntradaSemTaxaAdm,
                      obj.qtdParcelas
                    )
                      .then((response) => {
                        //console.log('[vya-responseConsultarDivida]', response)

                        this.setState({
                          result: {
                            ...this.state.result,
                            linhaDigitavel: response.linhaDigitavel,
                            pdf: response.pdf,
                            valorBoletoOriginal: response.valorBoleto,
                            valorBoleto: currencyDisplay(
                              parseFloat(response.valorBoleto).toFixed(2),
                              2
                            ),
                            dataAcordoOriginal: response.dataAcordo,
                            dataAcordo: formatDate(
                              response.dataAcordo,
                              'YYYY-MM-DD',
                              FORMAT.DATE_FULL_YEAR
                            ),
                          },
                        });
                        this.context.setValues(
                          response.pdf,
                          response.linhaDigitavel
                        );
                      })
                      .catch((error) => {
                        // handle error
                        console.log('[emitirBoleto]', error);
                        return 'end_error_message';
                      });
                  });
                }
              });

              this.setState({ result: obj });
              return 'escolha_forma_pagamento';
            },
          },
          {
            value: 'parcelamento_1_2',
            label: '3 Parcelas (1+2)',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACPA', statusCode: 'ACPA' });
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes_3);

              let responseConsultarParcelamento = null;
              let obj = this.state.result;
              obj.qtdParcelas = 3;
              consultarParcelamento(
                obj.regis,
                obj.contrato,
                obj.produto,
                obj.vencimentoOriginal,
                obj.dataAcordoOriginal,
                obj.qtdParcelas
              ).then((response) => {
                responseConsultarParcelamento = response;

                if (responseConsultarParcelamento.tipoAcordo !== '') {
                  obj = {
                    ...obj,
                    tipoAcordo: responseConsultarParcelamento.tipoAcordo, //Tipo de acordo codigo usado na geração do acordo
                    codParcela: responseConsultarParcelamento.codParcela, //informativo ainda não utilizado
                    qtdParcelas: responseConsultarParcelamento.qtdParcelas, //qtd de parcelas solicitada para consulta de parcelamento
                    tiposAcordoOriginal:
                      responseConsultarParcelamento.tiposAcordoOriginal, //Tipos de Acordo para uso em outras negociações alterando a quantidade de parcelas
                  };
                  console.log('[consultarParcelamento-obj]', obj);

                  let responseCalcularParcelamento = null;
                  calcularParcelamento(
                    obj.regis,
                    obj.contrato,
                    obj.produto,
                    obj.vencimentoOriginal,
                    obj.dataAcordoOriginal,
                    obj.valorOriginal,
                    obj.tipoAcordo,
                    obj.valorEncargos,
                    obj.valorPrincipal,
                    obj.qtdParcelas,
                    obj.maxDiscountValorPrincipal
                  ).then((response) => {
                    responseCalcularParcelamento = response;

                    if (responseCalcularParcelamento.valorTotal !== 0) {
                      obj = {
                        ...obj,
                        valorParcelarOriginal:
                          responseCalcularParcelamento.valorParcelar,
                        valorParcelar: currencyDisplay(
                          parseFloat(
                            responseCalcularParcelamento.valorParcelar
                          ).toFixed(2),
                          2
                        ),
                        valorBoletoOriginal:
                          responseCalcularParcelamento.valorTotal,
                        valorBoleto: currencyDisplay(
                          parseFloat(
                            responseCalcularParcelamento.valorTotal
                          ).toFixed(2),
                          2
                        ),
                        valorDesconto: parseFloat(
                          responseCalcularParcelamento.valorDesconto
                        ).toFixed(2),
                        valorEntradaSemTaxaAdm: parseFloat(
                          responseCalcularParcelamento.valorEntradaSemTaxaAdm
                        ).toFixed(2),
                        parcelas: responseCalcularParcelamento.parcelas,
                      };
                      console.log('[calcularParcelamento-obj]', obj);
                    } else {
                      if (responseCalcularParcelamento.acordoAtivo === 1) {
                        obj = {
                          ...obj,
                          acordoAtivo: responseCalcularParcelamento.acordoAtivo,
                        };
                      }
                    }
                    emitirBoleto(
                      obj.regis,
                      obj.contrato,
                      obj.produto,
                      obj.vencimentoOriginal,
                      obj.dataAcordoOriginal,
                      obj.valorOriginal,
                      obj.tipoAcordo,
                      obj.acordoAtivo,
                      obj.valorDesconto,
                      obj.valorEntradaSemTaxaAdm,
                      obj.qtdParcelas
                    )
                      .then((response) => {
                        //console.log('[vya-responseConsultarDivida]', response)

                        this.setState({
                          result: {
                            ...this.state.result,
                            linhaDigitavel: response.linhaDigitavel,
                            pdf: response.pdf,
                            valorBoletoOriginal: response.valorBoleto,
                            valorBoleto: currencyDisplay(
                              parseFloat(response.valorBoleto).toFixed(2),
                              2
                            ),
                            dataAcordoOriginal: response.dataAcordo,
                            dataAcordo: formatDate(
                              response.dataAcordo,
                              'YYYY-MM-DD',
                              FORMAT.DATE_FULL_YEAR
                            ),
                          },
                        });
                        this.context.setValues(
                          response.pdf,
                          response.linhaDigitavel
                        );
                      })
                      .catch((error) => {
                        // handle error
                        console.log('[emitirBoleto]', error);
                        return 'end_error_message';
                      });
                  });
                }
              });

              this.setState({ result: obj });
              return 'escolha_forma_pagamento';
            },
          },
        ],
      },
      {
        id: 'pagamento_formas',
        message:
          'Vamos tentar parcelar. Qual dessas datas fica melhor para você?',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_formas);
          return 'pagamento_formas_sim_nao';
        },
      },
      {
        id: 'pagamento_formas_sim_nao',
        options: [
          {
            value: 'sim',
            label: `${formatDate(
              addDays(new Date(), 5),
              'YYYY-MM-DD',
              FORMAT.DATE_FULL_YEAR
            )}`,
            trigger: 'end-message-5',
          },
          {
            value: 'não',
            label: `${formatDate(
              addDays(new Date(), 7),
              'YYYY-MM-DD',
              FORMAT.DATE_FULL_YEAR
            )}`,
            trigger: 'end-message-7',
          },
          {
            value: 'parcelamento_nenhuma',
            label: 'Nenhuma destas',
            trigger: 'parcelamento_nenhuma',
          },
        ],
      },
      {
        id: 'desconhece_divida_mensagem',
        message: `Por gentileza, entre em contato com nossa central para maiores informações .`,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.desconhece_divida_mensagem);
          return 'info_contato_desconhece';
        },
      },
      {
        id: 'info_contato_desconhece',
        component: <InfoContato />,
        asMessage: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.info_contato_desconhece);
          return 'agradecimento';
        },
      },

      {
        id: 'parcelamento_nenhuma',
        message:
          'Como não conseguimos fechar um acordo, por favor, entre em contato com a nossa central de atendimento',
        trigger: 'info_contato',
      },
      {
        id: 'recusa_mensagem',
        message:
          'Como não conseguimos fechar um acordo, por favor, entre em contato com a nossa central de atendimento',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.recusa_mensagem);
          return 'info_contato';
        },
      },

      {
        id: 'info_contato',
        component: <InfoContato />,
        asMessage: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.info_contato);
          return 'lembrando';
        },
        delay: 2000,
      },
      {
        id: 'end_error_message',
        message:
          'Ops :( estamos com instabilidade no sistema por favor tente novamente em alguns minutos. A Credsystem agradece sua compreensão!',
        end: true,
      },
      {
        id: 'lembrando',
        message: ({ previousValue, steps }) => {
          //let message = 'Lembrando que os juros e encargos são diários e seu nome poderá ser incluso nos orgãos de proteção ao crédito. A Credsystem agradece sua atenção!'
          let message = 'A Credsystem agradece sua atenção!';
          let stepFinal = { id: 'lembrando', message: message, value: '' };
          this.setState({ stepFinal });
          return message;
        },
        end: true,
      },

      {
        id: 'japagou_mensagem',
        message:
          'Ótimo! A confirmação do pagamento é registrada em nosso sistema em até 48 horas.',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.japagou_mensagem);
          return 'agradecimento';
        },
      },
      {
        id: 'type_boleto',
        options: [
          { value: 'sms', label: 'SMS', trigger: 'number-confirm' },
          { value: 'email', label: 'E-mail', trigger: 'email-confirm' },
          {
            value: 'gerarBoleto',
            label: 'Gerar Boleto (PDF)',
            trigger: 'gerar-boleto',
          },
        ],
      },
      {
        id: 'gerar-boleto',
        message: 'Em instantes iniciara um donwload do seu boleto',
        trigger: 'boleto_opcoes',
      },
      {
        id: 'boleto_opcoes',
        metadata: this.state.result.linhaDigitavel,
        component: (
          <ExportOptions
            digitableLine={this.state.result.linhaDigitavel}
            fileUrl={this.state.result.pdf}
          />
        ),
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.boleto_opcoes);
          return 'agradecimento';
        },
      },
      {
        id: 'email-confirm',
        message: 'Qual o seu email?',
        trigger: 'options-email-confirm',
      },
      {
        id: 'options-email-confirm',
        options: [
          {
            value: 'editar-email',
            label: 'Digitar o seu e-mail',
            trigger: 'email_edit',
          },
          {
            value: 'voltar-menu-anterior',
            label: 'Voltar ao menu anterior',
            trigger: 'type_boleto',
          },
        ],
      },
      {
        id: 'email_edit',
        user: true,
        trigger: 'email-confirm-user',
      },
      {
        id: 'email-confirm-user',
        message: ({ previousValue, steps }) => {
          return `O email esta correto? ${previousValue}`;
        },
        trigger: 'options-email-confirm2',
      },
      {
        id: 'options-email-confirm2',
        options: [
          { value: 'edit', label: 'Sim', trigger: 'confirm-email-ok' },
          { value: 'não', label: 'Não', trigger: 'options-email-confirm' },
        ],
      },
      {
        id: 'confirm-email-ok',
        message: ({ previousValue, steps }) => {
          //console.log('email?', steps)
          return `Pronto foi enviado no E-mail ${steps.email_edit.value} um email contendo o boleto para o pagamento do seu debito, confira sua caixa de email ou na caixa de span, apenas lembrando se ouver juros vira na proxima fatura`; // o numero tem que ser uma variavel recebida da api aqui ta mosdo de demonstraçao
        },
        trigger: 'agradecimento',
      },
      {
        id: 'number-confirm',
        message:
          'Posso enviar neste numero mesmo que você recebeu o link para este chat?',
        trigger: 'options-number-confirm',
      },
      {
        id: 'options-number-confirm',
        options: [
          { value: 'sim', label: 'Sim', trigger: 'confirm-number-ok' },
          { value: 'não', label: 'Não', trigger: 'no-confirm-number' },
        ],
      },
      {
        id: 'no-confirm-number',
        message:
          'Por favor digite um numero com DDD que você gostaria de enviar o SMS com o boleto',
        trigger: 'edit_number',
      },
      {
        id: 'edit_number',
        user: true,
        validator: (value) => {
          //console.log('[value]', value)
          if (isNaN(value)) {
            return 'Por favor digite apenas número';
          }
          return true;
        },
        trigger: 'validator_number',
      },
      {
        id: 'validator_number',
        message: ({ previousValue, steps }) => {
          // console.log('[steps]',steps)
          // console.log('[previousValue]',previousValue)

          let regex = previousValue;

          if (
            regex.match(
              /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
            ) == false
          ) {
            // O número não foi validado.
            return 'Este não é um numero valido';
          } else {
            // Telefone válido.
            return 'Execelente so um momento.';
          }
        },
        delay: 350,
        trigger: 'number-confirm-user',
      },
      {
        id: 'number-confirm-user',
        message: ({ previousValue, steps }) => {
          // console.log(previousValue)
          // console.log(steps)
          return `Este numero esta correto? ${steps.edit_number.value}`;
        },
        trigger: 'options-number-confirm',
      },
      {
        id: 'confirm-number-ok',
        message: ({ previousValue, steps }) => {
          return `Pronto foi enviado no telefone ${steps.edit_number.value} um sms contendo o boleto para o pagamento do seu debito, apenas lembrando se ouver juros vira na proxima fatura`; // o numero tem que ser uma variavel recebida da api aqui ta mosdo de demonstraçao
        },
        trigger: 'agradecimento',
      },
      {
        id: 'agradecimento',
        message: ({ previousValue, steps }) => {
          let message = 'A Credsystem agradece sua atenção.';
          let stepFinal = { id: 'agradecimento', message: message, value: '' };
          this.setState({ stepFinal });
          return message;
        },
        end: true,
      },
      {
        id: 'end-error-message',
        message: 'Desculpe! Credsystem agradece sua atenção!',
        end: true,
      },
      {
        id: 'end-message-1',
        message:
          'Por gentileza, antes do pagamento retira um extrato de sua conta corrente para verificar o valor atualizado de sua pendência. Agradecemos o compromisso firmado aqui hoje comigo com Credsystem e somente para informá-lo o agendamento da promessa não inibi a notificação nos órgãos de proteção ao crédito.',
        trigger: 'type_boleto',
      },
      {
        id: 'end-message-5',
        message:
          'Por gentileza, antes do pagamento retirar um extrato de sua conta corrente para verificar o valor atualizado de sua pendência. Agradecemos o compromisso firmado com a Credsystem e somente para informá-lo o agendamento da promessa não inibi a notificação nos órgãos de proteção ao crédito.',
        trigger: 'type_boleto',
      },
      {
        id: 'end-message-7',
        message:
          'Por gentileza, antes do pagamento retirar um extrato de sua conta corrente para verificar o valor atualizado de sua pendência. Agradecemos o compromisso firmado com a Credsystem e somente para informá-lo o agendamento da promessa não inibi a notificação nos órgãos de proteção ao crédito.',
        trigger: 'type_boleto',
      },
    ];

    return (
      <ThemeProvider theme={theme}>
        <ChatBot
          headerComponent={header}
          // Avatar do Bot e do usuário //
          botAvatar={/\/[0-9]/.exec(this.props.match.path) + '/avatar.png'}
          // userAvatar='https://i.imgur.com/6sm2DYv.png'

          handleEnd={this.handleEnd}
          recognitionEnable={false}
          recognitionPlaceholder="Escutando ..."
          placeholder="digite aqui..."
          botDelay={400}
          /* headerTitle="Credsystem" */
          floating={false}
          steps={steps}

          // Estilo do Rodapé (Footer) //
          /* footerStyle={{ backgroundColor: '#fff', margin: 5, padding: 1, borderRadius: 7, elevation: 2, }}
submitButtonStyle={{ backgroundColor: '#E89984', borderRadius: 4, width: 63, margin: 2 }} */
        />
      </ThemeProvider>
    );
  }
}

export default CredSystemNegociacao;
