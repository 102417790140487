import { addDays } from 'date-fns';
import React, { useEffect } from 'react';
import { Loading } from 'react-simple-chatbot';
import { cslogApi } from '../../../services/api';
import { flexFormat } from '../../../utils/flexFormat';

// import { Container } from './styles';

function WaitBillet({ data, triggerNextStep, step, steps }) {
  const handleProcessBillet = async () => {
    try {
      // const opt = data.infoAdicional.find(
      //   (opt) => opt.idBoleto === steps.pagamento_opcoes.value
      // );
      // const {
      //   data: { boleto },
      // } = await cslogApi.post('/cslog/agreements', {
      //   idContract: data.id_contr,
      //   dueDate: flexFormat(opt.venc, 'YMD'),
      //   installment: +opt.qtdeParc,
      //   overdueInstallmentQty: opt.contratosOferta.length,
      //   typeSendingBillet: 'LINHA_DIG',
      //   sendTo: '',
      // });

      const {
        data: { boletos },
      } = await cslogApi.get(`/cslog/billets/resend/${data.id_contr}`, {
        params: {
          typeSendingBillet: 'LINHA_DIG',
          sendTo: data.telefone,
        },
      });

      const boleto = boletos[0];

      step.value = { barCode: boleto.linhaDig, fileUrl: boleto.linkPDF };
    } catch (error) {
      console.log(error);
    }
    triggerNextStep();
  };

  useEffect(() => handleProcessBillet());

  return <Loading />;
}

export default WaitBillet;
