export const theme = {
  /* background: '#f5f8fb', */
  fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
  headerBgColor: '#343a40',
  headerFontColor: '#fff',
  headerFontSize: '21px',
  botBubbleColor: '#343a40',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#8f8e91',
};
