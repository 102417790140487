export const header = (
  <a href="https://banco.bradesco/" target="_blank" rel="noreferrer noopened">
    <div
      className="sc-iqHYGH ieNPiA rsc-header"
      style={{
        background: '#fff',
        height: '53px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px'
      }}
    >
      <img
        src="/10/bradesco-logo-new.png"
        alt="Bradesco"
        style={{
          width: '120px',
        }}
      />
      <img
        src="/10/system-extensive.png"
        alt="Bradesco"
        style={{
          height: '35px',
        }}
      />
    </div>
  </a>
);
