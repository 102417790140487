export const header = (
  <a href="https://www.sky.com.br/" target="_blank" rel="noreferrer noopened">
    <div
      className="sc-iqHYGH ieNPiA rsc-header"
      style={{
        background: '#e30609',
        height: '53px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <img
        src="/3/logo.png"
        alt="SKY"
        style={{
          width: '85px',

          paddingLeft: '18px',
          paddingTop: '4px',
        }}
      />
    </div>
  </a>
);
