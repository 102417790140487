export const header = (
  <a href="https://www.supermercadolopes.com.br/" target="_blank" rel="noreferrer noopened">
    <div
      className="sc-iqHYGH ieNPiA rsc-header"
      style={{
        background: '#ffffff',
        height: '53px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <img
        src="/wmarconi/logo.png"
        alt="Lopes Supermercado"
        style={{
          width: '20%',

          paddingLeft: '18px',
        }}
      />
    </div>
  </a>
);
