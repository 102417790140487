import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

export class Spinner extends Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader
          type="Oval"
          color={this.props.color || '#000'}
          height={30}
          width={30}
          // timeout={20000} //20 secs max
        />
      </div>
    );
  }
}

export default Spinner;
