import { Component, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import moment from 'moment';
import Chatbot, { Loading } from 'react-simple-chatbot';

import WaitResponse from '../../../components/WaitResponse';
import Spinner from '../../../components/loaders/spinner';
import Ops from '../../../components/Ops';
import { saudacao } from '../../../components/chat/saudacao';
import ExportOptions from '../../../components/ExportOptions';
import { header } from '../bradesco/Header';

import { ResourcesContext } from '../../../context/ResourcesContext';
import { textCapitalize } from '../../../utils/text';
import addUtilDays from '../../../utils/addUtilDays';
import { currencyDisplay } from '../../../utils/currency';
import vyaApi from '../../../services/api';
import validateId from '../../../utils/validateId';
import { sendPutRequest } from '../../../lib/sendPutRequest';
import { alterMetaTag } from '../../../components/chat/metaTag';

import { theme } from '../bradesco/theme';

const LoadingMessage = ({ isLoading, triggerNextStep, step, steps }) => {
  const handleProcessBillet = async () => {
    // const response = await axios.post(
    //   'https://api012.vya.digital/billet/pdf/generate',
    //   {
    //     bank: '237',
    //     sendEmail: {
    //       //   payerEmail: 'maxwellmacedo2015@gmail.com',
    //       //   logo:
    //       //     'https://i.ibb.co/LzbfmrK/logo-bradesco.png',
    //       //   sender: 'Bradesco',
    //     },
    //     payer: {
    //       name: steps.initial.metadata.nome,
    //       cpf: steps.initial.metadata.numerodocumento,
    //       address: boleto.desender,
    //       neighborhood: boleto.desbairr,
    //       city: boleto.descidad,
    //       stateUf: boleto.desestad,
    //       cep: boleto.descep,
    //     },
    //     recipient: {
    //       name: boleto.descodceden,
    //       cnpj: '04670195000138',
    //       bankWallet: boleto.descartebanco,
    //       agency: boleto.desagenc,
    //       agencyDigit: boleto.desagencdv,
    //       account: boleto.desconta,
    //       accountDigit: boleto.desagencdv,
    //       nossoNumero: nossoNumeroPad,
    //       nossoNumeroDigit,
    //       address: 'AL RIO NEGRO',
    //       neighborhood: 'ALPHAVILLE',
    //       city: 'BARUERI',
    //       stateUf: 'SP',
    //       cep: '06454000',
    //     },
    //     billet: {
    //       barCode: boleto.codigobarra,
    //       docNumber: boleto.desnumdoc,
    //       docSpecie: boleto.desespecdoc,
    //       value: boleto.valbolet,
    //       instructions: instructions,
    //       dates: {
    //         due: boleto.datvenci,
    //       },
    //     },
    //   }
    // );

    // step.value = response.data
    setTimeout(() => triggerNextStep(), 3000);
  };

  useEffect(() => handleProcessBillet(), []);

  return <Loading />;
};

class BradescoLpNegociacao extends Component {
  static contextType = ResourcesContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loadingMessage: true,
      error: false,
      result: undefined,
      selectedOptionValue: null,
      data: {},
    };

    this.sendPutRequest = sendPutRequest(this);
  }

  componentWillMount() {
    alterMetaTag(
      'Bradesco LP negociação',
      /\/[0-9]/.exec(this.props.match.path),
      '#e52645'
    );
    this.handleEnd = this.handleEnd.bind(this);
  }

  async componentDidMount() {
    // obrigatório
    if (!validateId(this.props.match.params.id)) {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage:
          'Identificamos informações incorretas. Tente novamente mais tarde.',
      });
      return;
    }
    let urlParameters = this.props.match.params.id.split('_');
    urlParameters = 'id=' + urlParameters[0] + '&idAcao=' + urlParameters[1];
    let sessionChat =
      this.props.match.params.id + moment().format('YYYYMMDD_HHMMSS');

    this.setState({ sessionChat: sessionChat });

    try {
      const { data } = await vyaApi.get(`Contato?${urlParameters}`);

      let result = JSON.parse(data.Data);
      console.log('[resultVyaApi]', result);
      if (result.hasOwnProperty('Nome') || result.hasOwnProperty('nome')) {
        this.setState({
          result: {
            ...result,
            option: result.a36_vezes
              ? 36
              : result.a24_vezes
              ? 24
              : result.a12_vezes
              ? 12
              : result.a06_vezes
              ? 6
              : 1,
            due_date: format(addUtilDays(new Date(), 7, true), 'yyyy-MM-dd'),
          },
        });
        this.setState({ isLoading: false, error: false });
        this.sendPutRequest({
          id: 'API',
          message: data.Message,
          value: data.Status,
        });
      } else {
        this.setState({ isLoading: false, error: true });
        this.sendPutRequest({
          id: 'API',
          message: 'erro api',
          value: 'error',
        });
      }
    } catch (error) {
      console.log('[Contato-error]', error);
      this.setState({ isLoading: false, error: true });
      this.sendPutRequest({
        id: 'API',
        message: 'erro api',
        value: 'error',
      });
    }
  }

  handleEnd({ steps, values }) {
    var data = JSON.stringify({
      id: this.state.result.id,
      idAcao: this.state.result.idAcao,
      session: this.state.sessionChat,
      step: this.state.stepFinal.id,
      frase: this.state.stepFinal.message,
      resposta: this.state.stepFinal.value,
      status: this.state.status,
      statusCode: this.state.statusCode,
    });

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi
      .post('/ContatoMidia', data)
      .then(function(response) {
        alert('Obrigado. Terminamos seu atendimento.');
        console.log(
          '[sendPutRequest-' + steps.id + ']',
          JSON.stringify(response.data)
        );
      })
      .catch(function(error) {
        console.log('[sendPutRequest-' + steps.id + ']', error);
      });
  }

  InfoContato = () => (
    <div style={{ width: '100%' }}>
      {/* <h3></h3> */}
      <table>
        <tbody>
          <tr>
            <td>No telefone:</td>
          </tr>
          <tr>
            <td>
              <a
                href="tel:08007275120"
                style={{ color: '#fff', textDecoration: 'none' }}
              >
                <strong>0800 727 5120</strong>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  processBillet = () => {
    setTimeout(() => {
      this.context.setValues(
        'https://api012.vya.digital/billet/pdf/file/boleto-10387605428-2021-01-13.pdf',
        '03399076364030010017554740301012884990000026549'
      );
    }, 5000);
  };

  handleEnd = () => {
    this.context.handleEnded();
  };

  render() {
    const { isLoading, result, error } = this.state;

    if (isLoading) {
      return (
        <div className="App">
          <Spinner color={theme.botBubbleColor} />
        </div>
      );
    }

    if (error) {
      return (
        <Ops
          theme={theme}
          headerComponent={header}
          botAvatar="/1/avatar.png"
          message={this.state.errorMessage ? this.state.errorMessage : null}
        />
      );
    }
    const steps = [
      {
        id: 'initial',
        message: `${saudacao()}`,
        metadata: result,
        trigger: ({ value, steps }) => {
          this.setState({ status: 'ENTR', statusCode: 'ENTR' });
          this.sendPutRequest(steps.initial);

          return 'apresentacao';
        },
      },
      {
        id: 'apresentacao',
        message: ({ previousValue, steps }) => {
          return (
            'Olá ' +
            textCapitalize(steps.initial.metadata.nome) +
            ', meu nome é João agente de negociação do banco Bradesco.'
          );
        },
        trigger: 'info_importantes',
      },
      {
        id: 'info_importantes',
        message: 'Tenho informações importantes para você',
        trigger: 'solicitar_cpf',
      },
      {
        id: 'solicitar_cpf',
        message:
          'Para garantir o sigilo das suas informações, me confirme os 3 primeiros dígitos do seu CPF.',
        trigger: 'get_cpf',
      },
      {
        id: 'get_cpf',
        user: true,
        validator: (value) => {
          if (isNaN(value)) {
            this.setState({ status: 'NCPF', statusCode: 'NCPF' });
            return 'Por favor digite apenas número';
          }
          return true;
        },
        trigger: 'validate_cpf',
      },
      {
        id: 'validate_cpf',
        message: ({ previousValue, steps }) => {
          if (
            previousValue.substring(0, 3) ===
            steps.initial.metadata.numerodocumento.substring(0, 3)
          ) {
            this.setState({ status: 'CPFD', statusCode: 'CPFD' });
            return 'Obrigado pela confirmação!';
          } else {
            return 'Ops! CPF incorreto vamos tentar novamente.';
          }
        },
        delay: 1200,
        trigger: ({ value, steps }) => {
          if (
            steps.get_cpf.value.substring(0, 3) ===
            steps.initial.metadata.numerodocumento.substring(0, 3)
          ) {
            return 'info_negociacao';
          } else {
            return 'solicitar_cpf';
          }
        },
      },
      {
        id: 'info_negociacao',
        message: ({ prev, steps }) =>
          `Ótimo, você tem ${result.perc_de_desconto} de desconto nessa negociação. Segue as opções abaixo 👇`,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.info_negociacao);
          const { option } = steps.initial.metadata;
          if (option === 1) {
            return 'a_vista';
          } else if (option === 6) {
            return 'a06_vezes';
          } else if (option === 12) {
            return 'a12_vezes';
          } else if (option === 24) {
            return 'a24_vezes';
          } else if (option === 36) {
            return 'a36_vezes';
          }

          return 'a_vista';
        },
      },

      // s-opcoes-pagamento

      {
        id: 'a_vista',
        options: [
          {
            value: '1x',
            label: `1x de ${currencyDisplay(result.a_vista, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a_vista, key: 1 },
              });
              return 'info_acordo';
            },
          },
          {
            value: 'NENHUMA',
            label: 'Nenhuma',
            trigger: 'feedback',
          },
        ],
      },
      {
        id: 'a06_vezes',
        options: [
          {
            value: '1x',
            label: `1x de ${currencyDisplay(result.a_vista, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a_vista, key: 1 },
              });
              return 'info_acordo';
            },
          },
          {
            value: '6x',
            label: `6x de ${currencyDisplay(result.a06_vezes, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a06_vezes, key: 6 },
              });
              return 'info_acordo';
            },
          },
          {
            value: 'NENHUMA',
            label: 'Nenhuma',
            trigger: 'feedback',
          },
        ],
      },
      {
        id: 'a24_vezes',
        options: [
          {
            value: '1x',
            label: `1x de ${currencyDisplay(result.a_vista, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a_vista, key: 1 },
              });
              return 'info_acordo';
            },
          },
          {
            value: '6x',
            label: `6x de ${currencyDisplay(result.a06_vezes, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a06_vezes, key: 6 },
              });
              return 'info_acordo';
            },
          },
          {
            value: '12x',
            label: `12x de ${currencyDisplay(result.a12_vezes, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a12_vezes, key: 12 },
              });
              return 'info_acordo';
            },
          },
          {
            value: '24x',
            label: `24x de ${currencyDisplay(result.a24_vezes, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a24_vezes, key: 24 },
              });
              return 'info_acordo';
            },
          },
          {
            value: 'NENHUMA',
            label: 'Nenhuma',
            trigger: 'feedback',
          },
        ],
      },
      {
        id: 'a36_vezes',
        options: [
          {
            value: '1x',
            label: `1x de ${currencyDisplay(result.a_vista, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a_vista, key: 1 },
              });
              return 'info_acordo';
            },
          },
          {
            value: '6x',
            label: `6x de ${currencyDisplay(result.a06_vezes, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a06_vezes, key: 6 },
              });
              return 'info_acordo';
            },
          },
          {
            value: '12x',
            label: `12x de ${currencyDisplay(result.a12_vezes, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a12_vezes, key: 12 },
              });
              return 'info_acordo';
            },
          },
          {
            value: '24x',
            label: `24x de ${currencyDisplay(result.a24_vezes, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a24_vezes, key: 24 },
              });
              return 'info_acordo';
            },
          },
          {
            value: '36x',
            label: `36x de ${currencyDisplay(result.a36_vezes, 2)}`,
            trigger: ({ v, steps }) => {
              this.setState({
                selectedOptionValue: { value: result.a36_vezes, key: 36 },
              });
              return 'info_acordo';
            },
          },
          {
            value: 'NENHUMA',
            label: 'Nenhuma',
            trigger: 'feedback',
          },
        ],
      },

      // e-opcoes-pagamento

      // s-feedback
      {
        id: 'feedback',
        message: '😔 O que eu poderia ter feito para te ajudar?',
        trigger: ({ _, steps }) => {
          this.setState({ status: 'FEEDBACK', statusCode: 'FEEDBACK' });
          this.sendPutRequest(steps.feedback);

          return 'digitar_feedback';
        },
      },
      {
        id: 'digitar_feedback',
        user: true,
        trigger: ({ previousValue, steps }) => {
          this.sendPutRequest({
            ...steps.digitar_feedback,
            message: previousValue,
          });
          return 'feedback_registrado';
        },
      },
      {
        id: 'feedback_registrado',
        message: 'Registrado. Vou falar com os meus criadores! 👍',
        trigger: 'info_contato_duvidas',
      },

      // e-feedback

      {
        id: 'info_acordo',
        message: ({ _, steps }) =>
          `Você escolheu pagar ${currencyDisplay(
            this.state.selectedOptionValue.value,
            2
          )} com vencimento dia ${format(
            parseISO(steps.initial.metadata.due_date),
            'dd/MM/yyyy'
          )}`,
        trigger: ({ _, steps }) => {
          this.setState({ status: 'ACAV', statusCode: 'ACAV' });
          this.sendPutRequest(steps.info_acordo);

          return 'gerar_boleto';
        },
      },
      {
        id: 'gerar_boleto',
        message: 'Aguarde, estamos gerando seu boleto.',
        trigger: ({ values, steps }) => {
          this.sendPutRequest(steps.gerar_boleto);

          const loadBoleto = () => {
            return setTimeout(
              () => this.setState({ loadingMessage: false }),
              3000
            );
          };
          loadBoleto();
          return 'gerar_boleto_carregando';
        },
      },
      {
        id: 'gerar_boleto_carregando',
        component: <LoadingMessage isLoading={this.state.loadingMessage} />,
        asMessage: true,
        waitAction: true,
        replace: true,
        trigger: ({ values, steps }) => {
          this.sendPutRequest(steps.gerar_boleto_carregando);

          return 'receber_email';
        },
      },

      // s-send-email
      {
        id: 'receber_email',
        message: 'Deseja receber por email seu boleto?',
        delay: 0,
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.receber_email);

          return 'receber_email_options';
        },
      },
      {
        id: 'receber_email_options',
        options: [
          {
            value: 'SIM',
            label: 'Sim',
            trigger: ({ _, steps }) => {
              if (steps.initial.metadata['e-mail']) {
                return 'confirmar_email';
              }

              return 'perguntar_email';
            },
          },
          {
            value: 'NÃO',
            label: 'Não',
            trigger: 'agradecimento',
          },
        ],
      },

      {
        id: 'perguntar_email',
        message: 'Qual o seu email?',
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.perguntar_email);

          return 'digitar_email';
        },
      },
      {
        id: 'digitar_email',
        user: true,
        validator: (value) => {
          if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value.trim())) {
            return 'Email inválido!';
          }
          return true;
        },
        trigger: 'confirmar_email',
      },
      {
        id: 'confirmar_email',
        message: ({ previousValue, steps }) => {
          return `O email esta correto?
${steps.initial.metadata['e-mail'] || previousValue.trim()}`;
        },
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.confirmar_email);
          return 'opcoes_confirmar_email';
        },
      },
      {
        id: 'opcoes_confirmar_email',
        options: [
          { value: 'edit', label: 'Sim', trigger: 'enviar_email' },
          { value: 'não', label: 'Não', trigger: 'perguntar_email' },
        ],
      },
      {
        id: 'enviar_email',
        message: ({ _, steps }) =>
          `Email enviado para ${steps.initial.metadata['e-mail'] ||
            steps.digitar_email
              ?.value}. Se não encontrar o email, verifique se não está na caixa de spam.`, // o numero tem que ser uma variavel recebida da api aqui ta mosdo de demonstraçao
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.enviar_email);

          axios({
            method: 'post',
            url: 'https://api012.vya.digital/email/send',
            data: {
              sender: {
                name: 'fatura.io',
              },
              destination: {
                name: result.nome,
                email:
                  steps.initial.metadata['e-mail'] ||
                  steps.digitar_email?.value.trim(),
              },
              content: {
                template: 'bradesco',
                subject: 'Aqui está o BOLETO que você pediu!',
                dueDate: format(parseISO(result.due_date), 'dd/MM/yyyy'),
                value: currencyDisplay(this.state.result.valordivida, 2),
                templateValues: {
                  qtdParcelas: this.state.selectedOptionValue.key,
                  valorParcelas: currencyDisplay(
                    this.state.selectedOptionValue.value,
                    2
                  ),
                  cpf: this.state.result.numerodocumento,
                },
                attachments: [],
              },
            },
          }).catch((error) => {
            console.log(error.response?.data);
          });

          return 'agradecimento';
        },
      },

      // e-send-email

      {
        id: 'desconhece_divida_mensagem',
        message: `Por gentileza, entre em contato com nossa central para maiores informações.`,
        trigger: 'info_contato_desconhece',
      },
      {
        id: 'info_contato_duvidas',
        message: 'Precisando de ajuda humana, entre em contato.',
        trigger: 'info_contato_desconhece',
      },

      {
        id: 'info_contato_desconhece',
        component: <this.InfoContato />,
        asMessage: true,
        trigger: 'agradecimento',
      },

      {
        id: 'info_contato',
        component: <this.InfoContato />,
        asMessage: true,
        trigger: 'agradecimento',
        delay: 2000,
      },

      {
        id: 'agradecimento',
        message: 'Bradesco agradece sua atenção!',
        end: true,
      },

      {
        id: 'pagamento_1_sim_processando_aguarde',
        component: <WaitResponse />,
        asMessage: true,
        waitAction: true,
        replace: true,
        trigger: '',
      },
      {
        id: 'boleto_opcoes',
        component: (
          <ExportOptions
            digitableLine={this.state.data.linhaDigitavel}
            fileUrl={this.state.data.pdf}
            email={true}
            waitAction={true}
          />
        ),
        waitAction: true,
        trigger: 'fallback',
      },
      {
        id: 'fallback',
        component: <Loading />,
        asMessage: true,
        replace: true,
        trigger: ({ value, steps }) => {
          if (this.context.email) {
            return 'email-confirm';
          }

          return 'agradecimento';
        },
      },
    ];

    const optionStyles = {
      background: 'transparent',
      border: `1.5px solid ${theme.botBubbleColor}`,
      borderRadius: 25,
      color: theme.botBubbleColor,
      cursor: 'pointer',
    };

    return (
      <ThemeProvider theme={theme}>
        <Chatbot
          className="bradesco"
          headerComponent={header}
          recognitionEnable={false}
          placeholder="digite aqui..."
          botDelay={500}
          floating={false}
          handleEnd={this.handleEnd}
          {...this.props}
          steps={steps}
          botAvatar={'/1/avatar.png'}
          bubbleOptionStyle={optionStyles}
          submitButtonStyle={{ fill: theme.botBubbleColor }}
        />
      </ThemeProvider>
    );
  }
}

export default BradescoLpNegociacao;
