//Versao A Vista / Parcelado e com opção de segunda via

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

import { saudacao } from '../../../components/chat/saudacao';
import { alterMetaTag } from '../../../components/chat/metaTag';

import { currencyDisplay } from '../../../utils/currency';

import DiscussLoader from '../../../components/loaders/discussLoader';
import Ops from '../../../components/Ops';
import vyaApi from '../../../services/api';
import moment from 'moment';
import ExportOptions from '../../../components/ExportOptions';
import { ResourcesContext } from '../../../context/ResourcesContext';
import { header } from './Header';
import { sendPutRequest } from '../../../lib/sendPutRequest';
import validateId from '../../../utils/validateId';

import LogoSky from '../../../assets/sky.webp';
import { isBefore, parseISO, startOfDay } from 'date-fns';
import ExportV2 from '../../../components/ExportV2';
import { flexFormat } from '../../../utils/flexFormat';

const SkyApp = () => {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=br.com.sky.selfcare"
      target="_blank"
      rel="noreferrer noopened"
      style={{
        display: 'flex',
        minHeight: '50px',
        padding: '0px 20px',
        alignItems: 'center',
        textDecoration: 'none',
      }}
    >
      <img
        src={LogoSky}
        alt="logo sky"
        style={{ width: '50px', marginRight: '15px' }}
      />
      <h3 style={{ color: '#4f4f4f', fontSize: 16 }}>
        Baixe no seu celular o app SKY e divirta-se.
      </h3>
    </a>
  );
};

class InfoContato extends Component {
  /*   constructor(props) {
      super(props);
    } */

  componentWillMount() {
    const { steps } = this.props;
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <p>
          Nos telefones:
          <br />
          <a
            href="tel:0800-283-6111"
            style={{ color: '#fff', textDecoration: 'none' }}
          >
            <strong>0800 283 6111 </strong>
          </a>
          ou
          <a href="tel:10611" style={{ color: '#fff', textDecoration: 'none' }}>
            <strong> 10611.</strong>
          </a>
        </p>
      </div>
    );
  }
}

InfoContato.propTypes = {
  steps: PropTypes.object,
};

InfoContato.defaultProps = {
  steps: undefined,
};
class SkyNegociacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: false,
      result: undefined,
      placeholder: 'digite aqui...',
    };
    this.sendPutRequest = sendPutRequest(this);
  }

  static contextType = ResourcesContext;

  componentWillMount() {
    alterMetaTag(
      'SKY negociação',
      /\/[0-9]/.exec(this.props.match.path),
      '#e30609'
    );
    this.handleEnd = this.handleEnd.bind(this);
  }

  async sendPutRequest(step) {
    var data = JSON.stringify({
      id: this.state.result.id,
      idAcao: this.state.result.idAcao,
      session: this.state.sessionChat,
      step: step.id,
      frase: step.message,
      resposta: step.value,
      status: this.state.status ? this.state.status : '',
      statusCode: this.state.statusCode ? this.state.statusCode : '',
    });

    console.log(step)

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi
      .post('/ContatoMidia', data)
      .then(function(response) {
        //console.log('[sendPutRequest]', JSON.stringify(response.data));
      })
      .catch(function(error) {
        //console.log('[sendPutRequest]', error);
      });
  }

  async componentDidMount() {
    if (!validateId(this.props.match.params.id)) {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage:
          'Identificamos informações incorretas. Tente novamente mais tarde.',
      });
      return;
    }

    let urlParameters = this.props.match.params.id.split('_');

    let sessionChat =
      urlParameters[0] +
      '_' +
      urlParameters[1] +
      '_' +
      moment().format('YYYYMMDD_HHMMSS');
    urlParameters = 'id=' + urlParameters[0] + '&idAcao=' + urlParameters[1];
    this.setState({ sessionChat: sessionChat });

    let resultVyaApi = {};
    //Coletando dados do contato na plataforma
    try {
      const response = await vyaApi.get(`Contato?${urlParameters}`);

      if (JSON.parse(response.data.Status == false)) {
        this.setState({
          isLoading: false,
          error: true,
          errorMessage: 'Os dados não foram localizados no sistema principal.',
        });
        return;
      }

      resultVyaApi = JSON.parse(response.data.Data);
      console.log('[resultVyaApi]', resultVyaApi);
      this.setState({
        result: resultVyaApi,
        isLoading: false,
        error: false,
      });

      //Registrar dados iniciais
      this.sendPutRequest({
        id: 'API',
        message: response.data.Message,
        value: response.data.Status,
      });
    } catch (err) {
      console.log('Erro: API ', err);
      this.setState({
        isLoading: false,
        error: true,
        errorMessage: 'Os dados não foram localizados no sistema principal.',
      });
      return;
    }
  }

  handleEnd({ steps, values }) {
    if (!this.state.stepFinal) {
      return;
    }

    var data = JSON.stringify({
      id: this.state.result.id,
      idAcao: this.state.result.idAcao,
      session: this.state.sessionChat,
      step: this.state.stepFinal.id,
      frase: this.state.stepFinal.message,
      resposta: this.state.stepFinal.value,
      status: this.state.status,
      statusCode: this.state.statusCode,
    });

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi
      .post('/ContatoMidia', data)
      .then(function(response) {
        //alert('Obrigado. Terminamos seu atendimento.');
      })
      .catch(function(error) {
        console.log('[sendPutRequest-' + steps.id + ']', error);
      });
  }

  ExportAVista = ({ steps }) => (
    <ExportV2
      eventTitle="Pagar Fatura SKY"
      eventDetailsLabel={`Acordo SKY no valor de ${currencyDisplay(
        steps.initial.metadata.valorapagarcomdesconto.replace(',', '.'),
        2
      )}`}
      eventDetails={`Acordo SKY no valor de <strong>${currencyDisplay(
        steps.initial.metadata.valorapagarcomdesconto.replace(',', '.'),
        2
      )}</strong>`}
      eventDay={
        parseISO(flexFormat(steps.initial.metadata.cob_data_fim, 'YMD')) ||
        new Date()
      }
      barCode={steps.initial.metadata.linha_digitavel_desconto || ''}
      handleEnd={(id, message) => {
        let stepFinal = { id, message, value: '' };

        this.sendPutRequest(stepFinal)
      }}
    />
  );

  ExportParcelado = ({ steps }) => (
    <ExportV2
      eventTitle="Pagar Fatura SKY"
      eventDetailsLabel={`Acordo SKY ${
        steps.initial.metadata.quantidadedeparcelas
      }x de ${currencyDisplay(
        steps.initial.metadata.valor_parcela.replace(',', '.'),
        2
      )}`}
      eventDetails={`Acordo SKY ${
        steps.initial.metadata.quantidadedeparcelas
      }x de <strong>${currencyDisplay(
        steps.initial.metadata.valor_parcela.replace(',', '.'),
        2
      )}</strong>`}
      eventDay={
        parseISO(flexFormat(steps.initial.metadata.cob_data_fim, 'YMD')) ||
        new Date()
      }
      barCode={steps.initial.metadata.linha_digitavel_parcelamento || ''}
      handleEnd={(id, message) => {
        let stepFinal = { id, message, value: '' };

        this.sendPutRequest(stepFinal)
      }}
    />
  );

  render() {
    const { isLoading, result, error } = this.state;

    if (isLoading) {
      return (
        <div className="App">
          <DiscussLoader />
        </div>
      );
    }

    if (error) {
      return (
        <Ops
          theme={theme}
          headerComponent={header}
          botAvatar="/3/avatar.png"
          message={this.state.errorMessage ? this.state.errorMessage : null}
        />
      );
    }

    // all available props

    let steps = [];
    steps = [
      {
        id: 'initial',
        message: `${saudacao()}`,
        metadata: this.state.result,
        delay: 800,
        trigger: ({ value, steps }) => {
          this.setState({ status: 'ENTR', statusCode: 'ENTR' });
          this.sendPutRequest(steps.initial);

          let date = this.state.result.cob_data_fim;
          date = date.split('/');
          date = `${date[2]}-${date[1]}-${date[0]}`;
          date = parseISO(date);

          if (isBefore(startOfDay(date), startOfDay(new Date()))) {
            return 'informacao_link_expirado';
          }
          return 'apresentacao';
        },
      },
      {
        id: 'informacao_link_expirado',
        message: 'Infelizmente esse link de negociação expirou :(',

        trigger: ({ value, steps }) => {
          sendPutRequest(steps.informacao_link_expirado);
          return 'entre_contato_link_expirado';
        },
        delay: 350,
      },
      {
        id: 'entre_contato_link_expirado',
        message: 'Por gentileza, entre em contato com a nossa central',
        trigger: ({ value, steps }) => {
          sendPutRequest(steps.entre_contato_link_expirado);
          return 'info_contato';
        },
      },
      {
        id: 'apresentacao',
        message: ({ previousValue, steps }) => {
          return (
            'Olá ' +
            steps.initial.metadata.nome_cliente +
            ' meu nome é K, sou agente virtual da SKY.'
          );
        },
        delay: 600,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.apresentacao);
          return 'info_debito';
        },
      },
      {
        id: 'info_debito',
        message: ({ previousValue, steps }) => {
          return `Identificamos que existem débitos em seu nome no valor de ${currencyDisplay(
            steps.initial.metadata.cob_valor_divida.replace(',', '.'),
            2
          )} que venceu em ${
            steps.initial.metadata.cob_data_envio
          }. Você já efetuou o esse pagamento?`;
        },
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.info_debito);
          return 'debitos_pendentes';
        },
        delay: 600,
      },
      {
        id: 'debitos_pendentes',
        options: [
          {
            value: 'NAOPAGOU',
            label: 'Ver ótimas opções 👍',
            trigger: ({ value, steps }) => {
              this.sendPutRequest(steps.debitos_pendentes);
              return 'pagamento_1';
            },
          },
          {
            value: 'JAPAGOU',
            label: 'Já paguei',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'PAGO', statusCode: 'PAGO' });
              this.sendPutRequest(steps.debitos_pendentes);
              return 'japagou_mensagem';
            },
          },
          {
            value: 'DESC',
            label: 'Desconheço esta dívida',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'DESC', statusCode: 'DESC' });
              this.sendPutRequest(steps.debitos_pendentes);
              return 'desconhece_divida_mensagem';
            },
          },
        ],
      },
      {
        id: 'pagamento_1',
        message: ({ previousValue, steps }) => {
          let msg = '';
          let discount = +steps.initial.metadata.desconto.replace(/\D/g, '');

          if (discount === 0) {
            msg =
              'Entendi. Consegue pagar o valor ' +
              currencyDisplay(
                steps.initial.metadata.valorapagarcomdesconto.replace(',', '.'),
                2
              ) +
              ' até a data ' +
              steps.initial.metadata.cob_data_fim +
              '?';
          } else {
            msg = `Apliquei um desconto de ${steps.initial.metadata.desconto}${
              /%/g.test(steps.initial.metadata.desconto) ? '' : '%'
            } 😄, o valor para pagamento ficará apenas ${currencyDisplay(
              steps.initial.metadata.valorapagarcomdesconto.replace(',', '.'),
              2
            )}. Escolha uma opção para pagamento:`;
          }

          return msg;
        },
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_1);

          if (
            steps.initial.metadata.quantidadedeparcelas &&
            steps.initial.metadata.quantidadedeparcelas !== '0'
          ) {
            return 'pagamento_1_2_parcelas';
          } else {
            return 'pagamento_1_a_vista';
          }
        },
      },
      {
        id: 'pagamento_1_2_parcelas',
        options: [
          {
            value: 'NENHUM',
            label: 'Nenhuma',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'RECU', statusCode: 'RECU' });
              this.sendPutRequest(steps.pagamento_1_2_parcelas);

              return 'parcelamento_nenhuma';
            },
          },
          {
            value: 'AVISTA',
            label: `À vista por ${currencyDisplay(
              this.state.result.valorapagarcomdesconto.replace(',', '.'),
              2
            )}`,
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACAV', statusCode: 'ACAV' });
              this.sendPutRequest(steps.pagamento_1_2_parcelas);

              return 'pre_agradecimento_a_vista';
            },
          },
          {
            value: 'PARCELAS',
            label: `${
              this.state.result.quantidadedeparcelas
            } parcelas de ${currencyDisplay(
              Number(this.state.result.valor_parcela.replace(',', '.')),
              2
            )}`,
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACPA', statusCode: 'ACPA' });
              this.sendPutRequest(steps.pagamento_1_2_parcelas);

              return 'pre_agradecimento_parcelado';
            },
          },
        ],
      },
      {
        id: 'pagamento_1_a_vista',
        options: [
          {
            value: 'NAO',
            label: 'Não',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'RECU', statusCode: 'RECU' });
              this.sendPutRequest(steps.pagamento_1_a_vista);

              return 'parcelamento_nenhuma';
            },
          },
          {
            value: 'AVISTA',
            label: 'Sim',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACAV', statusCode: 'ACAV' });
              this.sendPutRequest(steps.pagamento_1_a_vista);

              return 'pre_agradecimento_a_vista';
            },
          },
        ],
      },
      {
        id: 'pagamento_linha_digitavel_a_vista',
        message: ({ steps }) => {
          return `Pronto! Acordo registrado! 🎉`;
        },
        delay: 6500,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_linha_digitavel_a_vista);

          return 'informar_numero';
        },
      },
      {
        id: 'pagamento_linha_digitavel_parcelado',
        message: ({ steps }) => {
          return `Pronto! Acordo registrado! 🎉`;
        },
        delay: 6500,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_linha_digitavel_parcelado);

          return 'informar_numero';
        },
      },
      {
        id: 'informar_numero',
        component: (
          <p>
            Dúvidas ligue{' '}
            <a
              href="tel:08002836111"
              style={{ color: '#fff', textDecoration: 'none', fontWeight: 700 }}
            >
              0800 283 6111
            </a>{' '}
            ou{' '}
            <a
              href="tel:10611"
              style={{ color: '#fff', textDecoration: 'none', fontWeight: 700 }}
            >
              10611
            </a>
          </p>
        ),
        asMessage: true,
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.informar_numero);

          return 'agradecimento';
        },
      },

      {
        id: 'copiar_linha_digitavel_parcelado',
        component: <this.ExportParcelado />,
        end: true,
      },
      {
        id: 'copiar_linha_digitavel_a_vista',
        component: <this.ExportAVista />,
        end: true,
      },
      {
        id: 'pre_agradecimento_a_vista',
        component: (
          <span>
            É importante que o pagamento ocorra dentro do prazo para evitar a
            negativação ou cancelamento do seu serviço!
          </span>
        ),
        asMessage: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pre_agradecimento_a_vista);

          return 'pagamento_linha_digitavel_a_vista';
        },
      },
      {
        id: 'pre_agradecimento_parcelado',
        component: (
          <span>
            É importante que o pagamento ocorra dentro do prazo para evitar a
            negativação ou cancelamento do seu serviço!
          </span>
        ),
        asMessage: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pre_agradecimento_parcelado);

          return 'pagamento_linha_digitavel_parcelado';
        },
      },

      //************************************************************//
      //************************************************************//
      //************************************************************//
      //************************************************************//
      {
        id: 'pagamento_1_sim_processando',
        delay: 6500,
        message: 'Aguarde um instante que estou gerando o boleto',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_1_sim_processando);
          console.log(
            '[this.state.result.linhaDigitavel]',
            this.state.result.linhaDigitavel
          );
          return 'pagamento_linha_digitavel';
        },
      },

      {
        id: 'pagamento_formas_parcelamento',
        message:
          'Vamos parcelar? É só escolher uma dessas opções de parcelamento.',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_formas_parcelamento);

          //Verifica se será possivel ter parcelamento
          // 3 parcelas e a parcela deve ser maior que 25 reais
          if (this.state.result.valorBoletoOriginal / 5 <= 25) {
            return 'pagamento_formas_parcelamento_opcoes_1';
          } else {
            return 'pagamento_formas_parcelamento_opcoes';
          }
        },
      },
      {
        id: 'pagamento_formas_parcelamento_1',
        /* message: 'Consegui o parcelamento é só escolher a opção abaixo.', */
        message: 'E se eu parcelar o valor para você em ...',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_formas_parcelamento_1);

          //Verifica se será possivel ter parcelamento
          // 3 parcelas e a parcela deve ser maior que 25 reais
          if (this.state.result.valorBoletoOriginal / 5 <= 25) {
            return 'pagamento_formas_parcelamento_opcoes_1';
          } else {
            return 'pagamento_formas_parcelamento_opcoes';
          }
        },
      },
      {
        id: 'pagamento_formas_parcelamento_opcoes',
        options: [
          {
            value: 'parcelamento_nenhuma',
            label: 'Nenhuma',
            trigger: ({ value, steps }) => {
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes);
              return 'parcelamento_nenhuma';
            },
          },
          {
            value: 'parcelamento_1_2',
            label: '3 Parcelas (1+2)',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACPA', statusCode: 'ACPA' });
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes);

              return 'pagamento_parcelamento_sim_processando';
            },
          },
          {
            value: 'parcelamento_1_4',
            label: '5 Parcelas (1+4)',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACPA', statusCode: 'ACPA' });
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes);

              let obj = this.state.result;
              obj.qtdParcelas = 5;

              return 'pagamento_parcelamento_sim_processando';
            },
          },
        ],
      },

      {
        id: 'pagamento_formas_parcelamento_opcoes_1',
        options: [
          {
            value: 'parcelamento_nenhuma',
            label: 'Não quero',
            trigger: ({ value, steps }) => {
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes_1);
              return 'parcelamento_nenhuma';
            },
          },
          {
            value: 'parcelamento_1_2',
            label: '3 Parcelas (1+2)',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'ACPA', statusCode: 'ACPA' });
              this.sendPutRequest(steps.pagamento_formas_parcelamento_opcoes_1);

              return 'pagamento_parcelamento_sim_processando';
            },
          },
        ],
      },

      {
        id: 'desconhece_divida_mensagem',
        component: (
          <span>
            Por favor, encaminhe para o e-mail:{' '}
            <a
              href="mailto:skydocumentos@sky.com.br"
              style={{
                color: '#fff',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
            >
              skydocumentos@sky.com.br
            </a>
            <br />
            uma carta de próprio punho informando que desconhece a assinatura,
            com o comprovante de endereço em seu nome e RG.<br></br>Dúvidas
            ligue{' '}
            <a
              href="tel:+10611"
              style={{
                color: '#fff',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
            >
              10611
            </a>
          </span>
        ),
        asMessage: true,
        // message: `Por gentileza, entre em contato com nossa central para maiores informações .`,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.desconhece_divida_mensagem);
          return 'agradecimento_geral';
        },
      },
      {
        id: 'info_contato_desconhece',
        component: <InfoContato />,
        asMessage: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.info_contato_desconhece);
          return 'agradecimento_geral';
        },
      },

      {
        id: 'parcelamento_nenhuma',
        message:
          'Como não conseguimos fechar um acordo, por favor, entre em contato com a nossa central de atendimento.',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.parcelamento_nenhuma);
          return 'info_contato';
        },
      },
      {
        id: 'recusa_mensagem',
        message:
          'Como não conseguimos fechar um acordo, por favor, entre em contato com a nossa central de atendimento.',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.recusa_mensagem);
          return 'info_contato';
        },
      },

      {
        id: 'info_contato',
        component: <InfoContato />,
        asMessage: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.info_contato);
          return 'lembrando';
        },
        delay: 2000,
      },
      {
        id: 'end_error_message',
        message:
          'Ops :( estamos com instabilidade no sistema por favor tente novamente em alguns minutos. A SKY agradece sua compreensão!',
        end: true,
      },
      {
        id: 'lembrando',
        message: ({ previousValue, steps }) => {
          //let message = 'Lembrando que os juros e encargos são diários e seu nome poderá ser incluso nos orgãos de proteção ao crédito. A SKY agradece sua atenção!'
          let message = 'A SKY agradece sua atenção!';
          let stepFinal = { id: 'lembrando', message: message, value: '' };
          this.setState({ stepFinal });
          return message;
        },
        end: true,
      },

      {
        id: 'japagou_mensagem',
        message:
          'Ótimo! A confirmação do pagamento é registrada em nosso sistema em até 48 horas.',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.japagou_mensagem);
          return 'agradecimento_japagou';
        },
      },
      {
        id: 'agradecimento_geral',
        message: ({ previousValue, steps }) => {
          let message = 'A SKY agradece sua atenção.';
          let stepFinal = { id: 'agradecimento_geral', message: message, value: '' };
          this.setState({ stepFinal });
          return message;
        },
        end: true,
      },
      {
        id: 'agradecimento',
        message: ({ previousValue, steps }) => {
          let message = 'Opções de pagamento e agenda';
          let stepFinal = { id: 'exportar_agenda', message: message, value: '' };
          this.setState({ stepFinal });
          return 'A SKY agradece a sua atenção';
        },
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.agradecimento);
          this.setState({ placeholder: 'A SKY agradece sua atenção. 👋' });
          if (steps.pagamento_linha_digitavel_parcelado) {
            return 'copiar_linha_digitavel_parcelado';
          } else {
            return 'copiar_linha_digitavel_a_vista';
          }
        },
      },
      {
        id: 'agradecimento_japagou',
        message: 'A SKY agradece sua atenção.',
        trigger: ({ prev, steps }) => {
          let stepFinal = {
            id: 'agradecimento',
            message: 'A SKY agradece sua atenção.',
            value: '',
          };
          this.setState({ stepFinal });
          return 'aplicativo';
        },
      },
      {
        id: 'aplicativo',
        component: <SkyApp />,
        end: true,
      },
      {
        id: 'end-error-message',
        message: 'Desculpe! SKY agradece sua atenção!',
        end: true,
      },
    ];

    return (
      <ThemeProvider theme={theme}>
        <ChatBot
          headerComponent={header}
          // Avatar do Bot e do usuário //
          botAvatar={/\/[0-9]/.exec(this.props.match.path) + '/avatar.png'}
          handleEnd={this.handleEnd}
          recognitionEnable={false}
          recognitionPlaceholder="Escutando ..."
          placeholder={this.state.placeholder}
          botDelay={300}
          /* headerTitle="SKY" */
          floating={false}
          steps={steps}
          delay={500}
          // Estilo do Rodapé (Footer) //
          /* footerStyle={{ backgroundColor: '#fff', margin: 5, padding: 1, borderRadius: 7, elevation: 2, }}
submitButtonStyle={{ backgroundColor: '#E89984', borderRadius: 4, width: 63, margin: 2 }} */
        />
      </ThemeProvider>
    );
  }
}

export default SkyNegociacao;
