import axios from "axios";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Component } from "react";
import Chatbot, { Loading } from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import { ResourcesContext } from "../../context/ResourcesContext";
import { addDays, FORMAT, formatDate } from "../../utils/date";
import addUtilDays from "../../utils/addUtilDays";
import { splitText } from "../../utils/text";
import { saudacao } from "../../components/chat/saudacao";
import DatePI from "../DatePI";
import ExportOptions from "../ExportOptions";
import WaitResponse from "../WaitResponse";

import Avatar from "../../assets/avatar-bot.png";
import LogoVyaBlack from "../../assets/vya_logo_black.png";
import { currencyDisplay } from "../../utils/currency";
import ExportV2 from "../ExportV2";
import { flexFormat } from "../../utils/flexFormat";
import VyaChannel from "../VyaChannel";

const Vya = () => {
  return (
    <a
      href="https://vya.digital/show/vya.apresentacao.pdf"
      target="_blank"
      rel="noreferrer noopened"
      style={{
        display: "flex",
        minHeight: "50px",
        padding: "0px 20px",
        alignItems: "center",
        textDecoration: "none",
        flexDirection: "column",
      }}
    >
      <img
        src={LogoVyaBlack}
        alt="logo vya.digital"
        style={{ width: "100px", marginBottom: "10px" }}
      />
      <h3 style={{ color: "#4f4f4f", fontSize: 14 }}>
        Saiba mais sobre nossos produtos.
      </h3>
    </a>
  );
};

export default class DefaultChatbot extends Component {
  static contextType = ResourcesContext;
  constructor(props) {
    super(props);

    this.state = {
      data: {
        nome: "Nilza",
        nomeRede: "Carrefour",
        cpf: "123456789",
        acordoAtivo: 0,
        valorBoleto: 250,
        valorBoletoCurr: "R$ 250,00",
        valorBoletoOriginal: 500,
        dataAcordo: format(addDays(new Date(), 3), "dd/MM/yyyy", {
          locale: ptBR,
        }),
        vencimento: "22/11/2020",
        valor: "R$ 500",
        placeholder: "Digite aqui sua mensagem...",
      },
    };
  }

  InfoContato = () => (
    <div style={{ width: "100%" }}>
      {/* <h3></h3> */}
      <table>
        <tbody>
          <tr>
            <td>No telefone:</td>
          </tr>
          <tr>
            <td>
              <a
                href="tel:1131350318"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <strong>(11) 3135-0318</strong>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  processBillet = () => {
    setTimeout(() => {
      this.context.setValues(
        "https://vya.digital/show/boleto.pdf",
        "03399076364030010025803474301011688330000033695"
      );
    }, 5000);
  };

  handleEnd = () => {
    this.setState({
      data: {
        ...this.state.data,
        placeholder: "vya.digital 👋 (11) 3135-0318",
      },
    });
    this.context.handleEnded();
  };

  Apresentacao = ({ steps }) => (
    <span>
      Olá {steps.initial.metadata.nome}, meu nome é Ricardo agente virtual da{" "}
      <strong>vya.digital</strong>.
    </span>
  );

  getState = (key) => this.state.data[key];

  Export = ({ steps }) => (
    <ExportV2
      eventTitle="Pagar Fatura vya.digital"
      eventDetailsLabel={`Acordo vya.digital no valor de ${currencyDisplay(
        this.getState("valorBoleto"),
        2
      )}`}
      eventDetails={`Acordo vya.digital no valor de <strong>${currencyDisplay(
        this.getState("valorBoleto"),
        2
      )}</strong>`}
      eventDay={
        parseISO(flexFormat(this.getState("dataAcordo"), "YMD")) || new Date()
      }
      barCode={this.context.digitableLine || ""}
      billet={this.context.fileUrl}
      handleEnd={() => {}}
      onDownloadBillet={() => {}}
    />
  );

  render() {
    const steps = [
      {
        id: "initial",
        message: `${saudacao()}`,
        metadata: this.state.data,
        trigger: "apresentacao",
      },
      {
        id: "apresentacao",
        component: <this.Apresentacao />,
        asMessage: true,
        trigger: "info_importantes",
      },
      {
        id: "info_importantes",
        message: "Tenho informações importantes para você.",
        trigger: "solicitar_cpf",
      },
      {
        id: "solicitar_cpf",
        message:
          "Mas para sua segurança, me confirme os 3 primeiros digitos do seu CPF...",
        trigger: "get_cpf",
      },
      {
        id: "get_cpf",
        user: true,
        validator: (value) => {
          if (isNaN(value)) {
            return "Por favor digite apenas número";
          }
          return true;
        },
        trigger: "validate_cpf",
      },
      {
        id: "validate_cpf",
        message: ({ previousValue, steps }) => {
          if (
            previousValue.substring(0, 3) ===
            steps.initial.metadata.cpf.substring(0, 3)
          ) {
            if (steps.initial.metadata.acordoAtivo === 1) {
              return (
                steps.initial.metadata.nome +
                `, identificamos que existe um acordo ativo para a loja ${steps.initial.metadata.nomeRede}. Caso necessite entre em contato conosco.`
              );
            } else {
              // return (
              //   'Obrigado pela confirmação dos dados. ' +
              //   steps.initial.metadata.nome +
              //   ` identificamos que existem débitos em seu nome na loja ${steps.initial.metadata.nomeRede} no valor de ${steps.initial.metadata.valor} que venceu no dia ${steps.initial.metadata.vencimento}. Você efetuou esse pagamento?`
              // );

              return `Sabe aquele debito do ${
                steps.initial.metadata.nomeRede
              } de ${currencyDisplay(
                steps.initial.metadata.valorBoletoOriginal,
                2
              )}? Você fechando agora comigo, consigo um desconto de 50% eu te mando um boleto de ${currencyDisplay(
                steps.initial.metadata.valorBoleto,
                2
              )}. O que você acha?`;
            }
          } else {
            return "Ops! CPF incorreto vamos tentar novamente.";
          }
        },
        delay: 1200,
        trigger: ({ value, steps }) => {
          if (
            steps.get_cpf.value.substring(0, 3) ===
            steps.initial.metadata.cpf.substring(0, 3)
          ) {
            if (steps.initial.metadata.acordoAtivo === 1) {
              //return 'pagamento_1_sim_nao'
              return "info_contato";
            } else {
              return "debitos_pendentes";
            }
          } else {
            return "solicitar_cpf";
          }
        },
      },
      {
        id: "segundavia_boleto",
        options: [
          { value: "SIM", label: "Sim", trigger: "pagamento_1" },
          { value: "NAO", label: "Não", trigger: "desconhece_divida_mensagem" },
        ],
      },
      {
        id: "debitos_pendentes",
        options: [
          {
            value: "NAOPAGOU",
            label: "Gostei! 👍",
            trigger: "pagamento_1",
          },
          {
            value: "NAOGOSTEI",
            label: "Não gostei! 👎",
            trigger: "feedback",
          },
          {
            value: "JAPAGOU",
            label: "Já paguei",
            trigger: "japagou_mensagem",
          },
          {
            value: "DESC",
            label: "Desconheço esta dívida",
            trigger: "desconhece_divida_mensagem",
          },
        ],
      },
      {
        id: "feedback",
        message: "😔 O que eu poderia ter feito para te ajudar?",
        trigger: "digitar_feedback",
      },

      {
        id: "digitar_feedback",
        user: true,
        trigger: "feedback_registrado",
      },
      {
        id: "feedback_registrado",
        message: "Registrado. Vou falar com os meus criadores! 👍",
        trigger: "feedback_info",
      },
      {
        id: "pagamento_1",
        message: ({ previousValue, steps }) => {
          const date = format(
            addUtilDays(new Date(), 3, true),
            "dd 'de' MMMM 'na' EEEE",
            {
              locale: ptBR,
            }
          );

          return `Você pode pagar até o dia ${date}?`;
        },
        trigger: "pagamento_1_sim_nao",
      },
      {
        id: "pagamento_1_sim_nao",
        options: [
          {
            value: "NAO",
            label: "Escolher melhor data",
            trigger: "prazo_pagamento",
          },
          {
            value: "SIM",
            label: "Sim",
            trigger: () => {
              return "escolha_forma_pagamento";
            },
          },
        ],
      },
      {
        id: "prazo_pagamento",
        message: "Escolha sua melhor data para pagamento.",
        trigger: "escolha_prazo_pagamento",
      },
      {
        id: "escolha_prazo_pagamento",
        component: <DatePI />,
        waitAction: true,
        delay: 4000,
        trigger: ({ value, steps }) => {
          if (this.state.data.valorBoletoOriginal / 3 <= 25) {
            return "pagamento_formas_parcelamento_1";
          } else {
            if (this.state.data.valorBoletoOriginal / 5 <= 25) {
              return "pagamento_formas_parcelamento_3";
            }
            return "pagamento_formas_parcelamento";
          }
        },
      },
      {
        id: "pagamento_linha_digitavel_continua",
        delay: 1000,
        message: ({ previousValue, steps }) => {
          return (
            "Copie a linha digitável para efetuar o pagamento " +
            splitText(this.state.data.linhaDigitavel, 12)
          );
        },
        trigger: "agradecimento",
      },
      {
        id: "pagamento_formas_parcelamento",
        message:
          "Vamos parcelar? É só escolher uma dessas opções de parcelamento com desconto.",
        trigger: ({ value, steps }) => {
          console.log(
            format(this.context.date, "dd/MM/yyyy", {
              locale: ptBR,
            })
          );
          this.setState({
            data: {
              ...this.state.data,
              dataAcordoOriginal: format(this.context.date, "yyyy-MM-dd", {
                locale: ptBR,
              }),
              dataAcordo: format(this.context.date, "dd/MM/yyyy", {
                locale: ptBR,
              }),
            },
          });

          return "pagamento_formas_parcelamento_opcoes_5";
        },
      },
      {
        id: "pagamento_formas_parcelamento_3",
        message:
          "Vamos parcelar? É só escolher uma dessas opções de parcelamento com desconto.",
        trigger: ({ value, steps }) => {
          console.log(
            format(this.context.date, "dd/MM/yyyy", {
              locale: ptBR,
            })
          );
          this.setState({
            data: {
              ...this.state.data,
              dataAcordoOriginal: format(this.context.date, "yyyy-MM-dd", {
                locale: ptBR,
              }),
              dataAcordo: format(this.context.date, "dd/MM/yyyy", {
                locale: ptBR,
              }),
            },
          });

          return "pagamento_formas_parcelamento_opcoes_3";
        },
      },
      {
        id: "pagamento_formas_parcelamento_1",
        message: "Ok, data registrada. Escolha a opção de pagamento à vista",
        trigger: ({ value, steps }) => {
          this.setState({
            data: {
              ...this.state.data,
              dataAcordoOriginal: format(this.context.date, "yyyy-MM-dd", {
                locale: ptBR,
              }),
              dataAcordo: format(this.context.date, "dd/MM/yyyy", {
                locale: ptBR,
              }),
            },
          });

          return "pagamento_formas_parcelamento_opcoes_1";
        },
      },

      {
        id: "pagamento_formas_parcelamento_opcoes_5",
        options: [
          {
            value: "parcelamento_nenhuma",
            label: "Nenhuma",
            trigger: "parcelamento_nenhuma",
          },
          {
            value: "parcelamento_1_0",
            label: "1 Parcela (À vista)",
            trigger: "escolha_forma_pagamento",
          },
          {
            value: "parcelamento_1_2",
            label: "3 Parcelas (1+2)",
            trigger: () => {
              this.setState({
                data: {
                  ...this.state.data,
                  valorBoleto: this.state.data.valorBoleto / 3,
                  valorBoletoCurr: (
                    this.state.data.valorBoleto / 3
                  ).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }),
                },
              });

              return "escolha_forma_pagamento";
            },
          },
          {
            value: "parcelamento_1_4",
            label: "5 Parcelas (1+4)",
            trigger: () => {
              this.setState({
                data: {
                  ...this.state.data,
                  valorBoleto: this.state.data.valorBoleto / 5,
                  valorBoletoCurr: (
                    this.state.data.valorBoleto / 5
                  ).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }),
                },
              });

              return "escolha_forma_pagamento";
            },
          },
        ],
      },
      {
        id: "pagamento_formas_parcelamento_opcoes_1",
        options: [
          {
            value: "parcelamento_nenhuma",
            label: "Nenhuma",
            trigger: "parcelamento_nenhuma",
          },
          {
            value: "parcelamento_1_0",
            label: "1 Parcela (À vista)",
            trigger: "escolha_forma_pagamento",
          },
        ],
      },

      {
        id: "pagamento_formas_parcelamento_opcoes_3",
        options: [
          {
            value: "parcelamento_nenhuma",
            label: "Nenhuma",
            trigger: "parcelamento_nenhuma",
          },
          {
            value: "parcelamento_1_0",
            label: "1 Parcela (À vista)",
            trigger: "escolha_forma_pagamento",
          },
          {
            value: "parcelamento_1_2",
            label: "3 Parcelas (1+2)",
            trigger: "escolha_forma_pagamento",
          },
        ],
      },
      {
        id: "desconhece_divida_mensagem",
        message: `Por gentileza, entre em contato com nossa central para maiores informações .`,
        trigger: "info_contato_desconhece",
      },
      {
        id: "feedback_info",
        message: "Conheça nossos Canais!",
        trigger: "vya_channel",
      },
      {
        id: "vya_channel",
        component: <VyaChannel />,
        trigger: "agradecimento",
      },
      {
        id: "escolha_forma_pagamento",
        message: () =>
          `Você escolheu pagar ${this.state.data.valorBoletoCurr} com vencimento dia ${this.state.data.dataAcordo}`,
        trigger: "pagamento_1_sim_processando_aguarde",
      },
      {
        id: "pagamento_linha_digitavel",
        delay: 0,
        message: ({ previousValue, steps }) => {
          console.log(this.state);
          return (
            "Pronto! Aqui está o seu boleto no valor de " +
            this.state.data.valorBoletoCurr +
            " e vencimento para o dia " +
            this.state.data.dataAcordo
          );
        },
        trigger: ({ value, steps }) => {
          return "boleto_opcoes";
        },
      },
      {
        id: "info_contato_desconhece",
        component: <this.InfoContato />,
        asMessage: true,
        trigger: "agradecimento",
      },

      {
        id: "parcelamento_nenhuma",
        message:
          "Como não conseguimos fechar um acordo, por favor, entre em contato com a nossa central de atendimento",
        trigger: "info_contato",
      },
      {
        id: "recusa_mensagem",
        message:
          "Como não conseguimos fechar um acordo, por favor, entre em contato com a nossa central de atendimento",
        trigger: "info_contato",
      },

      {
        id: "info_contato",
        component: <this.InfoContato />,
        asMessage: true,
        trigger: "agradecimento",
        delay: 2000,
      },
      {
        id: "end_error_message",
        message:
          "Ops :( estamos com instabilidade no sistema por favor tente novamente em alguns minutos. A vya.digital agradece sua compreensão!",
        end: true,
      },
      {
        id: "agradecimento",
        message: "A vya.digital agradece sua atenção!",
        trigger: "learn_about_vya", //,
        //end: true,
      },
      {
        id: "learn_about_vya",
        component: <Vya />,
        end: true,
      },

      {
        id: "japagou_mensagem",
        message:
          "Ótimo! A confirmação do pagamento é registrada em nosso sistema em até 48 horas.",
        trigger: "agradecimento",
      },
      {
        id: "pagamento_1_sim_processando_aguarde",
        message: "Aguarde que estamos gerando o seu boleto.",
        trigger: ({ _, steps }) => {
          this.processBillet();
          steps.initial.metadata = this.state.data;
          return "loading";
        },
      },
      {
        id: "loading",
        component: <WaitResponse />,
        asMessage: true,
        waitAction: true,
        replace: true,
        trigger: "receber_email",
      },
      {
        id: "receber_email",
        message: "Deseja receber por email seu boleto?",
        delay: 0,
        trigger: "receber_email_options",
      },
      {
        id: "receber_email_options",
        options: [
          {
            value: "SIM",
            label: "Sim",
            trigger: ({ _, steps }) => {
              if (steps.initial.metadata.email) {
                return "email-confirm-user";
              }

              return "email-confirm";
            },
          },
          {
            value: "NÃO",
            label: "Não",
            trigger: "export_v2",
          },
        ],
      },
      {
        id: "email-confirm",
        message: "Qual o seu email?",
        trigger: "email_edit",
      },
      {
        id: "email_edit",
        user: true,
        validator: (value) => {
          if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value.trim())) {
            return "Email inválido!";
          }
          return true;
        },
        trigger: "email-confirm-user",
      },
      {
        id: "email-confirm-user",
        message: ({ previousValue, steps }) => {
          return `O email esta correto? ${previousValue.trim() ||
            this.state.data.email}`;
        },
        trigger: "options-email-confirm2",
      },
      {
        id: "options-email-confirm2",
        options: [
          { value: "edit", label: "Sim", trigger: "confirm-email-ok" },
          { value: "não", label: "Não", trigger: "email-confirm" },
        ],
      },
      {
        id: "confirm-email-ok",
        message: ({ previousValue, steps }) => {
          //console.log('email?', steps)
          return `Será enviado para ${steps.email_edit.value.trim()} um email contendo o boleto para o pagamento do seu debito, confira sua caixa de email ou na caixa de spam. Apenas lembrando se ouver juros vira na proxima fatura`; // o numero tem que ser uma variavel recebida da api aqui ta mosdo de demonstraçao
        },
        trigger: ({ steps }) => {
          axios({
            method: "post",
            url: "https://api012.vya.digital/email/send",
            data: {
              sender: {
                name: "fatura.io",
                logo: "https://i.ibb.co/3rXVrF2/vya-logo-black.png",
              },
              destination: {
                name: this.state.data.nome,
                email: steps.email_edit.value.trim(),
              },
              content: {
                template: "boleto",
                subject: "Aqui está o BOLETO que você pediu!",
                dueDate: this.state.data.dataAcordo,
                processDate: format(new Date(), "dd/MM/yyyy 'às' HH:mm'h'"),
                value: this.state.data.valorBoleto.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }),
                attachments: [this.context.fileUrl],
              },
            },
          }).catch((error) => {
            console.log(error.response.data);
          });

          return "export_v2";
        },
      },
      {
        id: "export_v2",
        component: <this.Export />,
        trigger: "vya_channel",
      },
    ];

    return (
      <ThemeProvider theme={this.props.theme}>
        <Chatbot
          recognitionEnable={false}
          placeholder={this.state.data.placeholder}
          botDelay={500}
          floating={false}
          handleEnd={this.handleEnd}
          {...this.props}
          steps={steps}
          botAvatar={this.props.botAvatar || Avatar}
          className={this.props.className || ""}
        />
      </ThemeProvider>
    );
  }
}
