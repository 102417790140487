import { Component, createRef } from 'react';
import { ThemeProvider } from 'styled-components';
import Loader from './Loader';
import Chatbot from 'react-simple-chatbot';
import { theme } from './theme';
import { header } from './Header';
import { saudacao } from '../../../components/chat/saudacao';
import verifyCpf from '../../../utils/verifyCpf';
import { sendPutRequest } from '../../../lib/sendPutRequest';
import CustomOptions from '../../../components/CustomOptions';
import DatePI from '../../../components/DatePI';
import { addDays, format, parseISO } from 'date-fns';
import { currencyDisplay } from '../../../utils/currency';
import vyaApi, { billetApi, cslogApi } from '../../../services/api';
import ExportV2 from '../../../components/ExportV2';
import { textCapitalize } from '../../../utils/text';
import validateId from '../../../utils/validateId';
import { flexFormat } from '../../../utils/flexFormat';
import WaitBillet from './WaitBillet';
import ContractImg from '../../../assets/file-text.svg';
import { alterMetaTag } from '../../../components/chat/metaTag';
import { orderBy, uniqBy } from 'lodash';
import WaitSms from './WaitSms';
import WaitEmail from './WaitEmail';
import { mask } from '../../../utils/remask';

export default class ItauChatbot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      typeCpfTries: 0,
      typeNomeTries: 0,
      userEmailIsCorrect: true,
      placeholder: 'Digite aqui...',
      result: {},
      closeLoader: false,
      phone1: '',
      phone2: '',
    };

    this.sendPutRequest = sendPutRequest(this);
  }

  componentWillMount() {
    alterMetaTag(
      'Fatura digital',
      /\/[0-9]/.exec(this.props.match.path),
      theme.botBubbleColor
    );
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    if (!validateId(this.props.match.params.id)) {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage:
          'Identificamos informações incorretas. Tente novamente mais tarde.',
      });
      return;
    }
    let urlParameters = this.props.match.params.id.split('_');
    const [id, idAcao] = this.props.match.params.id.split('_');
    urlParameters = 'id=' + urlParameters[0] + '&idAcao=' + urlParameters[1];
    let sessionChat =
      this.props.match.params.id + format(new Date(), 'yyyyMMdd_HHmmss');
    this.setState({ sessionChat: sessionChat });
    try {
      const { data } = await vyaApi.get(`Contato?${urlParameters}`);

      let result = JSON.parse(data.Data);
      console.log('[resultVyaApi]', result);

      const {
        data: { contrato },
      } = await cslogApi.get(`/cslog/contracts/${result.id_contr}`);
      console.log('[cslogResponse-contract]', contrato);
      let hasDebt = contrato.infoAdicional.some(
        (info) => info.origem === 'MIPS'
      );
      let infoAdicional = contrato.infoAdicional;
      const firstMipsDebt = infoAdicional.find(
        (info) => info.origem === 'MIPS'
      );
      let totalValue = {};
      if (hasDebt) {
        const {
          data: { calculo },
        } = await cslogApi.get(
          `/cslog/simulations/${result.id_contr}?dueDate=${flexFormat(
            firstMipsDebt.venc,
            'YMD'
          )}&installment=${firstMipsDebt.qtdeParc}&overdueInstallmentQty=${
            firstMipsDebt.contratosOferta.length
          }`
        );

        console.log('[cslogResponse-simulation]', calculo);

        const contrArray = calculo.detalhes.map((details) => details.contrato);

        infoAdicional = contrato.infoAdicional.filter((contr) =>
          contr.contratosOferta.every((contr) => contrArray.includes(contr))
        );

        const { total, totalSemDesc } = calculo;

        totalValue = {
          total: total.replace('.', '').replace(',', '.'),
          totalSemDesc: totalSemDesc.replace('.', '').replace(',', '.'),
        };
      }

      const filteredDebts =
        hasDebt && firstMipsDebt
          ? uniqBy(
              infoAdicional.filter(
                (info) =>
                  info.contratosOferta.join('') ===
                    firstMipsDebt.contratosOferta.join('') &&
                  info.origem === 'MIPS'
              ),
              'qtdeParc'
            )
          : [];

      hasDebt = filteredDebts && filteredDebts.length > 0;

      this.setState({
        result: {
          ...contrato,
          ...totalValue,
          infoAdicional: orderBy(
            filteredDebts,
            (data) => +data.qtdeParc,
            'asc'
          ),
          telefone: result.telefone,
          id,
          id_contr: result.id_contr,
          idAcao,
          email: result.email,
          userEmailIsCorrect:
            result.email &&
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
              result.email
            ),
          dividaAtiva: hasDebt,
          firstMipsDebt,
          parcelamentos: filteredDebts.map((info) => info.qtdeParc),
        },
        phone1: result.phone1 || '1135266014',
        phone2: result.phone2 || '08007265726',
      });
      this.setState({ isLoading: false, error: false, closeLoader: true });
    } catch (error) {
      console.log('[Contato-error]', error);
      this.setState({ isLoading: false, error: true, closeLoader: true });
      this.sendPutRequest({
        idUser: id,
        message: 'erro api',
        idAcao,
        value: 'error',
      });
    }
    this.setState({ isLoading: false, error: false, closeLoader: true });
  }

  handleEnd = ({ steps, values }) => {
    if (!this.state.stepFinal) {
      return;
    }
    var data = {
      id: this.state.result.id,
      idAcao: this.state.result.idAcao,
      session: this.state.sessionChat,
      step: steps.agradecimento.id,
      frase: steps.agradecimento.message,
      resposta: '',
      status: this.state.status,
      statusCode: this.state.statusCode,
    };
    vyaApi.post('/ContatoMidia', data);
  };

  Export = ({ steps }) => {
    const valor = this.state.result.infoAdicional
      .find((opt) => opt.idBoleto === steps.pagamento_opcoes.value)
      ?.valor.replace('.', '')
      .replace(',', '.');
    return (
      <ExportV2
        eventTitle="Pagar Débito Itaú"
        eventDetailsLabel={`Débito Itaú no valor de ${currencyDisplay(
          valor,
          2
        )}`}
        eventDetails={`Débito Itaú no valor de <strong>${currencyDisplay(
          valor,
          2
        )}</strong>`}
        eventDay={parseISO(
          flexFormat(
            this.state.result.infoAdicional.find(
              (opt) => opt.idBoleto === steps.pagamento_opcoes.value
            )?.venc,
            'YMD'
          )
        )}
        barCode={steps.gerar_acordo.value?.barCode}
        handleEnd={(id, message) => {
          let stepFinal = { id, message, value: '' };
          this.setState({ stepFinal });
          this.handleEnd({ steps });
        }}
        onDownloadBillet={() => {
          this.setState({ statusCode: '116', status: 'DBOL' }, () =>
            this.sendPutRequest(steps.exportar_boleto)
          );
        }}
        billet={steps.gerar_acordo.value?.fileUrl}
        //noCalendar
        copy
      />
    );
  };

  render() {
    const { result, phone1, phone2 } = this.state;
    let steps;

    if (!result.dividaAtiva) {
      steps = [
        {
          id: 'initial',
          asMessage: true,
          component: (
            <p>
              {saudacao()} Seu contrato não pode mais ser negociado por este
              canal.
              <br />
              Dúvidas ligue: <br />
              <a href="tel:1135266014" className="link">
                11 3526-6014
              </a>
              <br />
              <a href="tel:08007265726" className="link">
                0800 726 5726
              </a>
              <br />
              ou acesse{' '}
              <a
                href="http://www.siscom.com.br"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                www.siscom.com.br
              </a>
            </p>
          ),
          trigger: ({ steps }) => {
            this.sendPutRequest({
              ...steps.initial,
              message: `${saudacao()} Seu contrato não pode mais ser negociado por este canal. Dúvidas ligue: 11 35266014 / 0800 726 5726 ou acesse www.siscom.com.br`,
            });
            return 'agradecimento';
          },
        },
        {
          id: 'agradecimento',
          message: 'A Siscom agradece a sua atenção.',
          end: true,
        },
      ];
    }

    steps = steps || [
      {
        id: 'initial',
        metadata: result,
        message: `${saudacao()} sou a Julia, agente virtual do Grupo Siscom. Tenho informações importantes para você.`,
        trigger: ({ steps, ...props }) => {
          this.setState({ statusCode: '80', status: 'HUMA' }, () =>
            this.sendPutRequest(steps.initial)
          );

          return 'solicitar_cpf';
        },
      },
      {
        id: 'solicitar_cpf',
        message:
          'Para sua segurança, me confirme os 3 primeiros dígitos do seu CPF',
        trigger: ({ steps }) => {
          this.setState({ typeCpfTries: this.state.typeCpfTries + 1 });
          this.sendPutRequest(steps.solicitar_cpf);
          return 'get_cpf';
        },
      },
      {
        id: 'get_cpf',
        user: true,
        validator: (value) => {
          if (isNaN(value)) {
            return 'Por favor digite apenas número';
          }
          return true;
        },
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '83', status: 'CPFD' }, () =>
            this.sendPutRequest(steps.get_cpf)
          );

          if (verifyCpf(value.slice(0, 3), result.cpfCnpj)) {
            // if (result.inscricao.Pagamento) {
            //   return 'divida_paga';
            // }
            return 'confirmar_nome';
          } else {
            if (this.state.typeCpfTries > 2) {
              return 'mais_informacoes';
            }

            return 'cpf_nao_encontrado';
          }
        },
      },
      {
        id: 'cpf_nao_encontrado',
        message: 'Desculpe, não localizei o seu CPF.',
        trigger: ({ steps }) => {
          this.setState({ statusCode: '79', status: 'NAO' }, () =>
            this.sendPutRequest(steps.cpf_nao_encontrado)
          );

          return 'solicitar_cpf';
        },
      },
      {
        id: 'confirmar_nome',
        message: ({ steps }) =>
          `Eu falo com ${textCapitalize(steps.initial.metadata.nome)}?`,
        trigger: ({ steps }) => {
          this.setState(
            {
              statusCode: '83',
              status: 'CPFD',
              typeNomeTries: this.state.typeNomeTries + 1,
            },
            () => this.sendPutRequest(steps.confirmar_nome)
          );

          return 'confirmar_nome_options';
        },
      },
      {
        id: 'confirmar_nome_options',
        component: <CustomOptions options={['sim', 'não']} />,
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest({
            ...steps.confirmar_nome_options,
            message: value,
          });

          if (value === 'sim') {
            return 'info_negociacao';
          } else {
            return 'mais_informacoes';
          }
        },
      },
      {
        id: 'info_negociacao',
        component: (
          <p>
            Sabe aquele débito do Itaú de{' '}
            {currencyDisplay(result.totalSemDesc, 2)}? Esse débito é referente
            aos seguinte
            {result.firstMipsDebt.contratosOferta.length > 1 ? 's' : ''}{' '}
            contrato
            {result.firstMipsDebt.contratosOferta.length > 1 ? 's' : ''}: <br />{' '}
            {result.firstMipsDebt.contratosOferta.map((contr) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 5,
                }}
                key={contr}
              >
                <img
                  src={ContractImg}
                  style={{
                    width: 16,
                    marginRight: 5,
                  }}
                  alt="contrato"
                />
                <p>{contr}</p>
              </div>
            ))}
          </p>
        ),
        asMessage: true,
        trigger: ({ steps }) => {
          this.sendPutRequest({
            ...steps.info_negociacao,
            message: `Sabe aquele débito do Itaú de ${currencyDisplay(
              result.totalSemDesc,
              2
            )}? Esse débito é referente aos seguinte${
              result.firstMipsDebt.contratosOferta.length > 1 ? 's' : ''
            }{' '}
            contrato${
              result.firstMipsDebt.contratosOferta.length > 1 ? 's' : ''
            }: 
            ${result.firstMipsDebt.contratosOferta.join(', ')}`,
          });
          return 'fechar_negociacao';
        },
      },
      {
        id: 'fechar_negociacao',
        message: () => {
          const discount = 100 - (result.total * 100) / result.totalSemDesc;
          const maxInstallment = result.parcelamentos
            .map((parc) => +parc)
            .sort((a, b) => b - a);
          return discount > 0
            ? `Fechando um acordo comigo agora, consigo um ${
                discount < 15
                  ? ''
                  : discount >= 15 && discount < 40
                  ? 'ótimo'
                  : 'excelente'
              } desconto de ${Math.trunc(discount)}%. Vamos negociar?`
            : `Fechando um acordo comigo agora, consigo desconto ou parcelar em até ${maxInstallment[0]}x. Vamos negociar?`;
        },
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.fechar_negociacao);
          return 'fechar_negociacao_options';
        },
      },
      {
        id: 'fechar_negociacao_options',
        component: (
          <CustomOptions
            options={['sim, negociar', 'já paguei', 'desconheço a dívida']}
            primaryColor="#135394"
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest({
            ...steps.fechar_negociacao_options,
            message: value,
          });
          const options = {
            'sim, negociar': 'pagamento_opcoes_texto',
            'já paguei': 'ja_paguei',
            'desconheço a dívida': 'desconhece_divida',
          };

          return options[value];
        },
      },
      {
        id: 'mais_informacoes',
        component: (
          <p>
            Para conseguir maiores informações, ligue para:
            <br />
            <a href={`tel:${phone1}`} className="link">
              {mask(phone1, [
                '(99) 9999-9999',
                !/^0800/.test(phone1) ? '(99) 99999-9999' : '9999 999 9999',
              ])}
            </a>
            <br />
            {phone2 && (
              <a href={`tel:${phone2}`} className="link">
                {mask(phone2, [
                  '(99) 9999-9999',
                  !/^0800/.test(phone2) ? '(99) 99999-9999' : '9999 999 9999',
                ])}
              </a>
            )}
            <br />
            ou acesse:{' '}
            <a
              href="http://www.siscom.com.br"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              www.siscom.com.br
            </a>
            , temos algo muito importante para falar com você.
          </p>
        ),
        asMessage: true,
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.mais_informacoes);
          this.sendPutRequest({
            ...steps.mais_informacoes,
            message: `Para conseguir maiores informações, ligue para: ${mask(
              phone1,
              [
                '(99) 9999-9999',
                !/^0800/.test(phone1) ? '(99) 99999-9999' : '9999 999 9999',
              ]
            )} /
            ${
              phone2
                ? mask(phone2, [
                    '(99) 9999-9999',
                    !/^0800/.test(phone2) ? '(99) 99999-9999' : '9999 999 9999',
                  ])
                : ''
            } ou acesse www.siscom.com.br, temos algo muito importante para falar com você.`,
          });

          return 'agradecimento';
        },
      },
      {
        id: 'ja_paguei',
        component: (
          <p>
            Perfeito! a confirmação do pagamente pode levar até 7 dias úteis. Em
            caso de dúvidas, ligue para:
            <br />
            <a href={`tel:${phone1}`} className="link">
              {mask(phone1, [
                '(99) 9999-9999',
                !/^0800/.test(phone1) ? '(99) 99999-9999' : '9999 999 9999',
              ])}
            </a>
            <br />
            {phone2 && (
              <a href={`tel:${phone2}`} className="link">
                {mask(phone2, [
                  '(99) 9999-9999',
                  !/^0800/.test(phone2) ? '(99) 99999-9999' : '9999 999 9999',
                ])}
              </a>
            )}
            <br />
            ou acesse:{' '}
            <a
              href="http://www.siscom.com.br"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              www.siscom.com.br
            </a>
            , temos algo muito importante para falar com você.
          </p>
        ),
        asMessage: true,
        trigger: ({ steps }) => {
          this.sendPutRequest({
            ...steps.ja_paguei,
            message: `Perfeito! a confirmação do pagamente pode levar até 7 dias úteis. Em
            caso de dúvidas, ligue para: ${mask(phone1, [
              '(99) 9999-9999',
              !/^0800/.test(phone1) ? '(99) 99999-9999' : '9999 999 9999',
            ])} /
            ${
              phone2
                ? mask(phone2, [
                    '(99) 9999-9999',
                    !/^0800/.test(phone2) ? '(99) 99999-9999' : '9999 999 9999',
                  ])
                : ''
            } ou acesse www.siscom.com.br, temos algo muito importante para falar com você.`,
          });

          return 'agradecimento';
        },
      },
      {
        id: 'desconhece_divida',
        component: (
          <p>
            Por gentileza, entre em contato com nossa central para maiores
            informações. Ligue <br />
            <a href={`tel:${phone1}`} className="link">
              {mask(phone1, [
                '(99) 9999-9999',
                !/^0800/.test(phone1) ? '(99) 99999-9999' : '9999 999 9999',
              ])}
            </a>
            <br />
            {phone2 && (
              <a href={`tel:${phone2}`} className="link">
                {mask(phone2, [
                  '(99) 9999-9999',
                  !/^0800/.test(phone2) ? '(99) 99999-9999' : '9999 999 9999',
                ])}
              </a>
            )}
            <br />
            ou acesse:{' '}
            <a
              href="http://www.siscom.com.br"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              www.siscom.com.br
            </a>
            , temos algo muito importante para falar com você.
          </p>
        ),
        asMessage: true,
        trigger: ({ steps }) => {
          this.sendPutRequest({
            ...steps.desconhece_divida,
            message: `Por gentileza, entre em contato com nossa central para maiores
            informações. Ligue: ${mask(phone1, [
              '(99) 9999-9999',
              !/^0800/.test(phone1) ? '(99) 99999-9999' : '9999 999 9999',
            ])} /
            ${
              phone2
                ? mask(phone2, [
                    '(99) 9999-9999',
                    !/^0800/.test(phone2) ? '(99) 99999-9999' : '9999 999 9999',
                  ])
                : ''
            } ou acesse www.siscom.com.br, temos algo muito importante para falar com você.`,
          });

          return 'agradecimento';
        },
      },
      {
        id: 'pagamento_opcoes_texto',
        message: 'Escolha a melhor opção:',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.pagamento_opcoes_texto);

          return 'pagamento_opcoes';
        },
      },
      {
        id: 'pagamento_opcoes',
        component: (
          <CustomOptions
            options={
              result.infoAdicional
                ? [
                    ...result.infoAdicional.map((opt) => opt.idBoleto),
                    'nenhuma',
                  ]
                : ['nenhuma']
            }
            optionsLabel={
              result.infoAdicional
                ? [
                    ...result.infoAdicional.map(
                      (opt) =>
                        `${opt.qtdeParc}x de ${currencyDisplay(
                          opt.valor.replace('.', '').replace(',', '.'),
                          2
                        )} venc. ${opt.venc}`
                    ),
                    'nenhuma',
                  ]
                : ['nenhuma']
            }
            primaryColor="#135394"
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest({
            ...steps.pagamento_opcoes,
            message: value,
          });

          if (value === 'nenhuma') return 'nenhuma';
          const installments = +result.infoAdicional.find(
            (opt) => opt.idBoleto === value
          ).qtdeParc;

          if (installments === 1) return 'pagamento_avista';
          if (installments > 1) return 'pagamento_parcelado';
        },
      },
      {
        id: 'pagamento_avista',
        message: ({ steps }) => {
          const parc = result.infoAdicional.find(
            (opt) => opt.idBoleto === steps.pagamento_opcoes.value
          );

          return `Você escolheu pagar à vista o valor de ${currencyDisplay(
            parc.valor.replace('.', '').replace(',', '.'),
            2
          )} com vencimento em ${parc.venc}`;
        },
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.pagamento_avista);

          return 'receber_email';
        },
      },
      {
        id: 'pagamento_parcelado',
        message: ({ steps, ...props }) => {
          const parc = result.infoAdicional.find(
            (opt) => opt.idBoleto === steps.pagamento_opcoes.value
          );

          return `Você escolheu pagar ${
            parc.qtdeParc
          } parcelas no valor ${currencyDisplay(
            parc.valor.replace('.', '').replace(',', '.'),
            2
          )} cada, com o primeiro vencimento em ${parc.venc}`;
        },
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.pagamento_parcelado);

          return 'receber_email';
        },
      },
      {
        id: 'aguarde',
        message: 'Aguarde. Gerando o boleto...',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.aguarde);

          return 'gerar_acordo';
        },
      },
      {
        id: 'gerar_acordo',
        component: <WaitBillet data={result} />,
        replace: true,
        waitAction: true,
        asMessage: true,
        trigger: ({ steps, value }) => {
          this.sendPutRequest({
            ...steps.gerar_acordo,
            message: value?.pdf
              ? 'Boleto gerado com sucesso'
              : 'Erro ao gerar boleto',
          });
          return 'exportar_boleto';
        },
      },

      {
        id: 'nenhuma',
        component: (
          <p>
            Certo, mas não desista! Acesse nosso site:{' '}
            <a href="http://www.siscom.com.br" className="link">
              www.siscom.com.br
            </a>
          </p>
        ),
        asMessage: true,
        trigger: ({ steps }) => {
          this.sendPutRequest({
            ...steps.nenhuma,
            message: `Certo, mas não desista! Acesse nosso site: www.siscom.com.br`,
          });

          return 'agradecimento';
        },
      },
      {
        id: 'receber_email',
        message: 'Posso te enviar o boleto por e-mail?',
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.receber_email);

          return 'receber_email_options';
        },
      },
      {
        id: 'receber_email_options',
        component: (
          <CustomOptions options={['sim', 'não']} primaryColor="#135394" />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '116', status: 'ACP1' }, () =>
            this.sendPutRequest({
              ...steps.receber_email_options,
              message: value,
            })
          );
          //this.sendPutRequest(steps.receber_email_options);
          if (value === 'sim') {
            if (result.email && this.state.userEmailIsCorrect) {
              return 'confirmar_email';
            }

            return 'perguntar_email';
          } else {
            return 'enviar_sms';
          }
        },
      },
      {
        id: 'perguntar_email',
        message: 'Qual o seu email?',
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.perguntar_email);

          return 'digitar_email';
        },
      },
      {
        id: 'digitar_email',
        user: true,
        validator: (value) => {
          if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value.trim())) {
            return 'Email inválido!';
          }
          return true;
        },
        trigger: 'confirmar_email',
      },
      {
        id: 'confirmar_email',
        message: ({ previousValue, steps }) => {
          return `O email esta correto?
${
  !this.state.userEmailIsCorrect ? previousValue : result.email || previousValue
}`;
        },
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.confirmar_email);
          return 'opcoes_confirmar_email';
        },
      },
      {
        id: 'opcoes_confirmar_email',
        component: (
          <CustomOptions options={['sim', 'não']} primaryColor="#135394" />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          if (value === 'sim') {
            this.setState({ statusCode: '116', status: 'NEMAIL' }, () =>
              this.sendPutRequest(steps.opcoes_confirmar_email)
            );

            return 'enviar_email';
          } else {
            if (result.email && this.state.userEmailIsCorrect) {
              this.setState({ userEmailIsCorrect: false });
            }
            return 'perguntar_email';
          }
        },
      },
      {
        id: 'enviar_email',
        message: ({ _, steps }) =>
          `Será enviado em até 24 horas um email para ${
            !this.state.userEmailIsCorrect
              ? steps.digitar_email?.value
              : result.email || steps.digitar_email?.value
          } com o boleto para pagamento do seu débito.`, // o numero tem que ser uma variavel recebida da api aqui ta mosdo de demonstraçao
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '116', status: 'SEMAIL' }, () =>
            this.sendPutRequest(steps.enviar_email)
          );

          if (steps.digitar_email?.value) {
            cslogApi.post('/cslog/emails', {
              idContract: result.id_contr,
              email: steps.digitar_email?.value,
            });
          }

          return 'verificar_spam';
        },
      },

      {
        id: 'verificar_spam',
        message:
          'Confira na caixa de entrada ou spam. Pague até o vencimento para não perder os descontos!',
        trigger: 'gerar_email',
      },
      {
        id: 'enviar_sms',
        message:
          'Ok. Será enviado em até 24 horas a linha digitável do seu boleto para pagamento em seu celular via SMS.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.enviar_sms);

          return 'parabenizar_acordo';
        },
      },
      {
        id: 'gerar_sms',
        component: <WaitSms data={result} />,
        replace: true,
        waitAction: true,
        asMessage: true,
        trigger: 'gerar_acordo',
      },
      {
        id: 'gerar_email',
        component: <WaitEmail data={result} />,
        replace: true,
        waitAction: true,
        asMessage: true,
        trigger: 'gerar_acordo',
      },
      {
        id: 'parabenizar_acordo',
        component: (
          <p>
            Parabéns pelo seu acordo. E caso tenha dúvidas, entre em contato no
            <br />
            <a href={`tel:${phone1}`} className="link">
              {mask(phone1, [
                '(99) 9999-9999',
                !/^0800/.test(phone1) ? '(99) 99999-9999' : '9999 999 9999',
              ])}
            </a>
            <br />
            {phone2 && (
              <>
                ou
                <br />
                <a href={`tel:${phone2}`} className="link">
                  {mask(phone2, [
                    '(99) 9999-9999',
                    !/^0800/.test(phone2) ? '(99) 99999-9999' : '9999 999 9999',
                  ])}
                </a>
              </>
            )}
          </p>
        ),
        asMessage: true,
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.parabenizar_acordo);
          this.sendPutRequest({
            ...steps.parabenizar_acordo,
            message: `Parabéns pelo seu acordo. E caso tenha dúvidas, entre em contato no ${mask(
              phone1,
              [
                '(99) 9999-9999',
                !/^0800/.test(phone1) ? '(99) 99999-9999' : '9999 999 9999',
              ]
            )}${
              phone2
                ? `ou ${mask(phone2, [
                    '(99) 9999-9999',
                    !/^0800/.test(phone2) ? '(99) 99999-9999' : '9999 999 9999',
                  ])}`
                : ''
            }`,
          });

          return 'gerar_sms';
        },
      },
      {
        id: 'exportar_boleto',
        component: <this.Export />,
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.exportar_boleto);

          return 'agradecimento';
        },
      },
      {
        id: 'agradecimento',
        message: 'A Siscom agradece a sua atenção.',
        end: true,
      },
    ];
    return (
      <>
        <Loader close={this.state.closeLoader} />
        <ThemeProvider theme={theme}>
          {!this.state.isLoading && (
            <Chatbot
              recognitionEnable={false}
              placeholder="Digite aqui"
              botDelay={1000}
              headerComponent={header}
              floating={false}
              handleEnd={this.handleEnd}
              {...this.props}
              steps={steps}
              botAvatar="/12/avatar.png"
              submitButtonStyle={{ fill: theme.botBubbleColor }}
              className="itau --no-padding-top"
            />
          )}
        </ThemeProvider>
      </>
    );
  }
}
