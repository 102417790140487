import pad from './pad';

export default function verifyCpf(value, cpf) {
  let padCpf = pad(cpf, 11, 'left')
  let verified = false

  for(let i of padCpf) {
    if (i === '0') {
      if (padCpf.slice(0, 3) === value) {
        verified = true
        break
      }

      padCpf = padCpf.substring(1)
    } else {
      if (padCpf.slice(0, 3) === value) {
        verified = true
      }

      break
    }
  }

  return verified
}