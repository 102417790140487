import React from 'react';

import { Container } from './styles';

import { FaWhatsapp } from 'react-icons/fa';
import { BiPhone } from 'react-icons/bi';
import { formatPhone } from '../../utils/formatPhone';

function InfoContatoBtn({
  whatsapp,
  phone,
  defaultWpMessage,
  theme,
  convencional,
}) {
  return (
    <Container theme={theme}>
      {whatsapp && (
        <li>
          <a
            href={`https://wa.me/${
              /^55/.test(whatsapp) || /^0800/.test(whatsapp)
                ? whatsapp
                : `55${whatsapp}`
            }${
              defaultWpMessage
                ? `?text=${encodeURIComponent(defaultWpMessage)}`
                : ''
            }`}
            target="_blank"
            rel="noreferrer noopened"
          >
            <FaWhatsapp size={25} color={theme.fontColor} />
            <span>{formatPhone(whatsapp, convencional)}</span>
          </a>
        </li>
      )}
      {phone && (
        <li>
          <a href={`tel:${phone}`} target="_blank" rel="noreferrer noopened">
            <BiPhone size={25} color={theme.fontColor} />
            <span>{formatPhone(phone, convencional)}</span>
          </a>
        </li>
      )}
    </Container>
  );
}

export default InfoContatoBtn;
