import React, { useEffect } from 'react';
import { Header, Theme } from '.';
import { alterMetaTag } from '../../../../components/chat/metaTag';
import DefaultChatbot from '../../../../components/DefaultChatbot';

function VyaDigital(props) {
  useEffect(() => {
    alterMetaTag(
      'vya.digital',
      /\/[0-9]/.exec(props.match.path),
      Theme.headerBgColor
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <DefaultChatbot headerComponent={Header} theme={Theme} className="vya"/>;
}

export default VyaDigital;
