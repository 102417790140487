export const alterMetaTag = function alterMetaTag(
  value,
  path,
  themeColor = '#343a40',
  setFavicon
) {
  //console.log(path);
  try {
    const _desc = value;
    if (setFavicon) {
      document
        .querySelector('#favicon')
        .setAttribute('href', `${path}/favicon.ico`);
    }
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute('content', themeColor);
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', _desc);
    document.querySelector('meta[name="title"]').setAttribute('content', _desc);
    document.querySelector('meta[name="title"]').setAttribute('content', _desc);
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute('content', process.env.REACT_APP_PUBLIC_URL);
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute('content', _desc);
    document
      .querySelector('meta[property="og:site_name"]')
      .setAttribute('content', _desc);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute('content', _desc);
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute(
        'content',
        process.env.REACT_APP_PUBLIC_URL + path + '/ogLogo.png'
      );
    document.title = _desc;

    document
      .querySelector('meta[property="twitter:url"]')
      .setAttribute('content', process.env.REACT_APP_PUBLIC_URL);
    document
      .querySelector('meta[property="twitter:card"]')
      .setAttribute('content', _desc);
    document
      .querySelector('meta[property="twitter:title"]')
      .setAttribute('content', _desc);
    document
      .querySelector('meta[property="twitter:description"]')
      .setAttribute('content', _desc);
    document
      .querySelector('meta[property="twitter:image"]')
      .setAttribute(
        'content',
        process.env.REACT_APP_PUBLIC_URL + path + '/ogLogo.png'
      );

    console.log(
      '%cProduzido por vya.digital',
      'font-family: Arial;font-size:16px;color:' + themeColor
    );
    console.log(
      '%cChatbot ' + value,
      'font-family: Arial;font-size:15px;color:' + themeColor
    );
  } catch (err) {
    console.log('Erro: metaTag ', err);
  }
};
