import styled, { keyframes } from 'styled-components';
import Poster from '../../../assets/poster.jpg';
const miniSlide = keyframes`
  from {
    transform: translateX(100px);
    opacity: 0;
  } to {
    transform: translateX(0px);
    opacity: 1;
  }
`

const slideTop = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  } to {
    transform: translateY(0px);
    opacity: 1;
  }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 100vh) * 100);

  transition: all .5s ease-in-out;

  background-color: #fff;
  z-index: 9999;

  &.close {
    left: -100vw;
  }

  .video-container {
    width:100%;
    height:100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;

      background: transparent url(${Poster}) 50% 50% / cover no-repeat ;
    }
    
  }

  .slider {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .slide {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      min-width: 100%;
      min-height: 100vh;
      z-index: 2;

      transition: margin-top .8s ease-in-out;

      img.siscom-logo {
        width: 200px;
        opacity: 0;
        animation: ${miniSlide} .8s 0.2s forwards;
      }

      img.itau-logo {
        width: 70px;
        margin-bottom: 2.5rem;
        opacity: 0;
        animation: ${slideTop} .5s 1.8s forwards;
      }

      span {
        font-weight: 700;
        color: #fff;
        font-size: 2rem;
        margin-bottom: 0.75rem;
        opacity: 0;
        animation: ${slideTop} .5s 2.1s forwards;
      }

      svg {
        opacity: 0;
        animation: ${slideTop} .5s 2.4s forwards;
      }

      &.blur {
        margin-top: -100vh;
      }
    }
  }
`;
