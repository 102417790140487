import { Component, createContext } from 'react';

export const ResourcesContext = createContext({
  fileUrl: '',
  digitableLine: '',
  setValues: () => {},
  show: () => {},
  hide: () => {},
  setDate: () => {},
  isEnded: () => {},
  date: '',
  sendEmail: false,
  ended: false,
});

export class ResourcesProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: null,
      digitableLine: null,
      showCalendar: false,
      date: null,
      sendEmail: false,
      ended: false,
    };
  }

  setValues = (fileUrl, digitableLine) => {
    this.setState({ fileUrl, digitableLine });
  };

  show = () => this.setState({ showCalendar: true });
  hide = () => this.setState({ showCalendar: false });

  setDate = (date) => {
    this.setState({ date });

    this.hide();
  };

  sendEmail = () => {
    this.setState({
      sendEmail: true,
    });
  };

  isEnded = () => {
    this.setState({
      ended: true,
    });
  };

  render() {
    const {
      fileUrl,
      digitableLine,
      date,
      sendEmail: email,
      ended,
    } = this.state;
    return (
      <ResourcesContext.Provider
        value={{
          fileUrl,
          digitableLine,
          setValues: this.setValues,
          show: this.show,
          hide: this.hide,
          setDate: this.setDate,
          sendEmail: this.sendEmail,
          email,
          date,
          handleEnded: this.isEnded,
          ended,
        }}
      >
        {this.props.children}
      </ResourcesContext.Provider>
    );
  }
}
