import React from 'react';
import Chatbot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { saudacao } from '../client/vya.digital/presentations/credsystem/saudacao';

import logo from '../assets/vya_logo.png';

export default function Ops({
  message,
  ad,
  theme,
  headerComponent: header,
  botAvatar,
}) {
  const AnchorLink = () => (
    <p>
      Fale conosco:{' '}
      <a
        href="mailto:chatbot@vya.digital"
        style={{ textDecoration: 'none', color: '#fff' }}
      >
        <strong>chatbot@vya.digital</strong>
      </a>
    </p>
  );

  const Logo = () => (
    <img src={logo} alt="vya.digital logo" style={{ height: '20px' }}></img>
  );
  const steps = [
    {
      id: 'initial',
      message: ad ? saudacao() : 'Oops!',
      trigger: () => (ad ? 'ad_message_1' : 'message'),
    },

    {
      id: 'message',
      message: message || 'Seus  dados não foram encontrados.',
      end: true,
    },
    {
      id: 'ad_message_1',
      message: 'Endereço não localizado.',
      trigger: 'ad_message_2',
    },
    {
      id: 'ad_message_2',
      component: <Logo />,
      asMessage: true,
      trigger: 'ad_message_3',
    },
    {
      id: 'ad_message_3',
      message: 'Soluções de chatbot personalizado para sua empresa',
      trigger: 'ad_message_4',
    },
    {
      id: 'ad_message_4',
      component: <AnchorLink />,
      asMessage: true,
      end: true,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Chatbot
        steps={steps}
        botDelay={500}
        headerComponent={header}
        style={{ fontWeight: 400 }}
        hideSubmitButton={true}
        botAvatar={botAvatar}
        placeholder="Digite a mensagem..."
      />
    </ThemeProvider>
  );
}
