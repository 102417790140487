import { theme } from './theme';

export const header = (
  <a
    href="https://www.stoccoadvocacia.com.br/"
    target="_blank"
    rel="noreferrer noopened"
  >
    <div
      className="sc-iqHYGH ieNPiA rsc-header"
      style={{
        background: theme.headerBgColor,
        height: '53px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <img
        src="/13/logo.webp"
        alt="Stocco Advocacia"
        style={{
          width: '130px',

          paddingLeft: '18px',
          //paddingTop: '4px',
        }}
      />
    </div>
  </a>
);
