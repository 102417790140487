import { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import { header } from './Header';
import { theme } from './theme';
import ChatBot from 'react-simple-chatbot';
import ImagePicker from '../../../components/ImagePicker';
import CustomOptions from '../../../components/CustomOptions';

export default class Imasf extends Component {
  state = {
    userInfo: {},
  };

  render() {
    const linkInNewTab = {
      rel: 'noopened noreferrer',
      target: '_blank',
    };
    const steps = [
      {
        id: 'inicio',
        message: 'Olá, Bem-Vindo ao atendimento online do IMASF.',
        trigger: 'menu_inicial',
      },
      {
        id: 'menu_inicial',
        message: 'Escolha uma opção abaixo:',
        trigger: 'digitar_opcao_geral',
      },
      {
        id: 'digitar_opcao_geral',
        component: (
          <CustomOptions
            options={[
              'administração',
              'seção médica',
              'reembolso',
              'coparticipação',
              'tesouraria',
              'sugestões e reclamações',
            ]}
          />
        ),
        waitAction: true,
        trigger: ({ value }) => {
          const options = {
            administração: 'administracao',
            'seção médica': 'secao_medica',
            reembolso: 'reembolso',
            coparticipação: 'coparticipacao',
            // 5: 'tesouraria',
            // 6: 'feedback',
          };
          if (options[value]) {
            return options[value];
          } else {
            return 'registrado';
          }

          //return options[+value]
        },
      },
      //s-adm
      {
        id: 'administracao',
        message: 'Escolha uma opção abaixo:',
        trigger: 'digitar_opcao_admnistracao',
      },
      {
        id: 'digitar_opcao_admnistracao',
        component: (
          <CustomOptions
            options={[
              'alteração de plano',
              '2ª via de cartão de identificação',
              'solicitação de informe de rendimentos',
              'solicitação de certidões',
              'horários de atendimento e telefones',
              'voltar ao menu inicial',
            ]}
          />
        ),
        waitAction: true,
        trigger: ({ value }) => {
          const options = {
            'alteração de plano': 'aterar_plano',
            '2ª via de cartão de identificação.': 'segunda_via_cartao',
            // 3: 'informe_redimentos',
            // 4: 'certidoes',
            // 5: 'horarios_atendimento',
            'voltar ao menu inicial': 'menu_inicial',
          };
          if (options[value]) {
            return options[value];
          } else {
            return 'registrado';
          }

          //return options[+value]
        },
      },
      {
        id: 'aterar_plano',
        component: (
          <p>
            Para inscrição, imprima o requerimento:
            <br />
            <br />
            <a
              className="rsc-link"
              href="https://www.saobernardo.sp.gov.br/documents/997585/1192518/REQUERIMENTO+ADMINISTRATIVO/527b32f3-58da-f22f-06a3-e29c4f7d51dd"
            >
              Clique aqui para baixar o requerimento
            </a>
            <br />
            <br />
            Preencha e envie a foto do Requerimento preenchido, incluindo fotos
            dos seguintes documentos:
            <br />
            <br />
            - Termo de Posse
            <br />
            - RG
            <br />
            - Certidão de Casamento ou de Nascimento (se for solteiro)
            <br />
            - Termo de Adesão ao plano escolhido para <br />
            <br />
            Após envio dos documentos, você receberá um retorno do Setor de
            Administração do IMASF em até 3(três) dias úteis.
          </p>
        ),
        asMessage: true,
        trigger: 'fallback_encerrar',
      },
      //e-adm
      //s-secao_medica
      {
        id: 'secao_medica',
        message: 'Escolha uma opção abaixo:',
        trigger: 'digitar_opcao_secao_medica',
      },
      {
        id: 'digitar_opcao_secao_medica',
        component: (
          <CustomOptions
            options={[
              'pedidos de autorização',
              'exames e procedimentos que dispensam autorização',
              'envio de relatório médico pendente',
              'rede credenciada',
              'telefones e horários de atendimento',
              'voltar ao menu inicial',
            ]}
          />
        ),
        waitAction: true,
        trigger: ({ value }) => {
          const options = {
            'pedidos de autorização': 'autorizacao',
            // 2: 'exame_sem_autorizacao',
            // 3: 'relatorio_medico_pendente',
            // 4: 'rede_credenciada',
            //5: 'horarios_atendimento',
            'voltar ao menu inicial': 'menu_inicial',
          };
          if (options[value]) {
            return options[value];
          } else {
            return 'registrado';
          }
        },
      },
      {
        id: 'autorizacao',
        component: (
          <p>
            Atente-se as observações abaixo antes de prosseguir:
            <br />
            <br />
            - Os pedidos de autorização médica, devem ser enviados com
            antecedência mínima de 3 (três) dias úteis.
            <br />
            - Agende seu procedimento médico ou exame somente após a liberação
            da Guia de Autorização.
            <br />
            - Relatório Médico Complementar, Exames, etc., poderão ser
            solicitados, pela auditoria médica do IMASF quando necessário.
            <br />- Só serão aceitos pedidos médicos emitidos há, no máximo, 30
            (trinta) dias, no Estado de São Paulo.
          </p>
        ),
        asMessage: true,
        trigger: 'usuario_beneficente',
      },
      {
        id: 'usuario_beneficente',
        message: 'Digite o nome do beneficiário solicitante:',
        trigger: 'digitar_usuario_beneficente',
      },
      {
        id: 'digitar_usuario_beneficente',
        user: true,
        trigger: ({ value }) => {
          this.setState({ userInfo: { ...this.state.userInfo, name: value } });

          return 'numero_inscricao';
        },
      },
      {
        id: 'numero_inscricao',
        message:
          'Agora informe o número de inscrição do beneficiário.',
        trigger: 'digitar_numero_inscricao',
      },
      {
        id: 'digitar_numero_inscricao',
        user: true,
        trigger: ({ value }) => {
          this.setState({
            userInfo: { ...this.state.userInfo, number: value },
          });

          return 'email';
        },
      },
      {
        id: 'email',
        message: 'Email para contato:',
        trigger: 'digitar_email',
      },
      {
        id: 'digitar_email',
        user: true,
        validator: (value) => {
          if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value.trim())) {
            return 'Email inválido!';
          }

          return true;
        },
        trigger: ({ value }) => {
          this.setState({ userInfo: { ...this.state.userInfo, email: value } });

          return 'verificar_info';
        },
      },
      {
        id: 'verificar_info',
        message: ({ previousValue }) => {
          console.log('[user-info]', this.state.userInfo);

          return `Confirme as informações:\n\nNome do beneficiário:\n${this.state.userInfo.name}\n\nNúmero do beneficiário:\n${this.state.userInfo.number}\n\nEmail para contato:\n${previousValue}`;
        },
        trigger: 'verificar_info_opcoes',
      },
      {
        id: 'verificar_info_opcoes',
        options: [
          {
            value: 'correto',
            label: 'Tudo certo!',
            trigger: 'foto_pedido_medico',
          },
          {
            value: 'incorreto',
            label: 'Errei algo...',
            trigger: 'usuario_beneficente',
          },
        ],
      },
      {
        id: 'foto_pedido_medico',
        component: (
          <p>
            Agora envie uma foto do Pedido Médico
            <br />
            <br />
            Obs: O pedido não pode conter rasuras ou alterações, deve conter o
            nº do CID, hipótese diagnóstica, breve relato do procedimento
            requisitado, data da solicitação, carimbo e assinatura do médico.
          </p>
        ),
        asMessage: true,
        trigger: () => {
          console.log('[user-info]', this.state.userInfo);
          return 'solicitar_foto_messagem';
        },
      },
      {
        id: 'solicitar_foto_messagem',
        message: 'Envie o pedido médico:',
        trigger: 'solicitar_foto',
      },
      {
        id: 'solicitar_foto',
        component: <ImagePicker />,
        waitAction: true,
        trigger: ({ value }) => {
          this.setState({
            userInfo: { ...this.state.userInfo, photoMedicalRequest: value },
          });

          return 'agradece_info';
        },
      },
      {
        id: 'agradece_info',
        message:
          'O IMASF agradece o envio de suas informações. Em até 3 (três) dias úteis você receberá a resposta de sua autorização por e-mail.',
        trigger: 'fallback_encerrar',
      },
      //e-secao_medica
      //s-reembolso
      {
        id: 'reembolso',
        component: (
          <p>
            Atente-se as observações abaixo antes de prosseguir:
            <br />
            <br />
            Serão reembolsados os procedimentos cobertos, comprovadamente pagos
            pelo segurado titular ou seus dependentes através da apresentação do
            recebido original ao IMASF. De acordo com a Resolução nº 729, de 27
            de setembro de 2017, só serão aceitos pedidos de Reembolso, se o
            beneficiário entregar os seguintes documentos:
            <br />
            <br />
            Requerimento, a ser preenchido
            <br />
            Carteirinha do segurado (titular);
            <br />
            <br />
            Após imprimir e preencher, envie a foto do requerimento e da sua
            documentação para:
            <br />
            <a
              href="mailto:reembolso@imasf.com.br"
              className="rsc-link"
              {...linkInNewTab}
            >
              reembolso@imasf.com.br
            </a>
            <br />
            <br />
            Para ter acesso à todas as informações sobre reembolso acesse nosso
            site:
            <br />
            <a
              href="https://www.saobernardo.sp.gov.br/web/imasf/reembolso1"
              className="rsc-link"
            >
              https://www.saobernardo.sp.gov.br/web/imasf/reembolso1
            </a>
          </p>
        ),
        asMessage: true,
        trigger: 'fallback_encerrar',
      },
      //e-reembolso
      //s-coparticipacao
      {
        id: 'coparticipacao',
        message: 'Escolha uma opção abaixo:',
        trigger: 'digitar_opcao_coparticipacao',
      },
      {
        id: 'digitar_opcao_coparticipacao',
        component: (
          <CustomOptions
            options={[
              'consulta extrato coparticipação (primeiro acesso)',
              'consulta extrato coparticipação',
              'tabelas de coparticipação',
            ]}
          />
        ),
        waitAction: true,
        trigger: ({ value }) => {
          const options = {
            'consulta extrato coparticipação (primeiro acesso)':
              'consulta_ec_primeiro',
            'consulta extrato coparticipação': 'consulta_ec',
            'tabelas de coparticipação': 'tabelas_coparticipacao',
          };

          return options[value];
        },
      },
      {
        id: 'consulta_ec_primeiro',
        component: (
          <p>
            No caso de primeiro acesso, faça o cadastro clicando em “Primeiro
            Acesso?”
            <br />
            Em seguida, preencha os campos com o CPF do Beneficiário em questão
            e data de nascimento.
            <br />
            <br />
            Link:{' '}
            <a
              href="http://portal.imasf.com.br/portalimasf/"
              className="rsc-link"
              {...linkInNewTab}
            >
              http://portal.imasf.com.br/portalimasf/
            </a>
          </p>
        ),
        asMessage: true,
        trigger: 'fallback_encerrar',
      },
      {
        id: 'consulta_ec',
        component: (
          <p>
            Para consultar seu Extrato de coparticipação acesse:
            <br />
            <br />
            <a
              href="http://portal.imasf.com.br/portalimasf/"
              className="rsc-link"
              {...linkInNewTab}
            >
              http://portal.imasf.com.br/portalimasf/
            </a>
          </p>
        ),
        asMessage: true,
        trigger: 'fallback_encerrar',
      },
      {
        id: 'tabelas_coparticipacao',
        component: (
          <p>
            Para consultar as Tabelas de coparticipação acesse o link:
            <br />
            <br />
            <a
              href="https://www.saobernardo.sp.gov.br/web/imasf/tabelacoparticipacao"
              className="rsc-link"
              {...linkInNewTab}
            >
              https://www.saobernardo.sp.gov.br/web/imasf/tabelacoparticipacao
            </a>
          </p>
        ),
        asMessage: true,
        trigger: 'fallback_encerrar',
      },
      {
        id: 'fallback_encerrar',
        component: (
          <CustomOptions
            options={['voltar ao menu inicial', 'encerrar atendimento']}
          />
        ),
        waitAction: true,
        trigger: ({ value }) => {
          if (value === 'voltar ao menu inicial') {
            return 'menu_inicial';
          } else {
            return 'agradecimento';
          }
        },
      },
      {
        id: 'registrado',
        message: 'Ok, registrado.',
        trigger: 'agradecimento',
      },
      {
        id: 'agradecimento',
        message: 'O IMASF agradece seu contato.',
        end: true,
      },
    ];
    return (
      <ThemeProvider theme={theme}>
        <ChatBot
          headerComponent={header}
          botAvatar="/8/VoiceBot.png"
          placeholder="Digite aqui..."
          /* headerTitle="SKY" */
          floating={false}
          steps={steps}
          delay={500}
          // Estilo do Rodapé (Footer) //
          /* footerStyle={{ backgroundColor: '#fff', margin: 5, padding: 1, borderRadius: 7, elevation: 2, }}*/
          submitButtonStyle={{ fill: theme.botBubbleColor }}
          className="imasf"
        />
      </ThemeProvider>
    );
  }
}
