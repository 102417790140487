import moment from 'moment'

export const getPassedMinutes = (date) => {
    return moment().locale('pt-BR').diff(date, 'minutes');
}

export const getPassedDays = (date) => {
    date = moment(date)
    const now = moment().locale('pt-BR')
    return now.diff(date, 'days')
}

export const FORMAT = {
    DAY: 'dd',
    DATE_DASH: 'YYYY-MM-DD',
    DATE: 'dd/MM/yy',
    DATE_FULL_YEAR: 'DD/MM/YYYY',
    DATE_TIME: "dd/MM/yy 'às' HH:mm",
    DATE_FULL_YEAR_TIME: "dd/MM/yyyy 'às' HH:mm",
    TIME: 'HH:mm',
    MONTH_NAME: 'MMM'
}

export const formatDate = (dateOps, dateFormatInput, dateFormatOutput) => {
    const data = moment(dateOps, dateFormatInput);
    return String(data.format(dateFormatOutput));
}

export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}


