import React, { useContext, useEffect, useRef } from 'react';
import { Loading } from 'react-simple-chatbot';
import { ResourcesContext } from '../../context/ResourcesContext';

function WaitResponse(props) {
  const { digitableLine } = useContext(ResourcesContext);
  const timeout = useRef(null);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      props.triggerNextStep();
    }, 15000);

    return () => clearTimeout(timeout.current);
  });

  useEffect(() => {
    if (digitableLine) {
      props.triggerNextStep();
    }
  }, [digitableLine]);

  return <Loading />;
}

export default WaitResponse;
