import { addDays, getDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useContext, useState } from 'react';
import { DatePickerCalendar } from 'react-nice-dates';
import { ResourcesContext } from '../../context/ResourcesContext';
import addUtilDays from '../../utils/addUtilDays';

import './styles.css';

const modifiers = {
  disabled: (date) => getDay(date) === 6 || getDay(date) === 0, // Disables Saturdays
};

function DatePI(props) {
  const [trigger, setTrigger] = useState(false);
  const context = useContext(ResourcesContext);
  const minimum = props.minimum || addUtilDays(new Date(), 1, true);
  const maximum = props.maximum || addUtilDays(minimum, 5, true);

  const setDate = (date) => {
    setTrigger(true);

    props.step.value = date;
    context.setDate(date);

    props.triggerNextStep();
  };

  return (
    <div
      className="date-container"
      style={{
        pointerEvents: trigger ? 'none' : 'all',
      }}
    >
      <DatePickerCalendar
        locale={ptBR}
        date={context.date}
        onDateChange={setDate}
        maximumDate={maximum}
        minimumDate={minimum}
        modifiers={modifiers}
        weekdayFormat="eeeee"
      ></DatePickerCalendar>
    </div>
  );
}

export default DatePI;
