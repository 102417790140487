import axios from 'axios';
import { format, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import Chatbot, { Loading } from 'react-simple-chatbot';
import { billetApi } from '../../../services/api';
import { currencyDisplay } from '../../../utils/currency';
import { flexFormat } from '../../../utils/flexFormat';

// import { Container } from './styles';

function WaitBillet({ data, triggerNextStep, step, steps }) {
  const handleProcessBillet = async () => {
    const serializeProduct = data.produtos ? data.produtos.split('|').map(p => p.trim()) : []
    const contratos = data.contratos.split(',').map(contrato => `DESCOBERT C/C:${contrato.trim()}`)
    const nossoNumero = [data.bol_nossonumero.slice(0, -1), data.bol_nossonumero.slice(-1)]
    const docNumber = `0000${nossoNumero[0].slice(-6)}`

    const response = await billetApi.post(
      '/billet/pdf/generate',
      {
        bank: 'bradesco',
        sendEmail: {
          // payerEmail: 'maxwellmacedo2015@gmail.com',
          // sender: 'System Interact',
          // template: 'bradesco'
        },
        payer: {
          name: data.nome_cliente,
          cpf: data.CPFCNPJ,
          address: data.end_logradouro,
          neighborhood: data.end_bairro || data.municipio || data.end_cidade,
          city: data.municipio || data.end_cidade,
          stateUf: data.uf || data.end_uf,
          cep: data.end_cep,
        },
        recipient: {
          name: 'Banco Bradesco SA',
          cnpj: '60746948000112',
          bankWallet: '6',
          agency: '4120',
          agencyDigit: '3',
          account: '0000102',
          accountDigit: '3',
          nossoNumero: nossoNumero[0],
          nossoNumeroDigit: nossoNumero[1],
          address: 'NUC CIDADE DE DEUS',
          neighborhood: 'VILA YARA',
          city: 'OSASCO',
          stateUf: 'SP',
          cep: '06029900',
        },
        billet: {
          //barCode: '237941200664296.03372913000010200986480000000000',
          systemInteract: {
            qtdParcelas: data.quantidade_parcelas,
            produtos: serializeProduct,
            contratos
          },
          docNumber: docNumber,
          docSpecie: 'OUTROS',
          value: +data.valor_total,
          instructions: [
            'PAGÁVEL EM QUALQUER AGÊNCIA BANCÁRIA ATÉ O VENCIMENTO',
            'SYSTEM INTERACT – 08007275120 ou 11969147384 (WhatsApp)',
            'NÃO RECEBER VALOR DIFERENTE DE:',
            `Valor a pagar: ${currencyDisplay(data.valor_total, 2)}`,
            `Referente a negociação do plano. ( ) Em ${data.quantidade_parcelas} parcela(s) de ${currencyDisplay(data.valor_total, 2)}`
          ],
          dates: {
            due: flexFormat(data.vencimento, 'YMD'),
          },
        },
      }
    );
    console.log(response)

    step.value = response.data
    triggerNextStep();
  };

  useEffect(() => handleProcessBillet());

  return <Loading />;
}

export default WaitBillet;