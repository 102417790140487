class DeParaPhoneService {
  static getPhoneByDDD(DDD) {
    const dddPhoneList = {
      '11': '11988222047',
      fallback: '11988222047',
    };
    const phoneNumber = dddPhoneList[DDD];

    if (!phoneNumber) {
      return dddPhoneList['fallback'];
    }

    return phoneNumber;
  }
}

export default DeParaPhoneService;
