import Axios from 'axios';
import { isBefore, parseISO, subDays } from 'date-fns';
import pad from '../../utils/pad';
import { parseXml, xml2json } from '../../utils/toolXmlJson';

// const url =
//   'https://cors-anywhere.herokuapp.com/https://www.portalcredsystem.com.br/con_ppwcrs-war/CyberService?wsdl';
//const url =
//'https://cors-proxy.htmldriven.com/?url=https://www.portalcredsystem.com.br/con_ppwcrs-war/CyberService?wsdl';
// const url =
//  'https://www.portalcredsystem.com.br/con_ppwcrs-war/CyberService?wsdl';
// const url =
//   'https://thingproxy.freeboard.io/fetch/https://www.portalcredsystem.com.br/con_ppwcrs-war/CyberService?wsdl';

const url =
  'https://anywhere-vya-digital.herokuapp.com/https://www.portalcredsystem.com.br/con_ppwcrs-war/CyberService?wsdl';

//constantes
const credor = '1';
const parcelamento = 'N';

//Até divida de 90 dias
let empresa_90 = 'SERCOM';
let uid_90 = 'wsserc';
let pwd_90 = 'qwert123';

let empresa_1800 = 'SERCOM2';
let uid_1800 = 'wsserc2';
let pwd_1800 = 'qwert123';

function getCredentials(vencimento) {
  const dateToCompare = subDays(new Date(), 90);
  const before = isBefore(parseISO(vencimento), dateToCompare);

  return {
    empresa: before ? 'SERCOM2' : 'SERCOM',
    uid: before ? 'wsserc2' : 'wsserc',
    pwd: 'qwert123',
  };
}

export async function consultarDivida(regis, contrato, produto, vencimento) {
  const { empresa, uid, pwd } = getCredentials(vencimento);

  var data =
    '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://service.ppware.com.br/">';
  data += '<soapenv:Header/>';
  data += '<soapenv:Body>';
  data += '<ser:ConsultarDivida>';
  data += '<consulta>';
  data += '<contrato>' + contrato + '</contrato>';
  data += '<credor>' + credor + '</credor>';
  data += '<parcelamento>' + parcelamento + '</parcelamento>';
  if (produto) {
    data += '<produto>' + produto + '</produto>';
  }
  data += '<regis>' + regis + '</regis>';
  data += '<vencimento>' + vencimento + '</vencimento>';
  data += '</consulta>';
  data += '<login>';
  data += '<empresa>' + empresa + '</empresa>';
  data += '<pwd>' + pwd + '</pwd>';
  data += '<uid>' + uid + '</uid>';
  data += '</login>';
  data += '</ser:ConsultarDivida>';
  data += '</soapenv:Body>';
  data += '</soapenv:Envelope>';

  var axios = require('axios');
  var config = {
    method: 'post',
    url: url,
    timeout: 0,
    headers: {
      'Content-Type': 'text/xml',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    data: data,
  };

  let quantidadeDivida = 0;
  let valorTotal = 0;
  let retornoTexto = '';

  let response = {};
  try {
    response = await axios(config);
  } catch (error) {
    let retorno = {
      quantidadeDivida: 0,
      valorTotal: 0,
      retornoTexto: error.response?.status + ' - ' + error.response?.statusText,
    };
    return retorno;
  }

  let result = response.data.replaceAll('S:', '', response.data);
  result = result.replaceAll('ns0:', '', result);
  result = result.replaceAll('xmlns:', '', result);
  let dom = parseXml(result);
  let json = xml2json(dom, '');
  console.log('[ConsultarDivida-json]', json);

  if (json.Envelope.Body.ConsultarDividaResponse.return.retorno === '00') {
    const prestacoes =
      json.Envelope.Body.ConsultarDividaResponse.return.divida.prest;
    if (
      Array.isArray(
        json.Envelope.Body.ConsultarDividaResponse.return.divida.prest
      )
    ) {
      json.Envelope.Body.ConsultarDividaResponse.return.divida.prest.map(
        function(item, idx) {
          //console.log('[ConsultarDivida]', item.vencimento);
          quantidadeDivida++;
          valorTotal += parseFloat(item.valprinc);
        }
      );
    } else {
      //console.log('[ConsultarDivida-prestacoes]', prestacoes);
      quantidadeDivida++;
      valorTotal += parseFloat(prestacoes.valprinc);
    }
  } else {
    console.log(
      '[ConsultarDivida]',
      json.Envelope.Body.ConsultarDividaResponse.return.retornotexto
    );
    retornoTexto =
      json.Envelope.Body.ConsultarDividaResponse.return.retornotexto;
  }

  let retorno = {
    quantidadeDivida: quantidadeDivida,
    valorTotal: valorTotal,
    retornoTexto: retornoTexto,
  };
  return retorno;
}

export async function calcular(
  regis,
  contrato,
  produto,
  vencimento,
  dataAcordo
) {
  const { empresa, uid, pwd } = getCredentials(vencimento);

  var data = '';
  data +=
    '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://service.ppware.com.br/">';
  data += '<soapenv:Header/>';
  data += '<soapenv:Body>';
  data += '<ser:Calcular>';
  data += '<calculo>';
  data += '<credor>' + credor + '</credor>';
  data += '<descdespesa>N</descdespesa>';
  data += '<deschonorarios>N</deschonorarios>';
  data += '<dtpagto>' + dataAcordo + '</dtpagto>';
  data += '<pctdesconto>0</pctdesconto>';
  data += '<regis>' + regis + '</regis>';
  data += '<valdesconto>0</valdesconto>';
  data += '<valoradicional>0</valoradicional>';
  data += '</calculo>';

  if (Array.isArray(contrato)) {
    //todo for each
  } else {
    data += '<prest>';
    data += '<contr>' + contrato + '</contr>';
    data += '<produt>' + produto + '</produt>';
    data += '<vencto>' + vencimento + '</vencto>';
    data += '</prest>';
  }
  data += '<login>';
  data += '<empresa>' + empresa + '</empresa>';
  data += '<pwd>' + pwd + '</pwd>';
  data += '<uid>' + uid + '</uid>';
  data += '</login>';
  data += '</ser:Calcular>';
  data += '</soapenv:Body>';
  data += '</soapenv:Envelope>';

  //console.log('[calcular-xml]', data)

  var axios = require('axios');
  var config = {
    method: 'post',
    url: url,
    timeout: 0,
    headers: {
      'Content-Type': 'text/xml',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    data: data,
  };

  const response = await axios(config);
  //console.log('[Calcular-response]', JSON.stringify(response.data));

  let result = response.data.replaceAll('S:', '', response.data);
  result = result.replaceAll('ns0:', '', result);
  result = result.replaceAll('xmlns:', '', result);
  let dom = parseXml(result);
  let json = xml2json(dom, '');
  console.log('[Calcular-json]', json);

  result = json.Envelope.Body.CalcularResponse;
  let obj = {
    valorAtual: 0,
    valorPrincipal: 0,
    valorEncargos: 0,
  };
  if (result.return.retorno.codigo === '00') {
    obj.valorAtual = result.return.retorno.valortotal;
    obj.valorPrincipal = result.return.retorno.principal;
    obj.valorEncargos = result.return.retorno.encargos;
  } else {
    console.log(
      '[calcular]',
      result.return.retorno.codigo + ' - ' + result.return.retorno.mensagem
    );
  }

  return obj;
}

export async function emitirBoleto(
  regis,
  contrato,
  produto,
  vencimento,
  dataAcordo,
  valor,
  tipoAcordo,
  acordoAtivo,
  valorDesconto,
  valorEntradaSemTaxaAdm,
  quantidadeParcelas
) {
  const { empresa, uid, pwd } = getCredentials(vencimento);

  let obj = {
    linhadigitavel: undefined,
    valorBoleto: 0,
    dataAcordo: dataAcordo,
  };

  /* obj.linhaDigitavel = '2334544645646566654'
    obj.valorBoleto = '43.67'
    obj.dataAcordo = '2021-01-03'
    return obj */

  if (acordoAtivo === 0) {
    acordoAtivo = await confirmarParcelamento(
      regis,
      contrato,
      produto,
      vencimento,
      dataAcordo,
      valor,
      tipoAcordo,
      valorDesconto,
      valorEntradaSemTaxaAdm,
      quantidadeParcelas
    );
  }

  if (acordoAtivo || acordoAtivo === 1) {
    let data = '';
    data +=
      '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://service.ppware.com.br/">';
    data += '<soapenv:Header/>';
    data += '<soapenv:Body>';
    data += '<ser:EmitirBoleto>';
    data += '<calculo>';
    data += '<credor>' + credor + '</credor>';
    data += '<descdespesa>N</descdespesa>';
    data += '<deschonorarios>N</deschonorarios>';
    data += '<dtpagto>' + dataAcordo + '</dtpagto>';
    data += '<pctdesconto>0</pctdesconto>';
    data += '<regis>' + regis + '</regis>';
    data += '<valdesconto>' + valorDesconto + '</valdesconto>';
    data += '<valoradicional>0</valoradicional>';
    data += '</calculo>';
    if (Array.isArray(contrato)) {
      //todo for each
    } else {
      data += '<prest>';
      data += '<contr>' + contrato + '</contr>';
      data += '<produt>' + produto + '</produt>';
      data += '<vencto>' + vencimento + '</vencto>';
      data += '</prest>';
    }
    data += '<login>';
    data += '<empresa>' + empresa + '</empresa>';
    data += '<pwd>' + pwd + '</pwd>';
    data += '<uid>' + uid + '</uid>';
    data += '</login>';
    data += '</ser:EmitirBoleto>';
    data += '</soapenv:Body>';
    data += '</soapenv:Envelope>';

    var axios = require('axios');
    var config = {
      method: 'post',
      url: url,
      timeout: 0,
      headers: {
        'Content-Type': 'text/xml',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },

      data: data,
    };

    //console.log('[emitirBoleto-xml]', data);
    const response = await axios(config);
    //console.log('[EmitirBoleto-response]', JSON.stringify(response.data));

    let result = response.data.replaceAll('S:', '', response.data);
    result = result.replaceAll('ns0:', '', result);
    result = result.replaceAll('xmlns:', '', result);
    let dom = parseXml(result);
    let json = xml2json(dom, '');
    console.log('[EmitirBoleto-json]', json);

    result = json.Envelope.Body.EmitirBoletoResponse;

    if (result.return.retorno === '00') {
      obj.linhaDigitavel = result.return.dadosboleto.linhadigitavel;
      obj.valorBoleto = result.return.dadosboleto.valbolet;
      obj.dataAcordo = result.return.dadosboleto.datvenci;

      const boleto = result.return.dadosboleto;

      const instructions = Object.keys(boleto)
        .filter((key) => /^desinstr([1-5]{1})/.test(key) && boleto[key])
        .map((inst) => boleto[inst]);

      const nossonumero = boleto.nossonumero;
      const nossoNumeroIntegra = nossonumero.substr(0, nossonumero.length - 1);
      const nossoNumeroDigit = nossonumero[nossonumero.length - 1];
      const nossoNumeroPad = pad(nossoNumeroIntegra, 12, 'left');

      const response = await Axios.post(
        'https://api012.vya.digital/billet/pdf/generate',
        {
          bank: boleto.codbanco,
          sendEmail: {
            //   payerEmail: 'maxwellmacedo2015@gmail.com',
            //   logo:
            //     'https://assets.blu365.com.br/uploads/2019/10/logo-credsystem.png',
            //   sender: 'Credsystem',
          },
          payer: {
            name: boleto.nomclien,
            cpf: boleto.descpf,
            address: boleto.desender,
            neighborhood: boleto.desbairr,
            city: boleto.descidad,
            stateUf: boleto.desestad,
            cep: boleto.descep,
          },
          recipient: {
            name: boleto.descodceden,
            cnpj: '04670195000138',
            bankWallet: boleto.descartebanco,
            agency: boleto.desagenc,
            agencyDigit: boleto.desagencdv,
            account: boleto.desconta,
            accountDigit: boleto.desagencdv,
            nossoNumero: nossoNumeroPad,
            nossoNumeroDigit,
            address: 'AL RIO NEGRO',
            neighborhood: 'ALPHAVILLE',
            city: 'BARUERI',
            stateUf: 'SP',
            cep: '06454000',
          },
          billet: {
            barCode: boleto.codigobarra,
            docNumber: boleto.desnumdoc,
            docSpecie: boleto.desespecdoc,
            value: boleto.valbolet,
            instructions: instructions,
            dates: {
              due: boleto.datvenci,
            },
          },
        }
      );

      obj.pdf = response.data.openFile;

      return obj;
    } else {
      console.log(
        '[EmitirBoletoResponse]',
        result.return.retorno + ' - ' + result.return.retornotexto
      );
      return obj;
    }
  } else {
    return obj;
  }
}

export async function confirmarParcelamento(
  regis,
  contrato,
  produto,
  vencimento,
  dataAcordo,
  valor,
  tipoAcordo,
  valorDesconto,
  valorEntradaSemTaxaAdm,
  quantidadeParcelas
) {
  const { empresa, uid, pwd } = getCredentials(vencimento);

  let data = '';
  data +=
    '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://service.ppware.com.br/">';
  data += '<soapenv:Header/>';
  data += '<soapenv:Body>';
  data += '<ser:ConfirmarParcelamento>';
  data += '<calculo>';
  data += '<credor>' + credor + '</credor>';
  data += '<descdespesa>N</descdespesa>';
  data += '<deschonorarios>N</deschonorarios>';
  data += '<dtpagto>' + dataAcordo + '</dtpagto>';
  data += '<pctdesconto>0</pctdesconto>';
  data += '<regis>' + regis + '</regis>';
  data += '<valdesconto>' + valorDesconto + '</valdesconto>';
  data += '<valoradicional>0</valoradicional>';
  data += '</calculo>';

  if (Array.isArray(contrato)) {
    //todo for each
  } else {
    data += '<prest>';
    data += '<contr>' + contrato + '</contr>';
    data += '<produt>' + produto + '</produt>';
    data += '<vencto>' + vencimento + '</vencto>';
    data += '</prest>';
  }

  data += '<login>';
  data += '<empresa>' + empresa + '</empresa>';
  data += '<pwd>' + pwd + '</pwd>';
  data += '<uid>' + uid + '</uid>';
  data += '</login>';
  data += '<calculoparc>';
  data += '<datasmensais>S</datasmensais>';
  data += '<desconto>' + valorDesconto + '</desconto>';
  data += '<descpercentual>N</descpercentual>';
  data += '<dtentrada>' + dataAcordo + '</dtentrada>';
  data += '<dtpagamentodi />';
  data += '<formapagtoentrada>B</formapagtoentrada>';
  data += '<parcelasiguais>S</parcelasiguais>';
  data += '<propostaacordo>' + tipoAcordo + '</propostaacordo>';
  data += '<qtdparc>' + (quantidadeParcelas - 1) + '</qtdparc>';
  data += '<renegociacao />';
  data += '<valentrada>' + valorEntradaSemTaxaAdm + '</valentrada>';

  data += '<valoradicional>0</valoradicional>';
  data += '<valorverificar />';
  data += '</calculoparc>';
  data += '</ser:ConfirmarParcelamento>';
  data += '</soapenv:Body>';
  data += '</soapenv:Envelope>';

  var axios = require('axios');
  var config = {
    method: 'post',
    url: url,
    timeout: 0,
    headers: {
      'Content-Type': 'text/xml',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    data: data,
  };

  //console.log('[confirmarParcelamento-xml]', data);
  const response = await axios(config);
  //console.log('[ConfirmarParcelamento-response]', JSON.stringify(response.data));

  let result = response.data.replaceAll('S:', '', response.data);
  result = result.replaceAll('ns0:', '', result);
  result = result.replaceAll('xmlns:', '', result);
  let dom = parseXml(result);
  let json = xml2json(dom, '');
  console.log('[ConfirmarParcelamento-json]', json);

  result = json.Envelope.Body.ConfirmarParcelamentoResponse;
  if (result.return.retorno.codigo === '00') {
    return true;
  } else {
    console.log(
      '[confirmarParcelamentoResponse]',
      result.return.retorno.codigo + ' - ' + result.return.retorno.mensagem
    );
    return false;
  }
}

export async function calcularParcelamento(
  regis,
  contrato,
  produto,
  vencimento,
  dataAcordo,
  valor,
  tipoAcordo,
  valorEncargos,
  valorPrincipal,
  qtdParcelas,
  maxDiscountValorPrincipal
) {
  //----------------------------------------------
  const { empresa, uid, pwd } = getCredentials(vencimento);
  //Desconto Fixado por regra de negocio
  let desconto = 95.0;
  console.log('[maxDiscountValorPrincipal]', maxDiscountValorPrincipal);

  // if (typeof maxDiscountValorPrincipal === undefined)
  //   maxDiscountValorPrincipal = desconto;
  // desconto = +maxDiscountValorPrincipal;

  for (let i = 0; i < 9; i++) {
    //Contituindo valor para Pagamento
    //Aplicar o Desconto em cima do Valor Encargos
    let percentual = desconto / 100.0;
    let valorEncargosAux = valorEncargos - percentual * valorEncargos;

    //Percentual maximo do Valor Principal que podemos aplicar desconto
    let percentual_maximo_valor_principal = maxDiscountValorPrincipal || 20;
    percentual = (100 - desconto) / 100.0; // 10%
    percentual_maximo_valor_principal =
      percentual_maximo_valor_principal -
      percentual * percentual_maximo_valor_principal;

    percentual = percentual_maximo_valor_principal / 100.0; // 18%
    let valorPrincipalAux = valorPrincipal - percentual * valorPrincipal;

    let valorDesconto = valor;
    let valorAux = valorPrincipalAux + valorEncargosAux;
    valorDesconto = valorAux - valorDesconto;
    if (valorDesconto < 0) {
      valorDesconto = valorDesconto * -1;
    }

    //Formatando numeros para 2 casas decimais
    valorPrincipalAux = parseFloat(valorPrincipalAux).toFixed(2);
    valorAux = parseFloat(valorAux).toFixed(2);
    valorDesconto = parseFloat(valorDesconto).toFixed(2);
    valorEncargosAux = parseFloat(valorEncargosAux).toFixed(2);

    //console.log('[valorPrincipal]', valorPrincipal)
    //console.log('[valor]', valor)
    //console.log('[valorDesconto]', valorDesconto)
    //console.log('[valorEncargos]', valorEncargos)
    //console.log('[percentual_maximo_valor_principal]', percentual_maximo_valor_principal)
    //----------------------------------------------

    let data = '';
    data +=
      '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://service.ppware.com.br/">';
    data += '<soapenv:Header/>';
    data += '<soapenv:Body>';
    data += '<ser:CalcularParcelamento>';
    data += '<calculo>';
    data += '<credor>' + credor + '</credor>';
    data += '<descdespesa>N</descdespesa>';
    data += '<deschonorarios>N</deschonorarios>';
    data += '<dtpagto>' + dataAcordo + '</dtpagto>';
    data += '<pctdesconto>0</pctdesconto>';
    data += '<regis>' + regis + '</regis>';
    data += '<valdesconto>' + valorDesconto + '</valdesconto>';
    data += '<valoradicional>0</valoradicional>';
    data += '</calculo>';

    if (Array.isArray(contrato)) {
      //todo for each
    } else {
      data += '<prest>';
      data += '<contr>' + contrato + '</contr>';
      data += '<produt>' + produto + '</produt>';
      data += '<vencto>' + vencimento + '</vencto>';
      data += '</prest>';
    }

    data += '<login>';
    data += '<empresa>' + empresa + '</empresa>';
    data += '<pwd>' + pwd + '</pwd>';
    data += '<uid>' + uid + '</uid>';
    data += '</login>';

    data += '<calculoparc>';
    data += '<dtentrada>' + dataAcordo + '</dtentrada>';
    data += '<qtdparc>' + (qtdParcelas - 1) + '</qtdparc>';

    if (qtdParcelas == 1) {
      data += '<valentrada>' + valorAux + '</valentrada>';
    } else {
      data += '<valentrada>' + valorAux / qtdParcelas + '</valentrada>';
    }

    data += '<descpercentual>N</descpercentual>';
    data += '<desconto>' + valorDesconto + '</desconto>';
    data += '<renegociacao>N</renegociacao>';
    data += '<propostaacordo>' + tipoAcordo + '</propostaacordo>';
    data += '<datasmensais>S</datasmensais>';
    data += '<parcelasiguais>S</parcelasiguais>';
    data += '<formapagtoentrada>B</formapagtoentrada>';
    data += '<dtpagamentodi />';
    data += '<valoradicional>0</valoradicional>';
    data += '<valorverificar>0</valorverificar>';
    data += '</calculoparc>';
    data += '</ser:CalcularParcelamento>';
    data += '</soapenv:Body>';
    data += '</soapenv:Envelope>';

    var axios = require('axios');
    var config = {
      method: 'post',
      url: url,
      timeout: 0,
      headers: {
        'Content-Type': 'text/xml',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
      data: data,
    };

    //console.log('[calcularParcelamento-xml]', data);
    const response = await axios(config);
    //console.log('[CalcularParcelamento-response]', JSON.stringify(response.data));

    let result = response.data.replaceAll('S:', '', response.data);
    result = result.replaceAll('ns0:', '', result);
    result = result.replaceAll('xmlns:', '', result);
    let dom = parseXml(result);
    let json = xml2json(dom, '');
    console.log('[CalcularParcelamento-json]', json);

    result = json.Envelope.Body.CalcularParcelamentoResponse;
    var obj = {
      valorParcelar: 0,
      valorTotal: 0,
      acordoAtivo: 0,
      codParcela: 0,
      valorDesconto: valorDesconto,
      valorEncargos: valorEncargosAux,
      parcelas: [],
    };
    if (result.return.retorno.codigo === '00') {
      obj.valorParcelar = result.return.retorno.valorparcelar;
      obj.valorTotal = result.return.retorno.valortotal;
      if (Array.isArray(result.return.parcelas)) {
        obj.valorEntradaSemTaxaAdm = result.return.parcelas[0].principal;
      } else {
        obj.valorEntradaSemTaxaAdm = result.return.parcelas.principal;
      }
      obj.parcelas = result.return.parcelas;
      break;
    } else {
      //Verifica se tem acordo ativo
      if (result.return.retorno.mensagem.includes('Acordo Ativo')) {
        obj.acordoAtivo = 1;
        obj.codParcela = /(([0-9]{8})|([0-9]{9}))$/g.exec(
          result.return.retorno.mensagem
        )[0];

        //  await CancelarParcelamento(regis, obj.codParcela, vencimento);
        break;
      } else if (
        result.return.retorno.mensagem.includes('O Valor Total de Desconto')
      ) {
        desconto = desconto - 10;
        console.log(
          '[calcularParcelamentoResponse]',
          result.return.retorno.codigo + ' - ' + result.return.retorno.mensagem
        );

        if (desconto < 0) desconto = 0;
        continue;
      }
      console.log(
        '[calcularParcelamentoResponse]',
        result.return.retorno.codigo + ' - ' + result.return.retorno.mensagem
      );
      break;
    }
  }

  return obj;
}

export async function consultarParcelamento(
  regis,
  contrato,
  produto,
  vencimento,
  dataAcordo,
  qtdParcelas = 1
) {
  const { empresa, uid, pwd } = getCredentials(vencimento);
  // api com a funcionalidade de coletar o tipo de acordo
  //para utilizar posteriormente na negociação
  let data = '';
  data +=
    '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://service.ppware.com.br/">';
  data += '<soapenv:Header/>';
  data += '<soapenv:Body>';
  data += '<ser:ConsultarParcelamento>';
  if (Array.isArray(contrato)) {
    //todo for each
  } else {
    data += '<prest>';
    data += '<contr>' + contrato + '</contr>';
    data += '<produt>' + produto + '</produt>';
    data += '<vencto>' + vencimento + '</vencto>';
    data += '</prest>';
  }
  data += '<login>';
  data += '<empresa>' + empresa + '</empresa>';
  data += '<pwd>' + pwd + '</pwd>';
  data += '<uid>' + uid + '</uid>';
  data += '</login>';
  data += '</ser:ConsultarParcelamento>';
  data += '</soapenv:Body>';
  data += '</soapenv:Envelope>';

  var axios = require('axios');
  var config = {
    method: 'post',
    url: url,
    timeout: 0,
    headers: {
      'Content-Type': 'text/xml',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    data: data,
  };

  const response = await axios(config);
  //console.log('[ConsultarParcelamento-response]', JSON.stringify(response.data));

  let result = response.data.replaceAll('S:', '', response.data);
  result = result.replaceAll('ns0:', '', result);
  result = result.replaceAll('xmlns:', '', result);
  let dom = parseXml(result);
  let json = xml2json(dom, '');
  console.log('[ConsultarParcelamento-json]', json);

  result = json.Envelope.Body.ConsultarParcelamentoResponse;

  let obj = {
    tipoAcordo: '',
    codParcela: '',
    qtdParcelas: qtdParcelas,
    tiposAcordoOriginal: {},
    maxDiscountValorPrincipal: '0',
  };

  //deve-se subtrair 1 da quantidade de parcelas porque o processo ignora a entrada
  qtdParcelas = qtdParcelas - 1;
  if (result.return.retorno.codigo === '00') {
    for (var i = 0; i < result.return.agrTypes.length; i++) {
      if (qtdParcelas <= result.return.agrTypes[i].maxPayments) {
        obj.tipoAcordo = result.return.agrTypes[i].type;
        obj.codParcela = result.return.retorno.codparce;

        for (
          var t = 0;
          t < result.return.agrTypes[i].discountFields.length;
          t++
        ) {
          if (
            result.return.agrTypes[i].discountFields[t].field === 'u1vlprinc'
          ) {
            obj.maxDiscountValorPrincipal =
              result.return.agrTypes[i].discountFields[t].maxDiscount1;
            break;
          }
        }
        break;
      }
    }
    obj.tiposAcordoOriginal = { ...result.return.agrTypes };
  } else {
    console.log(
      '[consultarParcelamento-error]',
      result.return.retorno.codigo + ' - ' + result.return.retorno.mensagem
    );

    throw new Error(result.return.retorno.mensagem);
  }

  return obj;
}

export async function CancelarParcelamento(regis, codParcela, vencimento) {
  const { empresa, uid, pwd } = getCredentials(vencimento);
  let data = '';
  data +=
    '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://service.ppware.com.br/">';
  data += '<soapenv:Header/>';
  data += '<soapenv:Body>';
  data += '<ser:CancelarParcelamento>';
  data += '<cancelamento>';
  data += '<credor>' + credor + '</credor>';
  data += '<regis>' + regis + '</regis>';
  data += '<codparce>' + codParcela + '</codparce>';
  data += '</cancelamento>';
  data += '<login>';
  data += '<empresa>' + empresa + '</empresa>';
  data += '<pwd>' + pwd + '</pwd>';
  data += '<uid>' + uid + '</uid>';
  data += '</login>';
  data += '</ser:CancelarParcelamento>';
  data += '</soapenv:Body>';
  data += '</soapenv:Envelope>';

  var axios = require('axios');
  var config = {
    method: 'post',
    url: url,
    timeout: 0,
    headers: {
      'Content-Type': 'text/xml',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    data: data,
  };

  const response = await axios(config);
  console.log('[CancelarParcelamento-response]', response);

  let result = response.data.replaceAll('S:', '', response.data);
  result = result.replaceAll('ns0:', '', result);
  result = result.replaceAll('xmlns:', '', result);
  let dom = parseXml(result);
  let json = xml2json(dom, '');
  console.log('[CancelarParcelamento-json]', json);

  if (
    json.Envelope.Body.CancelarParcelamentoResponse.return.retorno.codigo ===
    '00'
  ) {
    return true;
  } else {
    console.log(
      '[CancelarParcelamento]',
      json.Envelope.Body.CancelarParcelamentoResponse.return.retorno.mensagem
    );
    return false;
  }
}
