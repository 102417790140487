let agora = new Date()
let dd = agora.getDay()
let mm = agora.getMonth()
let yyyy = agora.getFullYear()

let data = dd + '/' + mm + '/' + yyyy
let prazo5dias = dd + 5 + '/' + mm + '/' + yyyy
let prazo7dias = dd + 7 + '/' + mm + '/' + yyyy
//console.log(data)
//console.log(prazo5dias)
//console.log(prazo7dias)

export { prazo5dias, prazo7dias }