//Versao A Vista / Parcelado e com opção de segunda via

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

import { saudacao } from '../../components/chat/saudacao';
import { alterMetaTag } from '../../components/chat/metaTag';

import { currencyDisplay } from '../../utils/currency';

import DiscussLoader from '../../components/loaders/discussLoader';
import Ops from '../../components/Ops';
import vyaApi from '../../services/api';
import moment from 'moment';
import ExportOptions from '../../components/ExportOptions';
import { ResourcesContext } from '../../context/ResourcesContext';
import { header } from './Header';
import { sendPutRequest } from '../../lib/sendPutRequest';
import validateId from '../../utils/validateId';

import { addDays, format } from 'date-fns';
import { whatIsThisNumbers } from '../../utils/whatIsThisNumbers';
import { textCapitalize } from '../../utils/text';
import DatePI from '../../components/DatePI';
import addUtilDays from '../../utils/addUtilDays';
import ExportV2 from '../../components/ExportV2';
import pad from '../../utils/pad';

class InfoContato extends Component {
  /*   constructor(props) {
      super(props);
    } */

  componentWillMount() {
    const { steps } = this.props;
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <p>
          No telefone:
          <br />
          <a
            href="tel:0800-722-0222"
            style={{ color: '#fff', textDecoration: 'none' }}
          >
            <strong>0800 722 0222</strong>
          </a>
        </p>
      </div>
    );
  }
}

InfoContato.propTypes = {
  steps: PropTypes.object,
};

InfoContato.defaultProps = {
  steps: undefined,
};
class ClaroNegociacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: false,
      result: undefined,
      isCpf: true,
      placeholder: 'digite aqui...',
    };
    //this.sendPutRequest = sendPutRequest(this);
  }

  static contextType = ResourcesContext;

  componentWillMount() {
    alterMetaTag('NET negociação', '/5', '#e3262e', true);
    this.handleEnd = this.handleEnd.bind(this);
  }

  sendPutRequest = async (step) => {
    var data = JSON.stringify({
      id: this.state.result.ID,
      idAcao: this.state.result.IDACAO,
      session: this.state.sessionChat,
      step: step.id,
      frase: step.message,
      resposta: step.value,
      status: this.state.status ? this.state.status : '',
      statusCode: this.state.statusCode ? this.state.statusCode : '',
    });

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi.post('/ContatoMidia', data);
  };

  async componentDidMount() {
    if (!validateId(this.props.match.params.id)) {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage:
          'Identificamos informações incorretas. Tente novamente mais tarde.',
      });
      return;
    }

    let urlParameters = this.props.match.params.id.split('_');

    let sessionChat =
      urlParameters[0] +
      '_' +
      urlParameters[1] +
      '_' +
      moment().format('YYYYMMDD_HHMMSS');
    urlParameters = 'id=' + urlParameters[0] + '&idAcao=' + urlParameters[1];
    this.setState({ sessionChat: sessionChat });

    let resultVyaApi = {};
    //Coletando dados do contato na plataforma
    try {
      const response = await vyaApi.get(`Contato?${urlParameters}`);

      if (JSON.parse(response.data.Status === false)) {
        this.setState({
          isLoading: false,
          error: true,
          errorMessage: 'Os dados não foram localizados no sistema principal.',
        });
        return;
      }

      const original = JSON.parse(response.data.Data);
      Object.keys(original).forEach((key) => {
        resultVyaApi[key.toUpperCase()] = original[key];
      });

      resultVyaApi.CPFCNPJ = pad(resultVyaApi.CPFCNPJ, 11, 'left');

      console.log('[resultVyaApi]', resultVyaApi);
      this.setState({
        isCpf: whatIsThisNumbers(resultVyaApi.CPFCNPJ),
        result: resultVyaApi,
        isLoading: false,
        error: false,
      });

      //Registrar dados iniciais
      this.sendPutRequest({
        id: 'API',
        message: response.data.Message,
        value: response.data.Status,
      });
    } catch (err) {
      console.log('Erro: API ', err);
      this.setState({
        isLoading: false,
        error: true,
        errorMessage: 'Os dados não foram localizados no sistema principal.',
      });
      return;
    }
  }

  handleEnd({ steps, values }) {
    if (!this.state.stepFinal) {
      return;
    }

    var data = JSON.stringify({
      id: this.state.result.ID,
      idAcao: this.state.result.IDACAO,
      session: this.state.sessionChat,
      step: this.state.stepFinal.id,
      frase: this.state.stepFinal.message,
      resposta: this.state.stepFinal.value,
      status: this.state.status,
      statusCode: this.state.statusCode,
    });

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi
      .post('/ContatoMidia', data)
      .then(function(response) {
        //alert('Obrigado. Terminamos seu atendimento.');
      })
      .catch(function(error) {
        console.log('[sendPutRequest-' + steps.id + ']', error);
      });
  }

  Export = ({ steps }) => (
    <ExportV2
      eventTitle="Pagar Fatura NET"
      eventDetailsLabel={`Acordo NET no valor de ${currencyDisplay(
        steps.initial.metadata.TOTAL_DIVIDA_FINAL.replace(',', '.'),
        2
      )}`}
      eventDetails={`Acordo NET no valor de <strong>${currencyDisplay(
        steps.initial.metadata.TOTAL_DIVIDA_FINAL.replace(',', '.'),
        2
      )}</strong>`}
      eventDay={steps.escolher_data?.value || new Date()}
      barCode={steps.initial.metadata.COD_BARRAS_FINAL || ''}
      handleEnd={(id, message) => {
        let stepFinal = { id, message, value: '' };
        this.setState({ stepFinal });
        this.handleEnd({ steps });
      }}
    />
  );

  render() {
    const { isLoading, result, error } = this.state;

    if (isLoading) {
      return (
        <div className="App">
          <DiscussLoader />
        </div>
      );
    }

    if (error) {
      return (
        <Ops
          theme={theme}
          headerComponent={header}
          botAvatar="/5/avatar.png"
          message={this.state.errorMessage ? this.state.errorMessage : null}
        />
      );
    }

    // all available props

    let steps = [];
    steps = [
      {
        id: 'initial',
        message: `${saudacao(false)}${textCapitalize(
          this.state.result.NOME_ASSINANTE
        )}`,
        metadata: this.state.result,
        trigger: ({ value, steps }) => {
          this.setState({ status: 'ENTR', statusCode: 'ENTR' });
          this.sendPutRequest(steps.initial);

          return 'apresentacao';
        },
      },
      {
        id: 'apresentacao',
        message: ({ previousValue, steps }) => {
          return 'Eu sou a vya e trabalho na assessoria Sercom';
        },

        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.apresentacao);
          return 'informar_contrato';
        },
      },
      {
        id: 'informar_contrato',
        message: ({ steps }) =>
          `Seu contrato nº  ${
            steps.initial.metadata.NUM_CONTRATO
          }, no valor de ${currencyDisplay(
            steps.initial.metadata.TOTAL_DIVIDA_FINAL.replace(',', '.'),
            2
          )} não foi pago. Escolha abaixo as excelentes oportunidades para pagamento:`,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.informar_contrato);

          return 'debito_opcoes';
        },
      },
      {
        id: 'debito_opcoes',
        options: [
          {
            value: 'ESCOLHERDATA',
            label: 'Escolher melhor data',
            trigger: ({ value, steps }) => {
              this.sendPutRequest(steps.debito_opcoes);
              return 'escolher_data';
            },
          },
          {
            value: 'JAPAGOU',
            label: 'Já paguei',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'PAGO', statusCode: 'PAGO' });
              this.sendPutRequest(steps.debito_opcoes);
              return 'japagou_mensagem';
            },
          },
          {
            value: 'DESC',
            label: 'Desconheço esta dívida',
            trigger: ({ value, steps }) => {
              this.setState({ status: 'DESC', statusCode: 'DESC' });
              this.sendPutRequest(steps.debito_opcoes);
              return 'desconhece_divida_mensagem';
            },
          },
        ],
      },
      {
        id: 'escolher_data',
        component: (
          <DatePI maximum={addUtilDays(new Date(), 5)} minimum={new Date()} />
        ),
        waitAction: true,
        trigger: ({ _, steps }) => {
          this.setState({ status: 'ACAV', statusCode: 'ACAV' });
          this.sendPutRequest(steps.escolher_data);

          return 'mostrar_barra';
        },
      },
      {
        id: 'mostrar_barra',
        message: ({ steps }) => {
          return `Pronto! Geramos seu boleto no valor de ${currencyDisplay(
            steps.initial.metadata.TOTAL_DIVIDA_FINAL.replace(',', '.'),
            2
          )} ${steps.escolher_data.value &&
            `e vencimento no dia ${format(
              steps.escolher_data.value,
              'dd/MM/yyyy'
            )}`}`;
        },
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.mostrar_barra);

          return 'informa_codigo_barras';
        },
      },
      {
        id: 'informa_codigo_barras',
        message: `Código de barras:
${this.state.result.COD_BARRAS_FINAL}`,
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.informa_codigo_barras);

          return 'agradecimento';
        },
      },
      {
        id: 'exportar_codigo_barra',
        component: <this.Export />,
        end: true,
      },
      {
        id: 'desconhece_divida_mensagem',
        message: `Por gentileza, entre em contato com nossa central para maiores informações`,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.desconhece_divida_mensagem);
          return 'info_contato_desconhece';
        },
      },
      {
        id: 'japagou_mensagem',
        message:
          'Ótimo! A confirmação do pagamento é registrada em nosso sistema em até 48 horas.',
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.japagou_mensagem);
          return 'agradecimento_geral';
        },
      },
      {
        id: 'info_contato_desconhece',
        component: <InfoContato />,
        asMessage: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.info_contato_desconhece);
          return 'agradecimento_geral';
        },
      },
      {
        id: 'info_contato',
        component: <InfoContato />,
        asMessage: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.info_contato);
          return 'agradecimento_geral';
        },
      },
      {
        id: 'agradecimento_geral',
        message: ({ previousValue, steps }) => {
          let message = 'A NET agradece sua atenção.';
          let stepFinal = { id: 'agradecimento_geral', message, value: '' };
          this.setState({ stepFinal });
          return message;
        },
        end: true,
      },
      {
        id: 'agradecimento',
        message: ({ previousValue, steps }) => {
          let message = 'A Net agradece sua atenção.';
          let stepFinal = { id: 'agradecimento', message: message, value: '' };
          this.setState({ stepFinal });
          return message;
        },
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.agradecimento);
          this.setState({ placeholder: 'A NET agradece sua atenção.' });
          return 'exportar_codigo_barra';
        },
      },
    ];

    return (
      <ThemeProvider theme={theme}>
        <ChatBot
          headerComponent={header}
          // Avatar do Bot e do usuário //
          botAvatar={'/5/avatar.png'}
          handleEnd={this.handleEnd}
          recognitionEnable={false}
          recognitionPlaceholder="Escutando ..."
          placeholder={this.state.placeholder}
          /* headerTitle="SKY" */
          floating={false}
          steps={steps}
          delay={500}
          // Estilo do Rodapé (Footer) //
          /* footerStyle={{ backgroundColor: '#fff', margin: 5, padding: 1, borderRadius: 7, elevation: 2, }}*/
          submitButtonStyle={{ fill: '#00a4ec' }}
        />
      </ThemeProvider>
    );
  }
}

export default ClaroNegociacao;
