import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Component } from 'react';
import Chatbot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { ResourcesContext } from '../../context/ResourcesContext';
import { addDays } from '../../utils/date';
import addUtilDays from '../../utils/addUtilDays';
import { saudacao } from '../../components/chat/saudacao';
import DatePI from '../../components/DatePI';

import { currencyDisplay } from '../../utils/currency';
import ExportV2 from '../../components/ExportV2';
import { flexFormat } from '../../utils/flexFormat';
import { header } from './Header';
import { theme } from './theme';
import CustomOptions from '../../components/CustomOptions';
import vyaApi from '../../services/api';
import moment from 'moment';
import validateId from '../../utils/validateId';
import { alterMetaTag } from '../../components/chat/metaTag';
import Spinner from '../../components/loaders/spinner';

export default class WMarconiChatbot extends Component {
  static contextType = ResourcesContext;
  constructor(props) {
    super(props);

    this.state = {
      cpfTypeTries: 0,
      data: {
        Nome_Cliente: 'Nilza',
        cpf_cnpj: '123456789',
        telefone: '1192938123',
        valor_proposta_avista: 250,
        qtde_parcelas: 2,
        valor_proposta_parcelada: 152,
        dataAcordo: format(addDays(new Date(), 3), 'dd/MM/yyyy', {
          locale: ptBR,
        }),
        data_vencimento: '22/11/2020',
        Valor_Divida: 1519,
        placeholder: 'Digite aqui sua mensagem...',
      },
    };
    this.handleEnd = this.handleEnd.bind(this);
  }

  componentWillMount() {
    alterMetaTag(
      'Lopes Supermercado negociação',
      '/wmarconi',
      theme.botBubbleColor
    );
  }

  async sendPutRequest(step) {
    var data = JSON.stringify({
      id: this.state.data.id,
      idAcao: this.state.data.idAcao,
      session: this.state.sessionChat,
      step: step.id,
      frase: step.message,
      resposta: step.value,
      status: this.state.status ? this.state.status : '',
      statusCode: this.state.statusCode ? this.state.statusCode : '',
    });

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi.post('/ContatoMidia', data);
  }

  async componentDidMount() {
    if (!validateId(this.props.match.params.id)) {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage:
          'Identificamos informações incorretas. Tente novamente mais tarde.',
      });
      return;
    }

    let urlParameters = this.props.match.params.id.split('_');

    let sessionChat =
      urlParameters[0] +
      '_' +
      urlParameters[1] +
      '_' +
      moment().format('YYYYMMDD_HHMMSS');
    urlParameters = 'id=' + urlParameters[0] + '&idAcao=' + urlParameters[1];
    this.setState({ sessionChat: sessionChat });

    let resultVyaApi = {};
    //Coletando dados do contato na plataforma
    try {
      this.setState({ isLoading: true });
      const response = await vyaApi.get(`Contato?${urlParameters}`);

      if (JSON.parse(response.data.Status === false)) {
        this.setState({
          isLoading: false,
          error: true,
          errorMessage: 'Os dados não foram localizados no sistema principal.',
        });
        return;
      }

      resultVyaApi = JSON.parse(response.data.Data);
      console.log('[resultVyaApi]', resultVyaApi);
      this.setState({
        data: resultVyaApi,
        isLoading: false,
        error: false,
      });

      //Registrar dados iniciais
      this.sendPutRequest({
        id: 'API',
        message: response.data.Message,
        value: response.data.Status,
      });
    } catch (err) {
      console.log('Erro: API ', err);
      this.setState({
        isLoading: false,
        error: true,
        errorMessage: 'Os dados não foram localizados no sistema principal.',
      });
      return;
    }
  }

  InfoContato = () => (
    <div style={{ width: '100%' }}>
      <p>
        Para conseguir maiores informações, ligue para:
        <br />
        <a
          href="tel:08008809988"
          style={{ fontWeight: '700', textDecoration: 'none', color: '#fff' }}
        >
          0800 880 9988
        </a>
        <br />
        Temos algo muito importante para você.
      </p>
    </div>
  );

  processBillet = () => {
    setTimeout(() => {
      this.context.setValues(
        'https://api012.vya.digital/billet/pdf/file/boleto-10387605428-2021-01-13.pdf',
        '03399076364030010017554740301012884990000026549'
      );
    }, 5000);
  };

  handleEnd = () => {
    this.setState({
      data: {
        ...this.state.data,
        placeholder: 'Lopes supermercado agradece 👋',
      },
    });
    this.context.handleEnded();
  };

  getState = (key) => this.state.data[key];

  Export = ({ steps }) => (
    <ExportV2
      eventTitle="Pagar Fatura vya.digital"
      eventDetailsLabel={`Acordo vya.digital no valor de ${currencyDisplay(
        this.getState('valorBoleto'),
        2
      )}`}
      eventDetails={`Acordo vya.digital no valor de <strong>${currencyDisplay(
        this.getState('valorBoleto'),
        2
      )}</strong>`}
      eventDay={
        parseISO(flexFormat(this.getState('dataAcordo'), 'YMD')) || new Date()
      }
      barCode={this.context.digitableLine || ''}
      billet={this.context.fileUrl}
      handleEnd={() => {}}
    />
  );

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div className="App">
          <Spinner color={theme.botBubbleColor} />
        </div>
      );
    }

    const steps = [
      {
        id: 'initial',
        message: `${saudacao()} Eu sou o Marc, agente virtual da WMarconi. Representante do Supermercado Lopes. E tenho informações importantes para você.`,
        metadata: this.state.data,
        trigger: ({ steps }) => {
          this.setState({ status: 'ENTR', statusCode: 'ENTR' });
          this.sendPutRequest(steps.initial);

          return 'solicitar_cpf';
        },
      },
      {
        id: 'solicitar_cpf',
        message: () =>
          this.state.cpfTypeTries >= 1
            ? 'Confirme os 3 primeiros digitos do seu CPF'
            : 'Mas para sua segurança, me confirme os 3 primeiros digitos do seu CPF...',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.solicitar_cpf);

          return 'pegar_cpf';
        },
      },
      {
        id: 'pegar_cpf',
        user: true,
        validator: (value) => {
          if (isNaN(value)) {
            return 'Por favor digite apenas número';
          }
          return true;
        },
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.pegar_cpf);

          return 'validar_cpf';
        },
      },
      {
        id: 'validar_cpf',
        message: ({ previousValue, steps }) => {
          if (
            previousValue.substring(0, 3) ===
            steps.initial.metadata.cpf_cnpj.substring(0, 3)
          ) {
            return `Sabe aquele débito do cartão Lopes Supermercados no valor de ${currencyDisplay(
              steps.initial.metadata.valor_divida,
              2
            )}?`;
          } else {
            this.setState({ cpfTypeTries: this.state.cpfTypeTries + 1 });

            return this.state.cpfTypeTries + 1 >= 2
              ? 'Desculpe, não localizei seu CPF'
              : 'Ops! CPF incorreto vamos tentar novamente.';
          }
        },
        delay: 1200,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.validar_cpf);
          if (this.state.cpfTypeTries >= 2) {
            return 'info_contato';
          }
          if (
            steps.pegar_cpf.value.substring(0, 3) ===
            steps.initial.metadata.cpf_cnpj.substring(0, 3)
          ) {
            return 'mostrar_opcoes_debitos';
          } else {
            return 'solicitar_cpf';
          }
        },
      },
      {
        id: 'mostrar_opcoes_debitos',
        message:
          'Fechando um acordo agora, consigo um excelente desconto! Qual a melhor opção pra você?',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.mostrar_opcoes_debitos);

          return 'debitos_pendentes';
        },
      },
      {
        id: 'debitos_pendentes',
        component: (
          <CustomOptions
            primaryColor="#2a4b90"
            options={[
              `à vista`,
              this.state.data.valor_proposta_parcelada && `parcelado`,
              'nenhuma',
              'desconheço esta dívida',
              'já paguei',
            ]}
            optionsLabel={[
              `à vista ${currencyDisplay(
                this.state.data.valor_proposta_avista,
                2
              )}`,
              this.state.data.valor_proposta_parcelada &&
                `parcelado ${
                  this.state.data.qtde_parcelas
                }x de ${currencyDisplay(
                  this.state.data.valor_proposta_parcelada,
                  2
                )}`,
              'nenhuma',
              'desconheço esta dívida',
              'já paguei',
            ]}
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.debitos_pendentes);
          const options = {
            'à vista': 'pagamento_a_vista',
            parcelado: 'pagamento_parcelado',
            nenhuma: 'extender_prazo',
            'desconheço esta dívida': 'desconhece_divida_mensagem',
            'já paguei': 'ja_paguei',
          };
          return options[value];

          //return options[+value]
        },
      },
      {
        id: 'ja_paguei',
        component: (
          <p>
            Perfeito! A confirmação do pagamento pode levar até 7 dias úteis. Em
            caso de dúvidas ligue{' '}
            <a
              href="tel:08008809988"
              style={{
                color: '#fff',
                textDecoration: 'none',
                fontWeight: '700',
              }}
            >
              0800 880 9988
            </a>
            .
          </p>
        ),
        asMessage: true,
        trigger: ({ steps }) => {
          this.setState({ status: 'PAGO', statusCode: 'PAGO' });
          this.sendPutRequest(steps.ja_paguei);

          return 'agradecimento';
        },
      },
      {
        id: 'pagamento_a_vista',
        message: ({ previousValue, steps }) => {
          const date = format(addUtilDays(new Date(), 7, true), 'dd/MM/yyyy', {
            locale: ptBR,
          });

          return `Você escolheu pagar à vista o valor de ${currencyDisplay(
            this.state.data.valor_proposta_avista,
            2
          )} com vencimento em ${date}.`;
        },
        trigger: ({ steps }) => {
          this.setState({ status: 'ACAV', statusCode: 'ACAV' });
          this.sendPutRequest(steps.pagamento_a_vista);
          return 'receber_email';
        },
      },
      {
        id: 'pagamento_parcelado',
        message: ({ previousValue, steps }) => {
          const date = format(addUtilDays(new Date(), 7, true), 'dd/MM/yyyy', {
            locale: ptBR,
          });

          return `Você escolheu pagar ${
            steps.initial.metadata.qtde_parcelas
          } parcelas no valor de ${currencyDisplay(
            this.state.data.valor_proposta_parcelada,
            2
          )} com vencimento em ${date}?`;
        },
        trigger: ({ steps }) => {
          this.setState({ status: 'ACPA', statusCode: 'ACPA' });
          this.sendPutRequest(steps.pagamento_parcelado);

          return 'receber_email';
        },
      },
      {
        id: 'extender_prazo',
        message: () => {
          const date = format(addUtilDays(new Date(), 10, true), 'dd/MM/yyyy');
          return `E se eu estender o prazo para você até o dia ${date}?`;
        },
        trigger: ({ steps }) => {
          this.setState({ status: 'EXTPR', statusCode: 'EXTPR' });
          this.sendPutRequest(steps.extender_prazo);

          return 'extender_prazo_opcoes';
        },
      },
      {
        id: 'extender_prazo_opcoes',
        component: (
          <CustomOptions
            primaryColor="#2a4b90"
            options={['gostei', 'não gostei']}
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.extender_prazo_opcoes);
          const options = {
            gostei: 'pagamento_info',
            'não gostei': 'nao_gostei_proposta',
          };
          return options[value];

          //return options[+value]
        },
      },
      {
        id: 'pagamento_info',
        message: 'Escolha a proposta que mais se encaixa no seu orçamento:',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.pagamento_info);

          return 'pagamento_info_opcoes';
        },
      },
      {
        id: 'pagamento_info_opcoes',
        component: (
          <CustomOptions
            primaryColor="#2a4b90"
            options={[`à vista`, `parcelado`, 'nenhuma']}
            optionsLabel={[
              `à vista ${currencyDisplay(
                this.state.data.valor_proposta_avista,
                2
              )}`,
              this.state.data.valor_proposta_parcelada &&
                `parcelado ${
                  this.state.data.qtde_parcelas
                }x de ${currencyDisplay(
                  this.state.data.valor_proposta_parcelada,
                  2
                )}`,
              'nenhuma',
            ]}
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.pagamento_info_opcoes);
          const options = {
            'à vista': 'prazo_pagamento_a_vista',
            parcelado: 'prazo_pagamento_parcelado',
            nenhuma: 'agradecimento',
          };

          return options[value];

          //return options[+value]
        },
      },
      {
        id: 'prazo_pagamento_a_vista',
        message: 'Escolha a melhor data para o pagamento.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.prazo_pagamento_a_vista);
          return 'escolha_prazo_pagamento_a_vista';
        },
      },
      {
        id: 'escolha_prazo_pagamento_a_vista',
        component: <DatePI maximum={addUtilDays(new Date(), 10, true)} />,
        waitAction: true,
        delay: 4000,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.escolha_prazo_pagamento_a_vista);
          return 'info_pagamento_final_a_vista';
        },
      },
      {
        id: 'info_pagamento_final_a_vista',
        message: ({ previousValue, steps }) => {
          const date = format(previousValue, 'dd/MM/yyyy');
          return `Você escolheu pagar à vista o valor de ${currencyDisplay(
            steps.initial.metadata.valor_proposta_avista,
            2
          )} com vencimento em ${date}`;
        },
        trigger: ({ value, steps }) => {
          this.setState({ status: 'ACAVE', statusCode: 'ACAVE' });
          this.sendPutRequest(steps.info_pagamento_final_a_vista);
          this.setState({
            data: {
              ...this.state.data,
              dataAcordoOriginal: format(this.context.date, 'yyyy-MM-dd', {
                locale: ptBR,
              }),
              dataAcordo: format(this.context.date, 'dd/MM/yyyy', {
                locale: ptBR,
              }),
            },
          });

          return 'receber_email';
        },
      },
      {
        id: 'prazo_pagamento_parcelado',
        message: 'Escolha a melhor data para o pagamento.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.prazo_pagamento_parcelado);

          return 'escolha_prazo_pagamento_parcelado';
        },
      },
      {
        id: 'escolha_prazo_pagamento_parcelado',
        component: <DatePI maximum={addUtilDays(new Date(), 10, true)} />,
        waitAction: true,
        delay: 4000,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.escolha_prazo_pagamento_parcelado);
          return 'info_pagamento_final_parcelado';
        },
      },
      {
        id: 'info_pagamento_final_parcelado',
        message: ({ previousValue, steps }) => {
          const date = format(previousValue, 'dd/MM/yyyy');
          return `Você escolheu pagar ${
            steps.initial.metadata.qtde_parcelas
          } parcelas no valor de ${currencyDisplay(
            steps.initial.metadata.valor_proposta_parcelada,
            2
          )} com vencimento em ${date}`;
        },
        trigger: ({ value, steps }) => {
          this.setState({ status: 'ACAPE', statusCode: 'ACAPE' });
          this.sendPutRequest(steps.info_pagamento_final_parcelado);
          this.setState({
            data: {
              ...this.state.data,
              dataAcordoOriginal: format(this.context.date, 'yyyy-MM-dd', {
                locale: ptBR,
              }),
              dataAcordo: format(this.context.date, 'dd/MM/yyyy', {
                locale: ptBR,
              }),
            },
          });

          return 'receber_email';
        },
      },

      {
        id: 'info_contato',
        component: <this.InfoContato />,
        asMessage: true,
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.info_contato);

          return 'agradecimento';
        },
        delay: 2000,
      },
      {
        id: 'nao_gostei_proposta',
        component: (
          <p>
            Poxa, mas não desista. Ligue na nossa Central que tenho certeza que
            teremos algo que lhe atenda!{' '}
            <a
              href="tel:08008809988"
              style={{
                fontWeight: '700',
                color: '#fff',
                textDecoration: 'none',
              }}
            >
              0800 880 9988
            </a>
            .
          </p>
        ),
        asMessage: true,
        trigger: ({ steps }) => {
          this.setState({ status: 'RECU', statusCode: 'RECU' });
          this.sendPutRequest(steps.nao_gostei_proposta);
          return 'agradecimento';
        },
      },
      {
        id: 'agradecimento',
        message: 'O Lopes Supermercado agradece sua atenção!',
        end: true,
      },
      {
        id: 'desconhece_divida_mensagem',
        asMessage: true,
        component: (
          <p>
            Por gentileza, entre em contato com nossa central para maiores
            informações{' '}
            <a
              href="tel:08008809988"
              style={{ color: '#fff', textDecoration: 'none', fontWeight: 700 }}
            >
              0800 880 9988
            </a>
            .
          </p>
        ),
        trigger: ({ steps }) => {
          this.setState({ status: 'DESC', statusCode: 'DESC' });
          this.sendPutRequest(steps.desconhece_divida_mensagem);
          return 'agradecimento';
        },
      },
      {
        id: 'receber_email',
        message: 'Deseja receber o boleto por email?',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.receber_email);
          return 'receber_email_options';
        },
      },
      {
        id: 'receber_email_options',
        component: (
          <CustomOptions primaryColor="#2a4b90" options={['sim', 'não']} />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.receber_email_options);
          const options = {
            sim: 'confirmar_email',
            não: 'enviar_sms',
          };

          return options[value];
        },
      },
      {
        id: 'confirmar_email',
        message: 'Qual o seu email?',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.confirmar_email);
          return 'editar_email';
        },
      },
      {
        id: 'editar_email',
        user: true,
        validator: (value) => {
          if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value.trim())) {
            return 'Email inválido!';
          }
          return true;
        },
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.editar_email);
          return 'confirmar_email_usuário';
        },
      },
      {
        id: 'confirmar_email_usuário',
        message: ({ previousValue, steps }) => {
          return `O email esta correto? ${previousValue.trim() ||
            this.state.data.email}`;
        },
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.confirmar_email_usuário);
          return 'verificar_email';
        },
      },
      {
        id: 'verificar_email',
        component: (
          <CustomOptions primaryColor="#2a4b90" options={['sim', 'não']} />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.verificar_email);
          const options = {
            sim: 'email_ok',
            não: 'confirmar_email',
          };

          return options[value];
        },
      },
      {
        id: 'enviar_sms',
        message:
          'Ok. Será enviado em até 24 horas em seu celular via SMS, a linha digitável do seu pagamento.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.enviar_sms);
          return 'acordo_realizado';
        },
      },
      {
        id: 'email_ok',
        message: ({ previousValue, steps }) => {
          //console.log('email?', steps)
          return `Será enviado em até 24 horas um email para ${steps.editar_email.value.trim()} com o boleto para pagamento do seu débito`;
        },
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.confirm_email_ok);
          return 'aviso_spam';
        },
      },
      {
        id: 'aviso_spam',
        message:
          'Confira na caixa de entrada ou spam. Pague até o vencimento para não perder os descontos.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.aviso_spam);
          return 'acordo_realizado';
        },
      },
      {
        id: 'acordo_realizado',
        component: (
          <p>
            Parabéns pelo seu acordo. E caso tenha dúvidas entre em contato no{' '}
            <a
              href="tel:08008809988"
              style={{
                color: '#fff',
                textDecoration: 'none',
                fontWeight: '700',
              }}
            >
              0800 880 9988
            </a>
            .
          </p>
        ),
        asMessage: true,
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.acordo_realizado);
          return 'agradecimento';
        },
      },
    ];
    return (
      <ThemeProvider theme={theme}>
        <Chatbot
          className="--no-padding-top"
          headerComponent={header}
          recognitionEnable={false}
          placeholder={this.state.data.placeholder}
          botDelay={500}
          botAvatar="/wmarconi/avatar-bot.png"
          floating={false}
          handleEnd={this.handleEnd}
          {...this.props}
          steps={steps}
          submitButtonStyle={{ fill: '#2a4b90' }}
        />
      </ThemeProvider>
    );
  }
}
