import React, { useEffect, useRef, useState } from 'react';
import { classNames } from '../../utils/classNames';

import { Container } from './styles';

function CustomOptions({
  options,
  optionsLabel,
  step,
  triggerNextStep,
  primaryColor,
}) {
  const [triggered, setTriggered] = useState(false);
  const [selectedOpt, setSelectedOpt] = useState(null);

  const timeout = useRef(null);

  useEffect(() => () => clearTimeout(timeout.current));

  const handleClick = (value, index) => {
    if (triggered) return;

    step.value = value;

    setSelectedOpt(value);
    triggerNextStep();
    setTriggered(true);
  };

  return (
    <Container primaryColor={primaryColor}>
      <div className="wrapper">
        {optionsLabel
          ? optionsLabel.map((opt, index) => (
            opt && <li
                className={classNames({
                  '--disabled': triggered,
                  '--invisible ': selectedOpt !== options[index] && triggered,
                })}
                key={index}
                onClick={() => handleClick(options[index], index)}
              >
                {opt}
              </li>
            ))
          : options.map((opt, index) => (
              opt&&<li
                className={classNames({
                  '--disabled': triggered,
                  '--invisible ': selectedOpt !== opt && triggered,
                })}
                key={index}
                onClick={() => handleClick(opt)}
              >
                {opt}
              </li>
            ))}
      </div>
    </Container>
  );
}

export default CustomOptions;
