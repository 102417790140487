export const header = (
  <a href="https://banco.bradesco/" target="_blank" rel="noreferrer noopened">
    <div
      className="sc-iqHYGH ieNPiA rsc-header"
      style={{
        background: '#e5173f',
        height: '53px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      {/* <h2 class="sc-crrsfI lhHHeu rsc-header-title">Credsystem</h2> */}
      <img
        src="/1/logo.png"
        alt="Bradesco"
        style={{
          width: '120px',

          paddingLeft: '15px',
        }}
      />
    </div>
  </a>
);
