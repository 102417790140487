import React from 'react';

// Pages Component
import SystemInteractBradesco from '../client/system-interact/bradesco/negociacao';
import SystemInteractBradescoLp from '../client/system-interact/bradescoLp/negociacao';
import SystemInteractBradescoLp_V2 from '../client/system-interact/bradescoLp_v2/negociacao';
import SercomCredsystemParceladoBoleto from '../client/sercom/credsystem/negociacaoParceladoBoleto';
import SercomSkyParceladoBoleto from '../client/sercom/sky/negociacaoParceladoBoleto';
import ClaroApp from '../client/ClaroNet/negociacao';
import ClaoNetIntersic from '../client/ClaroNet-Intersic/negociacao';
import Imasf from '../client/siscom/imasf/negociacao';

//Vya.Digital - Presentations
import PresentationSkyNegociacao from '../client/vya.digital/presentations/sky/negociacao';
import PresentationCredSystemNegociacao from '../client/vya.digital/presentations/credsystem/negociacao';
import PresentationVIVONegociacao from '../client/vya.digital/presentations/vivo/negociacao';
import PresentationTimNegociacao from '../client/vya.digital/presentations/tim/negociacao';
import PresentationClaroNegociacao from '../client/vya.digital/presentations/claro/negociacao';
import PresentationEnelNegociacao from '../client/vya.digital/presentations/enel/negociacao';

import DefaultPage from '../components/DefaultPage';

import Ops from '../components/Ops';

import Teste from '../client/teste/help';

import { theme as themeSky } from '../client/sercom/sky/theme';
import { header as headerSky } from '../client/sercom/sky/Header';
import { theme as themeCred } from '../client/sercom/credsystem/theme';
import { header as headerCred } from '../client/sercom/credsystem/Header';
import { theme as themeBrad } from '../client/system-interact/bradesco/theme';
import { header as headerBrad } from '../client/system-interact/bradesco/Header';
import { theme as themeClaro } from '../client/ClaroNet/theme';
import { header as headerClaro } from '../client/ClaroNet/Header';
import { VyaDigital } from '../client/vya.digital/presentations/vya.digital';
import WMarconiChatbot from '../client/wmarconi/negociacao';
import GrupoZeloChatbot from '../client/GrupoZelo/negociacao';
import { header } from '../client/GrupoZelo/Header';
import { theme } from '../client/GrupoZelo/theme';
import ItauChatbot from '../client/siscom/itau/negociacao';
import { theme as themeItau } from '../client/siscom/itau/theme';
import { header as themeHeader } from '../client/siscom/itau/Header';
import StoccoChatbot from '../client/stocco/negociacao';
import StoccoChatbotSantander from '../client/stocco/santander/negociacao';
import Unimed from '../client/Unimed';
import UnimedSeguros from '../client/Unimed-seguros';

const publicRoutes = [
  {
    path: '/1',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={themeBrad}
        headerComponent={headerBrad}
        botAvatar="/1/avatar.png"
        ad={true}
      />
    ),
  },
  { path: '/1/:id', component: SystemInteractBradesco },
  {
    path: '/2',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={themeCred}
        headerComponent={headerCred}
        botAvatar="/2/avatar.png"
        ad={true}
      />
    ),
  },
  { path: '/2/:id', component: SercomCredsystemParceladoBoleto },
  {
    path: '/3',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={themeSky}
        headerComponent={headerSky}
        botAvatar="/3/avatar.png"
        ad={true}
      />
    ),
  },
  { path: '/3/:id', component: SercomSkyParceladoBoleto },

  {
    path: '/5',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={themeClaro}
        headerComponent={headerClaro}
        botAvatar="/5/avatar.png"
        ad={true}
      />
    ),
  },
  { path: '/5/:id', component: ClaroApp },
  {
    path: '/6',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={themeBrad}
        headerComponent={headerBrad}
        botAvatar="/1/avatar.png"
        ad={true}
      />
    ),
  },
  { path: '/6/:id', component: SystemInteractBradescoLp },
  {
    path: '/7',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={themeClaro}
        headerComponent={headerClaro}
        botAvatar="/5/avatar.png"
        ad={true}
      />
    ),
  },
  { path: '/wmarconi/:id', component: WMarconiChatbot },
  {
    path: '/marconi',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={themeClaro}
        headerComponent={headerClaro}
        botAvatar="/5/avatar.png"
        ad={true}
      />
    ),
  },
  {
    path: '/10',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={themeBrad}
        headerComponent={headerBrad}
        botAvatar="/1/avatar.png"
        ad={true}
      />
    ),
  },
  { path: '/10/:id', component: SystemInteractBradescoLp_V2 },
  {
    path: '/11',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={theme}
        headerComponent={header}
        botAvatar="/11/avatar.png"
        ad={true}
      />
    ),
  },
  { path: '/11/:id', component: GrupoZeloChatbot },
  {
    path: '/12',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={themeItau}
        headerComponent={themeHeader}
        botAvatar="/11/avatar.png"
        ad={true}
      />
    ),
  },
  { path: '/12/:id', component: ItauChatbot },
  {
    path: '/13',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={theme}
        headerComponent={header}
        botAvatar="/11/avatar.png"
        ad={true}
      />
    ),
  },
  {
    path: '/14',
    exact: true,
    component: (props) => (
      <Ops
        {...props}
        theme={theme}
        headerComponent={header}
        botAvatar="/11/avatar.png"
        ad={true}
      />
    ),
  },
  { path: '/13/:id', component: StoccoChatbot },
  { path: '/14/:id', component: StoccoChatbotSantander },
  { path: '/7/:id', component: ClaoNetIntersic },
  { path: '/imasf', exact: true, component: Imasf },
  { path: '/unimed', exact: true, component: Unimed },
  { path: '/unimed-seguros', exact: true, component: UnimedSeguros },

  { path: '/credsystem', component: PresentationCredSystemNegociacao }, // CredSystem
  { path: '/sky', component: PresentationSkyNegociacao }, //Sky
  { path: '/vivo', component: PresentationVIVONegociacao }, //VIVO
  { path: '/tim', component: PresentationTimNegociacao }, //TIM
  { path: '/claro', component: PresentationClaroNegociacao }, //Claro
  { path: '/enel', component: PresentationEnelNegociacao }, //Enel
  { path: '/vya', component: VyaDigital },

  { path: '/teste', component: Teste }, //Enel
  { path: '/', exact: true, component: DefaultPage },
];

export default publicRoutes;
