import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import bg from '../../assets/7883.jpg';
const Container = styled.div`
  width: 100%;
  height: 100vh;
  /* background: url(${bg}) no-repeat; */
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  h3,
  span {
    color: #333;
    text-align: center;
  }

  h3 {
    font-size: 60px;
  }

  a {
    display: flex;
    font-size: 10px;
    color: #333;
    margin: 10px;
    justify-self: flex-end;
  }

  img {
    width: 60%;
  }

  @media screen and (max-width: 568px) {
    h3 {
      font-size: 40px;
    }

    img {
      width: 80% !important;
    }

    span {
      margin-bottom: 15px
    }
  }

  @media screen and (max-width: 850px) {
    h3 {
      font-size: 50px;
    }
  }

  @media screen and (max-height: 680px) {
    img {
      width: 40%;
    }

    h3 {
      font-size: 40px
    }
  }

  //578
`;

function DefaultPage() {
  const [secs, setSecs] = useState(15);
  useEffect(() => {
    const interval = setInterval(
      () => setSecs((state) => (state !== -1 ? state - 1 : 0)),
      1000
    );
    const timeout = setTimeout(
      () => (window.location.href = 'https://vya.digital/'),
      15000
    );

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);
  return (
    <Container>
      <h3>fatura.io</h3>
      <span>
        você será redirecionado em {secs} segundo{secs !== 1 && 's'}.
      </span>
      <img src={bg} alt="background" />
      <a href="http://www.freepik.com">Designed by pch.vector / Freepik</a>
    </Container>
  );
}

export default DefaultPage;
