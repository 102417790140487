import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
class Help extends Component {
    componentWillMount() {
        const { previousStep } = this.props;
        const { metadata = {} } = previousStep;
        const trigger = previousStep.value === 'help' ? 'help-message' : metadata.triggerNext;


        this.props.triggerNextStep({ value: metadata.triggerNext, trigger });
    }

    render() {
        return null;
    }
}

class HelpMessage extends Component {
    componentDidMount() {
        const { previousStep } = this.props;
        this.props.triggerNextStep({ trigger: previousStep.id });
    }

    render() {
        return (
            <div>
                Help Message
            </div>
        );
    }
}



class Test extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    componentDidMount() {



    }

    render() {

        // all available props
        const theme = {
            /* background: '#f5f8fb', */
            fontFamily: 'Gilroy, Arial, Helvetica, sans-serif',
            headerBgColor: '#2b292e',
            headerFontColor: '#fff',
            headerFontSize: '21px',
            botBubbleColor: '#2b292e',
            botFontColor: '#fff',
            userBubbleColor: '#fff',
            userFontColor: '#8f8e91',
        };

        const steps = [
            {
                id: '1',
                message: 'Some Question. Type "help" if you need help',
                trigger: '2',
            },
            {
                id: '2',
                user: true,
                trigger: 'help',
                metadata: {
                    triggerNext: '3',
                },
            },
            {
                id: 'help',
                replace: true,
                component: <Help />,
                delay: 8,
                waitAction: true,
            },
            {
                id: 'help-message',
                component: <HelpMessage />,
                asMessage: true,
                waitAction: true,
            },
            {
                id: '3',
                message: 'End',
                end: true,
            },
        ];

        return (
            <ThemeProvider theme={theme}>
                <ChatBot
                    /* headerComponent={
                          <div class="sc-iqHYGH ieNPiA rsc-header">
                               <h2 class="sc-crrsfI lhHHeu rsc-header-title">Bradesco</h2> 
                              <img class="sc-crrsfI lhHHeu rsc-header-title" src="/bot/logo_branco.png" alt="Bradesco" style={{width:"130px", height:"31px"}}/> 
                          </div>
                      }  */
                    // Avatar do Bot e do usuário //
                    botAvatar='/avatar_credsystem.png'
                    // userAvatar='https://i.imgur.com/6sm2DYv.png'

                    handleEnd={this.handleEnd}
                    recognitionEnable={false}
                    recognitionPlaceholder="Escutando ..."
                    placeholder="digite aqui..."
                    botDelay={500}
                    headerTitle="Credsystem"
                    floating={false}
                    steps={steps}

                // Estilo do Rodapé (Footer) //
                /* footerStyle={{ backgroundColor: '#fff', margin: 5, padding: 1, borderRadius: 7, elevation: 2, }}
                submitButtonStyle={{ backgroundColor: '#E89984', borderRadius: 4, width: 63, margin: 2 }} */
                />
            </ThemeProvider>
        );
    }
}

export default Test;