import React from "react";
import { FiMail, FiPhone, FiDollarSign } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";

import { Container } from "./styles";

function VyaChannel() {
  return (
    <Container>
      <ul>
        <li>
          <div className="icon">
            <FiPhone size={16} color="#fff" />
          </div>
          <div className="text">
            <h3>agente virtual</h3>
            <a href="tel:1131350318">(11) 3135 0318</a>
          </div>
        </li>
        <li>
          <div className="icon">
            <FiMail size={16} color="#fff" />
          </div>
          <div className="text">
            <h3>comercial</h3>
            <a href="mailto:comercial@vya.digital">comercial@vya.digital</a>
          </div>
        </li>
        <li>
          <div className="icon">
            <FaWhatsapp size={16} color="#fff" />
          </div>
          <div className="text">
            <h3>whatsapp bot</h3>
            <a href="https://wa.me/551131350318">(11) 3135-0318</a>
          </div>
        </li>
        <li>
          <div className="icon">
            <FiDollarSign size={16} color="#fff" />
          </div>
          <div className="text">
            <h3>portal de negociação</h3>
            <a href="https://simplifica.ai">simplifica.ai</a>
          </div>
        </li>
      </ul>
    </Container>
  );
}

export default VyaChannel;
