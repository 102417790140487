import styled from 'styled-components';

export const Container = styled.ul`
  width: 100%;
  
  display: flex;
  flex-direction: column;

  list-style: none;
  margin-left: 40px;

  li {
    width: 70%;
    min-width: 70%;
    padding: 12px 0px;
    border: 2px solid ${(props) => props.primaryColor || '#0f71b3'};

    margin-bottom: 5px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: ${(props) => props.primaryColor || '#0f71b3'};
    cursor: pointer;
    border-radius: 25px;

    &:hover {
      background-color: #e1e8ed
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.--disabled {
      opacity: 0.5;
      cursor: default;

      &:hover {
        background-color: transparent
      }
    }

    &.--invisible {
      display: none;
    }
  }
`;
