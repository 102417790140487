import axios from 'axios';

const {
  REACT_APP_VYA_DIGITAL_API,
  REACT_APP_BILLET_API_URL,
  REACT_APP_CSLOG_API_URL,
  REACT_APP_CSLOG_KEY_DEV,
  REACT_APP_CSLOG_KEY_PRD,
} = process.env;

const vyaApi = axios.create({
  baseURL: REACT_APP_VYA_DIGITAL_API,
  timeout: 0,
});

const billetApi = axios.create({
  baseURL: REACT_APP_BILLET_API_URL,
});

const cslogApi = axios.create({
  baseURL: REACT_APP_CSLOG_API_URL,
  headers: {
    'x-api-key': REACT_APP_CSLOG_KEY_PRD,
  },
});

export { billetApi, cslogApi };
export default vyaApi;
