import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { theme } from './theme';
// import { Container } from './styles';

function LoaderStocco({ className = '', close }) {
  const [minCloseTimer, setMinCloseTimer] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setMinCloseTimer(true), 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      className={`loader-container ${minCloseTimer && close ? 'close' : ''}`}
      style={{ backgroundColor: theme.headerBgColor }}
    >
      <img
        src="/13/logo.webp"
        alt="logo"
        style={{ marginBottom: '3rem', width: 200 }}
      />
      <Loader
        type="Oval"
        color={theme.botBubbleColor}
        height={30}
        width={30}
      // timeout={20000} //20 secs max
      />
    </div>
  );
}

export default LoaderStocco;
