import Logo from './assets/logo.svg';

export const header = (
  <a
    href="https://www.segurosunimed.com.br/"
    target="_blank"
    rel="noreferrer noopened"
  >
    <div
      className="sc-iqHYGH ieNPiA rsc-header"
      style={{
        background: '#112b53',
        height: '53px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <img
        src={Logo}
        alt="UNIMEDSEGUROS"
        style={{
          width: '50px',

          paddingLeft: '18px',
        }}
      />
    </div>
  </a>
);
