import { addDays, differenceInDays, format, parseISO } from 'date-fns';
import { Component, createRef } from 'react';
import Chatbot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { ResourcesContext } from '../../context/ResourcesContext';

import { currencyDisplay } from '../../utils/currency';
import ExportV2 from '../../components/ExportV2';
import { flexFormat } from '../../utils/flexFormat';
import vyaApi, { billetApi } from '../../services/api';
import validateId from '../../utils/validateId';
import verifyCpf from '../../utils/verifyCpf';
import moment from 'moment';
import { alterMetaTag } from '../../components/chat/metaTag';
import { theme } from './theme';

import { header } from './Header';
import Ops from '../../components/Ops';
import CustomOptions from '../../components/CustomOptions';
import WaitBillet from './WaitBillet';
import GrupoZelo from '../../lib/GrupoZelo';
import LoaderZelo from './Loader';
import InfoContatoBtn from '../../components/InfoContatoBtn';
import DeParaPhoneService from './PhoneService';
import { saudacao } from '../../components/chat/saudacao';
import { sendPutRequest } from '../../lib/sendPutRequest';
import { sendPatchRequest } from '../../lib/sendPatchRequest';
import { pad } from 'vya.utilities';
export default class GrupoZeloChatbot extends Component {
  static contextType = ResourcesContext;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      loadingMessage: true,
      error: false,
      result: null,
      selectedOptionValue: null,
      typeCpfTries: 0,
      userEmailIsCorrect: true,
      placeholder: 'Digite aqui...',
      closeLoader: false,
    };

    this.sendPutRequest = sendPutRequest(this);
    this.sendPatchRequest = sendPatchRequest(this);
  }

  componentWillMount() {
    alterMetaTag(
      'Grupo Zelo Negociação',
      /\/[0-9]/.exec(this.props.match.path),
      theme.headerBgColor
    );
    this.handleEnd = this.handleEnd.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    //obrigatório
    billetApi.post('/code-start');
    if (!validateId(this.props.match.params.id)) {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage:
          'Identificamos informações incorretas. Tente novamente mais tarde.',
      });
      return;
    }
    let urlParameters = this.props.match.params.id.split('_');
    urlParameters = 'id=' + urlParameters[0] + '&idAcao=' + urlParameters[1];
    let sessionChat =
      this.props.match.params.id + moment().format('YYYYMMDD_HHMMSS');
    this.setState({ sessionChat: sessionChat });
    try {
      const grupoZelo = await GrupoZelo.init();
      const { data } = await vyaApi.get(`Contato?${urlParameters}`);
      // console.log('[resultContato]', data);

      let result = data.Data[0].data[0];
      //console.log('[resultVyaApi]', result);
      const { relatorio } = await grupoZelo.getAssociadoAtrasado({
        incricao: result.inscricao,
        page: 1,
        itemsPerPage: 50,
      });
      //const associacao = await grupoZelo.getAssociado(result.inscricao)
      console.log('[result]', result);
      //const inscricao = await grupoZelo.getInscricao(result.inscricao);
      this.setState({
        result: {
          ...result,
        }
      });
      if (result.hasOwnProperty('Nome') || result.hasOwnProperty('nome')) {
        this.setState({
          result: {
            ...result,
            phoneByDDD: DeParaPhoneService.getPhoneByDDD(
              result.Telefone.slice(0, 2)
            ),
            vencimento_1: result.vencimento_1 || result.vencimento_parcela_1,
            //associacao,
            //inscricao,
            dividaAtiva: relatorio.length > 0,
            emails: result.emails || result.email,
            contratos: result.contratos || result.contrato,
            dueDate: addDays(new Date(), 3),
          },
        });
        this.setState({ isLoading: false, error: false, closeLoader: true });
        this.sendPutRequest({
          id: 'API',
          message: data.Message,
          value: data.Status,
        });
      } else {
        this.setState({ isLoading: false, error: true, closeLoader: true });
        this.sendPutRequest({
          id: 'API',
          message: 'Property Nome not found',
          value: 'error',
        });
      }
    } catch (error) {
      console.log('[Contato-error]', error);
      this.setState({ isLoading: false, error: true, closeLoader: true });
      this.sendPutRequest({
        id: 'API',
        message: 'erro api',
        value: 'error',
      });
    }
  }

  handleEnd({ steps, values }) {
    if (!this.state.stepFinal) {
      return;
    }

    //console.log('[handleEnd]', steps)

    var data = JSON.stringify({
      id: this.state.result.id,
      idAcao: this.state.result.idAcao,
      session: this.state.sessionChat,
      step: steps.agradecimento.id,
      frase: steps.agradecimento.message,
      resposta: '',
      status: this.state.status,
      statusCode: this.state.statusCode,
    });

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi
      .post('/ContatoMidia', data)
      .then(function (response) {
        //console.log('[handleEnd]', JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log('[handleEnd]', error);
      });
  }

  InfoContato = () => (
    <p>
      Para conseguir maiores informações, ligue para:{' '}
      <a href="tel:08007275120" className="link">
        0800 727 5120
      </a>
      , temos algo muito importante para falar com você.
    </p>
  );

  Export = ({ steps }) => (

    < ExportV2
      eventTitle="Pagar Débito Grupo Zelo"
      eventDetailsLabel={`Débito Grupo Zelo no valor de ${currencyDisplay(
        steps.initial.metadata.ValorTotal,
        2
      )}`
      }
      eventDetails={`Débito Grupo Zelo no valor de <strong>${currencyDisplay(
        steps.initial.metadata.ValorTotal,
        2
      )}</strong>`
      }
      eventDay={this.state.dueDate}
      barCode={steps.gerar_boleto.value.barCode || ''}
      handleEnd={(id, message) => {
        let stepFinal = { id, message, value: '' };
        this.setState({ stepFinal });
        this.handleEnd({ steps });
      }}
      onDownloadBillet={() => {
        this.setState({ statusCode: '116', status: 'DBOL' }, () =>
          this.sendPutRequest({ id: 'download_boleto', message: 'baixar', value: 'baixar' })
        );
      }}
      billet={steps.gerar_boleto.value.fileUrl}
      noCalendar
    />

  );

  render() {
    const {
      isLoading,
      result,
      error,
      placeholder,
      errorMessage,
      closeLoader,
    } = this.state;

    const steps = [
      {
        id: 'initial',
        metadata: result,
        message: () => {
          return differenceInDays(
            new Date(),
            parseISO(flexFormat(result.DataReferencia, 'YMD'))
          ) > 9
            ? 'Ops, o prazo para esta negociação expirou.'
            : 'Olá, eu sou o assistente do Grupo Zelo e estou aqui para te passar algumas informações importantes sobre seu plano funerário.';
        },
        trigger: ({ steps }) => {
          this.setState({ statusCode: '80', status: 'HUMA' }, () =>
            this.sendPutRequest(steps.initial)
          );

          return differenceInDays(
            new Date(),
            parseISO(flexFormat(result.DataReferencia, 'YMD'))
          ) > 9
            ? 'info_contato_vencimento'
            : 'solicitar_cpf';
        },
      },
      {
        id: 'solicitar_cpf',
        message: ({ steps }) => {
          const slicedCpf = pad(steps.initial.metadata.cpf, 11, 'left');

          return `Por favor, digite os 3 primeiros dígitos do seu CPF: ${slicedCpf.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            '***.$2.$3-$4'
          )}`;
        },
        trigger: ({ steps }) => {
          this.setState({ typeCpfTries: this.state.typeCpfTries + 1 });
          this.sendPutRequest(steps.solicitar_cpf);
          return 'get_cpf';
        },
      },
      {
        id: 'get_cpf',
        user: true,
        validator: (value) => {
          if (isNaN(value)) {
            return 'Por favor digite apenas número';
          }
          return true;
        },
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '83', status: 'CPFD' }, () =>
            this.sendPutRequest(steps.get_cpf)
          );

          if (verifyCpf(value.slice(0, 3), steps.initial.metadata.cpf)) {
            if (!result.dividaAtiva) {
              return 'divida_paga';
            }
            return 'info_negociacao';
          } else {
            return 'cpf_nao_encontrado';
          }
        },
      },
      {
        id: 'info_negociacao',
        message: () => {
          const date = format(
            parseISO(flexFormat(result.vencimento_1, 'YMD')),
            'MM/yyyy'
          );
          const dueDate = format(result.dueDate, 'dd/MM/yyyy');

          return `Consta uma pendência em nosso sistema da mensalidade ${date} do seu plano funerário. Posso gerar um novo boleto com o vencimento para o dia ${dueDate} no valor de ${currencyDisplay(
            result.ValorTotal,
            2
          )}?`;
        },
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.info_negociacao);
          return 'info_negociacao_options';
        },
      },
      {
        id: 'info_negociacao_options',
        component: (
          <CustomOptions
            options={['sim', 'não', 'já paguei', 'desconheço a dívida']}
            primaryColor={theme.botBubbleColor}
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          //this.sendPutRequest(steps.info_negociacao_options);
          const options = {
            sim: 'confirmar_pagamento',
            não: 'sugerir_pagar',
            'já paguei': 'ja_paguei',
            'desconheço a dívida': 'desconhece_divida',
          };

          if (options[value] == 'ja_paguei') {
            this.setState({ statusCode: '83', status: 'JA_PAGUEI' }, () =>
              this.sendPutRequest(steps.info_negociacao_options)
            );
          }

          if (options[value] == 'desconhece_divida') {
            this.setState({ statusCode: '83', status: 'DESCONHECE_DIVIDA' }, () =>
              this.sendPutRequest(steps.info_negociacao_options)
            );
          }

          if (options[value] == 'sugerir_pagar') {
            this.setState({ statusCode: '83', status: 'RECU' }, () =>
              this.sendPutRequest(steps.info_negociacao_options)
            );
          }

          if (options[value] == 'confirmar_pagamento') {
            this.setState({ statusCode: '83', status: 'ACP!' }, () =>
              this.sendPutRequest(steps.info_negociacao_options)
            );
          }

          return options[value];
        },
      },
      {
        id: 'confirmar_pagamento',
        message: () => {
          const dueDate = format(result.dueDate, 'dd/MM/yyyy');

          return `Você escolheu pagar o boleto de ${currencyDisplay(
            result.ValorTotal,
            2
          )} até o dia ${dueDate}`;
        },
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.confirmar_pagamento);
          return 'gerar_boleto';
        },
      },
      // {
      //   id: 'gerar_boleto',
      //   component: <WaitBillet data={result} />,
      //   replace: true,
      //   waitAction: true,
      //   asMessage: true,
      //   trigger: 'receber_email',

      // },
      {
        id: 'gerar_boleto',
        component: <WaitBillet data={result} />,
        replace: true,
        waitAction: true,
        asMessage: true,
        trigger: ({ _, steps }) => {
          console.log('[gerar_boleto]', steps);
          if (!steps.gerar_boleto.value) {
            return 'erro_boleto';
          } else {
            this.sendPutRequest(steps.gerar_boleto.value.fileUrl);
            return 'receber_email';
          }
        },

      },

      {
        id: 'receber_email',
        message: 'Posso te enviar o boleto por e-mail?',
        delay: 0,
        trigger: ({ _, steps }) => {
          //console.log(steps);
          this.sendPutRequest(steps.receber_email);
          //this.setState({ vencimento: format(result.dueDate, 'yyyy-MM-dd') }, () =>
          // this.sendPatchRequest(steps.receber_email)
          //);

          return 'receber_email_options';
        },
      },
      {
        id: 'receber_email_options',
        component: (
          <CustomOptions
            options={['sim', 'não']}
            primaryColor={theme.botBubbleColor}
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '116', status: 'ACP1' }, () =>
            this.sendPutRequest(steps.receber_email_options)
          );
          //this.sendPutRequest(steps.receber_email_options);
          if (value === 'sim') {
            if (
              steps.initial.metadata['emails'] &&
              this.state.userEmailIsCorrect
            ) {
              return 'confirmar_email';
            }

            return 'perguntar_email';
          } else {
            return 'exportar_boleto';
          }
        },
      },

      {
        id: 'perguntar_email',
        message: 'Qual o seu email?',
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.perguntar_email);

          return 'digitar_email';
        },
      },
      {
        id: 'digitar_email',
        user: true,
        validator: (value) => {
          if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value.trim())) {
            return 'Email inválido!';
          }
          return true;
        },
        trigger: 'confirmar_email',
      },
      {
        id: 'confirmar_email',
        message: ({ previousValue, steps }) => {
          return `O email esta correto?
${!this.state.userEmailIsCorrect
              ? previousValue
              : steps.initial.metadata['emails'] || previousValue
            }`;
        },
        trigger: ({ _, steps }) => {
          this.sendPutRequest(steps.confirmar_email);
          return 'opcoes_confirmar_email';
        },
      },
      {
        id: 'opcoes_confirmar_email',
        component: (
          <CustomOptions
            options={['sim', 'não']}
            primaryColor={theme.botBubbleColor}
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          if (value === 'sim') {
            this.setState({ statusCode: '116', status: 'NEMAIL' }, () =>
              this.sendPutRequest(steps.opcoes_confirmar_email)
            );

            return 'enviar_email';
          } else {
            if (
              steps.initial.metadata['emails'] &&
              this.state.userEmailIsCorrect
            ) {
              this.setState({ userEmailIsCorrect: false });
            }
            return 'perguntar_email';
          }
        },
      },
      {
        id: 'enviar_email',
        message: ({ _, steps }) =>
          `Seu boleto será enviado em até 24 horas para o ${!this.state.userEmailIsCorrect
            ? steps.digitar_email?.value
            : steps.initial.metadata['emails'] || steps.digitar_email?.value
          }`, // o numero tem que ser uma variavel recebida da api aqui ta mosdo de demonstraçao
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '116', status: 'SEMAIL' }, () =>
            this.sendPutRequest(steps.enviar_email)
          );

          const dueDate = format(result.dueDate, 'dd/MM/yyyy');

          billetApi({
            method: 'post',
            url: '/email/send',
            data: {
              sender: {
                name: 'Grupo Zelo',
                logo:
                  'https://i.ibb.co/QM8Xpb6/6c89eadb-ea48-4229-96e1-d339b0b76797-4.png',
              },
              destination: {
                name: result.nome,
                email: !this.state.userEmailIsCorrect
                  ? steps.digitar_email?.value
                  : steps.initial.metadata['emails'] ||
                  steps.digitar_email?.value,
              },
              content: {
                template: 'grupoZelo',
                subject: 'Boleto Negociação Grupo Zelo',
                dueDate: dueDate,
                processDate: format(new Date(), "dd/MM/yyyy 'às' HH:mm'h'"),
                value: currencyDisplay(result.ValorTotal, 2),
                link: steps.gerar_boleto.value.fileUrl,
              },
            },
          }).catch((error) => {
            console.log(error.response.data);
          });

          return 'verificar_spam';
        },
      },
      {
        id: 'erro_boleto',
        message:
          'Ops..não conseguimos gerar o seu boleto! Por favor atualize seus dados em nossa central.',
        trigger: ({ steps }) => {
          console.log('[Export]', steps)
          this.sendPutRequest(steps.erro_boleto);
          return 'contatos';
        },
      },
      {
        id: 'verificar_spam',
        message:
          'Confira na caixa de entrada ou spam. Pague até o vencimento para não perder os descontos!',
        trigger: 'exportar_boleto',
      },
      {
        id: 'exportar_boleto',
        component: <this.Export />,
        trigger: ({ steps }) => {
          console.log('[Export]', steps)
          this.sendPutRequest(steps.exportar_boleto);

          return 'duvidas_geral';
        },
      },
      {
        id: 'duvidas_geral',
        message: 'Em caso de dúvida, entre em contato.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.duvidas_geral);
          return 'contatos';
        },
      },
      {
        id: 'sugerir_pagar',
        message:
          'Entendo. Sugerimos que regularize esse débito o mais rápido possível para não perder os benefícios do seu plano funerário.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.sugerir_pagar);

          return 'duvidas_geral';
        },
      },
      {
        id: 'ja_paguei',
        message:
          'Perfeito! A confirmação do pagamento pode levar até 3 dias úteis.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.ja_paguei);
          return 'duvidas_geral';
        },
      },
      {
        id: 'desconhece_divida',
        message:
          'Consta uma pendência no seu plano funerário do Grupo Zelo. Por favor, fale conosco para regularizar o seu cadastro',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.desconhece_divida);
          return 'contatos';
        },
      },
      {
        id: 'cpf_nao_encontrado',
        message: 'Desculpe, não localizei o seu CPF.',
        trigger: ({ steps }) => {
          this.setState({ statusCode: '79', status: 'NAO' }, () =>
            this.sendPutRequest(steps.cpf_nao_encontrado)
          );

          if (this.state.typeCpfTries > 2) {
            return 'cpf_max_tentativas';
          }
          return 'solicitar_cpf';
        },
      },
      {
        id: 'cpf_max_tentativas',
        message:
          'Ops... Para passar as informações, preciso confirmar o CPF. Como não conseguimos, entre em contato conosco.',
        trigger: ({ steps }) => {
          this.setState({ statusCode: '91', status: 'NCPF' }, () =>
            this.sendPutRequest(steps.cpf_max_tentativas)
          );

          return 'contatos';
        },
      },
      {
        id: 'info_contato_vencimento',
        message:
          'Por favor, entre em contato com nossa central de atendimento para possamos ajudá-lo.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.info_contato_vencimento);

          return 'contatos';
        },
      },
      {
        id: 'divida_paga',
        message:
          'Já consta em nosso sistema que o seu pagamento foi realizado. Em caso de dúvidas, estamos à disposição nos canais atendimento abaixo.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.divida_paga);

          return 'contatos';
        },
      },
      {
        id: 'contatos',
        component: (
          <InfoContatoBtn
            whatsapp="553133148000"
            phone={`55${result?.phoneByDDD}`}
            theme={{
              fontColor: theme.botBubbleColor,
              borderColor: theme.botBubbleColor,
            }}
            convencional
          />
        ),
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.contatos);
          return 'agradecimento';
        },
      },
      {
        id: 'agradecimento',
        message: () => {
          const message = `O Grupo Zelo agradece a sua atenção. Tenha ${new Date().getHours() < 12 ? 'um' : 'uma'
            } ${saudacao().toLocaleLowerCase()}`;
          let stepFinal = { id: 'agradecimento', message: message, value: '' };
          this.setState({ stepFinal });
          return message;
        },
        end: true,
      },
    ];

    const optionStyles = {
      background: 'transparent',
      border: `1.5px solid ${theme.botBubbleColor}`,
      borderRadius: 25,
      color: theme.botBubbleColor,
      cursor: 'pointer',
    };

    return (
      <>
        <LoaderZelo close={closeLoader} />
        <ThemeProvider theme={theme}>
          {!isLoading && !error && (
            <Chatbot
              className="--no-padding-top"
              recognitionEnable={false}
              placeholder={placeholder}
              botDelay={1000}
              floating={false}
              handleEnd={this.handleEnd}
              steps={steps}
              botAvatar="/11/avatar.png"
              headerComponent={header}
              bubbleOptionStyle={optionStyles}
              submitButtonStyle={{ fill: theme.botBubbleColor }}
            />
          )}
          {error && (
            <Ops
              theme={theme}
              headerComponent={header}
              botAvatar="/11/avatar.png"
              message={this.state.errorMessage || null}
            />
          )}
        </ThemeProvider>
      </>
    );
  }
}
