const formatPhone = (phone, convencional) => {
  if (/^0800/.test(phone)) {
    return phone.replace(/^(\d{4})(\d{3})(\d{4})$/, '$1 $2 $3');
  }

  if (phone.length >= 12) {
    return phone.replace(
      /^(\d{2})(\d{2})(\d{5}|\d{4})(\d{4})$/,
      convencional ? '($2) $3-$4' : '+$1 $2 $3-$4'
    );
  }

  return phone.replace(
    /^(\d{2})(\d{5}|\d{4})(\d{4})$/,
    convencional ? '($1) $2-$3' : '$1 $2-$3'
  );
};

export { formatPhone };
