
class DeParaPhoneService {
  static getPhoneByDDD(DDD) {
    const dddPhoneList = {
      '11': '1130903142',
      '28': '2833218270',
      '32': '3237710210',
      '35': '3530420267',
      '38': '3834412747',
      '62': '6231422895',
      '77': '7730868898',
      '27': '2732292496',
      '31': '3133148000',
      '33': '3330381987',
      '37': '3735161122',
      '61': '6136860097',
      '63': '6331420005',
      fallback: '3133148000',
    }
    const phoneNumber = dddPhoneList[DDD];

    if (!phoneNumber) {
      return dddPhoneList['fallback']
    }

    return phoneNumber
  }
}

export default DeParaPhoneService