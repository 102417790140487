import { differenceInDays, parseISO } from 'date-fns';
import moment from 'moment';
import { Component } from 'react';
import Chatbot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { alterMetaTag } from '../../../components/chat/metaTag';
import CustomOptions from '../../../components/CustomOptions';
import InfoContatoBtn from '../../../components/InfoContatoBtn';
import Ops from '../../../components/Ops';
import { ResourcesContext } from '../../../context/ResourcesContext';
import { sendPutRequest } from '../../../lib/sendPutRequest';
import vyaApi, { billetApi } from '../../../services/api';
import { flexFormat } from '../../../utils/flexFormat';
import validateId from '../../../utils/validateId';
import verifyCpf from '../../../utils/verifyCpf';
import { header } from './Header';
import LoaderStocco from './Loader';
import DeParaPhoneService from './PhoneService';
import { theme } from './theme';

export default class StoccoChatbot extends Component {
  static contextType = ResourcesContext;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      loadingMessage: true,
      error: false,
      result: null,
      selectedOptionValue: null,
      typeCpfTries: 0,
      userEmailIsCorrect: true,
      placeholder: 'Digite aqui...',
      closeLoader: false,
    };

    this.sendPutRequest = sendPutRequest(this);
  }

  componentWillMount() {
    alterMetaTag(
      'Stocco Advocacia Negociação',
      /\/[0-9]/.exec(this.props.match.path),
      theme.headerBgColor
    );
    this.handleEnd = this.handleEnd.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    //this.setState({ isLoading: false, error: false, closeLoader: true });
    //obrigatório
    billetApi.post('/code-start');
    if (!validateId(this.props.match.params.id)) {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage:
          'Identificamos informações incorretas. Tente novamente mais tarde.',
      });
      return;
    }
    let urlParameters = this.props.match.params.id.split('_');
    urlParameters = 'id=' + urlParameters[0] + '&idAcao=' + urlParameters[1];
    let sessionChat =
      this.props.match.params.id + moment().format('YYYYMMDD_HHMMSS');
    this.setState({ sessionChat: sessionChat });
    try {
      const { data } = await vyaApi.get(`Contato?${urlParameters}`);

      let result = data.Data[0].data[0];
      console.log('[resultVyaApi]', result);
      //const associacao = await grupoZelo.getAssociado(result.inscricao)

      if (result.hasOwnProperty('Nome') || result.hasOwnProperty('nome')) {
        this.setState({
          result: {
            ...result,
            phoneByDDD: DeParaPhoneService.getPhoneByDDD(
              result.telefone.slice(0, 2)
            ),
            phoneWithSpecialNumber: result.telefone.slice(0, 2) == 11 ? false : true,
            //associacao,
            //inscricao,
            emails: result.emails || result.email,
          },
        });
        this.setState({ isLoading: false, error: false, closeLoader: true });
        this.sendPutRequest({
          id: 'API',
          message: data.Message,
          value: data.Status,
        });
      } else {
        this.setState({ isLoading: false, error: true, closeLoader: true });
        this.sendPutRequest({
          id: 'API',
          message: 'erro api',
          value: 'error',
        });
      }
    } catch (error) {
      console.log('[Contato-error]', error);
      this.setState({ isLoading: false, error: true, closeLoader: true });
      this.sendPutRequest({
        id: 'API',
        message: 'erro api',
        value: 'error',
      });
    }
  }

  handleEnd({ steps, values }) {
    if (!this.state.stepFinal) {
      return;
    }

    var data = JSON.stringify({
      id: this.state.result.id,
      idAcao: this.state.result.idAcao,
      session: this.state.sessionChat,
      step: steps.agradecimento.id,
      frase: steps.agradecimento.message,
      resposta: '',
      status: this.state.status,
      statusCode: this.state.statusCode,
    });

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi
      .post('/ContatoMidia', data)
      .then(function (response) {
        console.log(
          '[sendPutRequest-' + steps.id + ']',
          JSON.stringify(response.data)
        );
      })
      .catch(function (error) {
        console.log('[sendPutRequest-' + steps.id + ']', error);
      });
  }

  InfoContato = () => (
    <p>
      Para conseguir maiores informações, ligue para:{' '}
      <a href="tel:5511988222047" className="link">
        (11) 98822-2047
      </a>
      , temos algo muito importante para falar com você.
    </p>
  );

  render() {
    const {
      isLoading,
      result,
      error,
      placeholder,
      errorMessage,
      closeLoader,
    } = this.state;

    const steps = [
      {
        id: 'initial',
        metadata: result,
        message:
          'Olá! Sou assistente virtual da Stocco Advogados, representante do Banco Santander. Estamos com o contrato em aberto e tenho uma pergunta para você.',
        trigger: ({ steps }) => {
          this.setState({ statusCode: '80', status: 'HUMA' }, () =>
            this.sendPutRequest(steps.initial)
          );

          return differenceInDays(
            new Date(),
            parseISO(flexFormat(result.DataReferencia, 'YMD'))
          ) > 9
            ? 'info_contato_vencimento'
            : 'solicitar_cpf';
        },
      },
      {
        id: 'info_contato_vencimento',
        message:
          'Entre em contato conosco:',
        trigger: ({ steps }) => {
          this.setState({ statusCode: 'ENTR', status: 'ENTR' }, () =>
            this.sendPutRequest(steps.info_contato_vencimento)
          );
          return 'contatos';
        },
      },
      {
        id: 'solicitar_cpf',
        message:
          'Mas antes, para sua segurança, me confirme os 3 primeiros dígitos do seu CPF.',
        trigger: ({ steps }) => {
          this.setState({ typeCpfTries: this.state.typeCpfTries + 1 });
          this.sendPutRequest(steps.solicitar_cpf);
          return 'get_cpf';
        },
      },
      {
        id: 'get_cpf',
        user: true,
        validator: (value) => {
          if (isNaN(value)) {
            return 'Por favor digite apenas número';
          }
          return true;
        },
        trigger: ({ value, steps }) => {
          this.setState({ statusCode: '83', status: 'CPFD' }, () =>
            this.sendPutRequest(steps.get_cpf)
          );

          if (verifyCpf(value.slice(0, 3), steps.initial.metadata.cpfcnpj)) {
            return 'info_opcoes';
          } else {
            return 'cpf_nao_encontrado';
          }
        },
      },
      {
        id: 'info_opcoes',
        message: 'Qual dessas opções te ajudaria mais com seu veiculo hoje?',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.info_opcoes);
          return 'info_opcoes_options';
        },
      },
      {
        id: 'info_opcoes_options',
        component: (
          <CustomOptions
            options={[
              'Devolução amigável do veículo',
              'Renegociação do contrato',
              'Acordo das parcelas',
              'Outros assuntos',
            ]}
            primaryColor={theme.botBubbleColor}
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.info_opcoes_options);
          // const options = {
          //   'Devolução amigável do veículo': 'confirmar_pagamento',
          //   'Renegociação do contrato': 'sugerir_pagar',
          //   'Acordo das parcelas': 'ja_paguei',
          //   'Outros assuntos': 'desconhece_divida',
          // };

          return 'selecionar_canal';
        },
      },
      {
        id: 'selecionar_canal',
        message:
          'Ótimo! Por qual canal você deseja receber mais informações sobre esta opção?',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.selecionar_canal);
          return 'selecionar_canal_opcoes';
        },
      },
      {
        id: 'selecionar_canal_opcoes',
        component: (
          <CustomOptions
            options={['Whatsapp', 'Telefone']}
            primaryColor={theme.botBubbleColor}
          />
        ),
        waitAction: true,
        trigger: ({ value, steps }) => {
          this.sendPutRequest(steps.selecionar_canal_opcoes);
          const options = {
            Whatsapp: 'canal_whatsapp',
            Telefone: 'canal_telefone',
          };

          return options[value];
        },
      },
      {
        id: 'canal_whatsapp',
        message:
          'Ok. Vamos entrar em contato no seu número de WhatsApp em breve! Caso queira entrar em contato agora, click no item abaixo.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.canal_whatsapp);

          return 'whatsapp';
        },
      },
      {
        id: 'canal_telefone',
        message:
          'Ok. Vamos te ligar em breve! Caso queira falar agora conosco, estamos a sua disposição no telefone abaixo.',
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.canal_telefone);

          return 'telefone';
        },
      },
      {
        id: 'cpf_nao_encontrado',
        message: 'Desculpe, não localizei o seu CPF.',
        trigger: ({ steps }) => {
          this.setState({ statusCode: '79', status: 'NAO' }, () =>
            this.sendPutRequest(steps.cpf_nao_encontrado)
          );

          if (this.state.typeCpfTries > 2) {
            return 'cpf_max_tentativas';
          }
          return 'solicitar_cpf';
        },
      },
      {
        id: 'cpf_max_tentativas',
        message:
          'Infelizmente não consegui validar o seu CPF, mas você pode entrar em contato conosco para maiores informações através do canais abaixo.',
        trigger: ({ steps }) => {
          this.setState({ statusCode: '91', status: 'NCPF' }, () =>
            this.sendPutRequest(steps.cpf_max_tentativas)
          );

          return 'contatos';
        },
      },
      {
        id: 'whatsapp',
        component: (
          <InfoContatoBtn
            whatsapp="11988222047"
            defaultWpMessage={`Sou cliente Santander e me chamo ${result?.nome}. Preciso entender melhor as informações que o bot passou.`}
            theme={{
              fontColor: theme.botBubbleColor,
              borderColor: theme.botBubbleColor,
            }}
            convencional
          />
        ),
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.whatsapp);
          return 'agradecimento';
        },
      },
      {
        id: 'telefone',
        component: (

          <InfoContatoBtn
            phone={result?.phoneWithSpecialNumber ? result?.phoneByDDD : `55${result?.phoneByDDD}`}
            theme={{
              fontColor: theme.botBubbleColor,
              borderColor: theme.botBubbleColor,
            }}
            convencional
          />
        ),
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.telefone);
          return 'agradecimento';
        },
      },
      {
        id: 'contatos',
        component: (
          <InfoContatoBtn
            whatsapp="11988222047"
            defaultWpMessage={`Sou cliente Santander e me chamo ${result?.nome}. Preciso entender melhor as informações que o bot passou.`}
            phone={result?.phoneByDDD}
            theme={{
              fontColor: theme.botBubbleColor,
              borderColor: theme.botBubbleColor,
            }}
            convencional
          />
        ),
        trigger: ({ steps }) => {
          this.sendPutRequest(steps.contatos);
          return 'agradecimento';
        },
      },
      {
        id: 'agradecimento',
        message: () => {
          const message = `A Stocco Advogados agradece sua atenção!`;
          let stepFinal = { id: 'agradecimento', message: message, value: '' };
          this.setState({ stepFinal });
          return message;
        },
        end: true,
      },
    ];

    const optionStyles = {
      background: 'transparent',
      border: `1.5px solid ${theme.botBubbleColor}`,
      borderRadius: 25,
      color: theme.botBubbleColor,
      cursor: 'pointer',
    };

    return (
      <>
        <LoaderStocco close={closeLoader} />
        <ThemeProvider theme={theme}>
          {!isLoading && !error && (
            <Chatbot
              className="--no-padding-top stocco"
              recognitionEnable={false}
              placeholder={placeholder}
              botDelay={1000}
              floating={false}
              handleEnd={this.handleEnd}
              steps={steps}
              botAvatar="/14/avatar.svg"
              headerComponent={header}
              bubbleOptionStyle={optionStyles}
              submitButtonStyle={{ fill: theme.botBubbleColor }}
            />
          )}
          {error && (
            <Ops
              theme={theme}
              headerComponent={header}
              botAvatar="/14/avatar.svg"
              message={this.state.errorMessage || null}
            />
          )}
        </ThemeProvider>
      </>
    );
  }
}
