import vyaApi from '../services/api';

export function sendPutRequest(_this) {
  const bindedFunc = async function (param) {
    //console.log('[sendPutRequest]', param)
    const data = JSON.stringify({
      id: this.state.result?.id || param.idUser,
      idAcao: this.state.result?.idAcao || param.idAcao,
      session: this.state.sessionChat,
      step: param.id,
      frase: param.message,
      resposta: param.value,
      status: this.state.status || '',
      statusCode: this.state.statusCode || '',
    });

    vyaApi.defaults.headers.put['Content-Type'] = 'application/json';
    vyaApi.post('/ContatoMidia', data);
  }.bind(_this);

  return bindedFunc;
}
