export const header = (
  <a
    href="https://auth.netcombo.com.br/web/index.html"
    target="_blank"
    rel="noreferrer noopened"
  >
    <div
      className="sc-iqHYGH ieNPiA rsc-header"
      style={{
        background: '#e3262e',
        height: '53px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <img
        src="/5/logo.svg"
        alt="SKY"
        style={{
          width: '80px',

          paddingLeft: '18px',
          paddingTop: '4px',
        }}
      />
    </div>
  </a>
);
