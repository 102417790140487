import vyaApi from '../services/api';
import { format } from 'date-fns';

export function sendPatchRequest(_this) {
  const bindedFunc = async function (param) {
    //console.log('[sendPatchRequest]', param)
    //console.log('[sendPatchRequest-state]', this.state.result)
    const data = JSON.stringify({
      codigo_contato: this.state.result?.id || param.idUser,
      codigo_acao: this.state.result?.idAcao || param.idAcao,
      vencimento: this.state.result?.dueDate ? format(this.state.result?.dueDate, 'yyyy-MM-dd') : null,
    });
    //console.log('[sendPatchRequest-data]', data)
    const credentials = btoa("journey@vya.digital" + ':' + "DG#glob*@");
    vyaApi.defaults.headers.patch['Content-Type'] = 'application/json';
    vyaApi.defaults.headers.patch['Authorization'] = `Basic ${credentials}`;
    vyaApi.patch('/Contato', data);
  }.bind(_this);

  return bindedFunc;
}
