export const theme = {
  /* background: '#f5f8fb', */
  fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
  headerBgColor: '#e52645',
  headerFontColor: '#fff',
  headerFontSize: '21px',
  botBubbleColor: '#E1173F',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};
