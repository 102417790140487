import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { saudacao } from './saudacao';
import { prazo5dias, prazo7dias} from './prazoPagamento'
import headerImg from './chat_VIVO.png'

// alert('Por questão de seguraça e privacidade esse atendimento tem um prazo de sessão de 5 minutos')
// function session(){
  
//   return setTimeout(() => {
//     alert(`Atendimento finalizado caso deseja recomeçar basta atualizar a pagina 
//     ou entre em contato no 4003-3356 para capitais e regiões metropolitanas.
//     Para demais localidades 0800 723 2960.
//     Nosso horário de atendimento é de segunda a sexta das 7h às 20h e aos sábados das 9h às 15h20.`)
//   }, 15000); //DEFINIR TEMPO
// }

// session()
/* import logo from '../../../logo_branco.png'; */

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      gender: '',
      age: '',
      number: '',
    };
  }

  componentWillMount() {
    const { steps } = this.props;
    const { name, gender, age, number } = steps;

    this.setState({ name, gender, age });

  }



  render() {
    //const { name, gender, age } = this.state;
    return (
      <div style={{ width: '100%' }}>
      <h3></h3>
      <table>
        <tbody>
          <tr>
            <td>Telefone VIVO para Clientes</td>
          </tr>
          
          <tr>
            <td><a href="tel:10611" style={{color: '#fff', textDecoration:'none'}}><strong>10611</strong></a></td>
          </tr>
        </tbody>
      </table>
    </div>
    );
  }
}

Review.propTypes = {
  steps: PropTypes.object,
};

Review.defaultProps = {
  steps: undefined,
};

class ReviewUnknownDebt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      gender: '',
      age: '',
      number: '',
    };
  }

  componentWillMount() {
    const { steps } = this.props;
    const { name, gender, age, number } = steps;

    this.setState({ name, gender, age });

  }



  render() {
    //const { name, gender, age } = this.state;
    return (
        <div style={{ width: '100%' }}>
        <h3></h3>
        <table>
          <tbody>
            <tr>
              <td>Telefone VIVO para Clientes</td>
            </tr>
            
            <tr>
              <td><a href="tel:10611" style={{color: '#fff', textDecoration:'none'}}><strong>10611</strong></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

ReviewUnknownDebt.propTypes = {
  steps: PropTypes.object,
};

ReviewUnknownDebt.defaultProps = {
  steps: undefined,
};

class VIVONegociacao extends Component {

    componentWillMount() {
        this.handleEnd = this.handleEnd.bind(this);
      }
    
    handleEnd({ steps, values }) {
    console.log(steps);
    console.log(values);

    //alert(`Finalizamos seu atendimento`);
    }
  
  render() {
      // all available props
    const theme = {
        /* background: '#f5f8fb', */
        fontFamily: 'Gilroy, Arial, Helvetica, sans-serif',
        headerBgColor: '#660099',
        headerFontColor: '#fff',
        headerFontSize: '21px',
        botBubbleColor: '#660099',
        botFontColor: '#fff',
        userBubbleColor: '#fff',
        userFontColor: '#8f8e91',
    };

    

    const steps = [
        {
            id: 'initial',
            message: `${saudacao()}`,
            metadata: {name:"Vanderson",cpf:"123",valor_divida:"R$ 149,43"},
            delay: 350,
            trigger: '0',
          },
      {
        id: '0',
        message: ({ previousValue, steps }) => {
            //console.log('[steps]',steps)
            //console.log('[previousValue]',previousValue)
            return 'Olá '+steps.initial.metadata.name+' meu nome é Andreina agente virtual da VIVO.'
            
        },
        delay: 350,
        trigger: ({ value, steps }) => {
            //console.log('[steps]',steps)
            //console.log('[value]',value)
            return '1'},
      },
      {
        id: '1',
        message: 'Preciso conversar com você sobre um assunto importante, mas antes de mais nada eu quero agradecer por ser cliente da VIVO',
        trigger: '2',
        delay: 350,
      },
      {
        id: '2',
        message: 'Para que possamos garantir o sigilo de suas informações, por favor digite os 3 primeiros digitos do seu CPF.',
        trigger: 'get_cpf',
        
      },
      {
        id: 'get_cpf',
        user: true,
        validator: (value) => {
            
            console.log('[value]',value)
            if (isNaN(value)) {
                return 'Por favor digite apenas número';
            } 
            
            return true;
        },
        trigger: 'validate_cpf',
        
    },
    {
        id: 'validate_cpf',
        message: ({ previousValue, steps }) => {
            //console.log('[steps]',steps)
            //console.log('[previousValue]',previousValue)
            if (previousValue === steps.initial.metadata.cpf){
                return 'Obrigado pela confirmação dos dados, '+steps.initial.metadata.name+' identificamos que existem débitos pendentes em seu nome em nosso sistema.' 
            }else{
                return 'Ops! CPF incorreto vamos tentar novamente.'
            }
            
        },
        delay: 350,
        trigger: ({ value, steps }) => {
            //console.log('[steps]',steps)
            //console.log('[value]',value)
           
            if (steps.get_cpf.value === steps.initial.metadata.cpf){
                return 'debitos_pendentes'
            }else{
                return '2'
            }
        }
      },

      {
        id: 'debitos_pendentes',
        options: [
          { value: 'SIM', label: 'Não paguei', trigger: 'pagamento_1' },
          { value: 'JAPAGOU', label: 'Já paguei', trigger: 'end-paid-message' },
          { value: 'DESC', label: 'Desconheço esta dívida', trigger: 'end-unknown-debt' },
        ],
      },
      {
        id: 'pagamento_1',
        message: 'Nós podemos agendar este pagamento para hoje ou amanhã?',
        trigger: 'pagamento_1_sim_nao',
      },
      {
        id: 'pagamento_1_sim_nao',
        options: [
          { value: 'sim', label: 'Sim', trigger: 'end-message-1' },
          { value: 'não', label: 'Não', trigger: 'pagamento_formas' },
        ],
      },

      {
        id: 'pagamento_formas',
        message: 'Entendi. Qual dessas duas opçoes fica melhor para voce?',
        trigger: 'pagamento_formas_sim_nao',
      },
      {
        id: 'pagamento_formas_sim_nao',
        options: [
          { value: 'sim', label: `${prazo5dias}`, trigger: 'end-message-5' },
          { value: 'não', label: `${prazo7dias}`, trigger: 'end-message-7' },
          { value: 'end-nodeal2', label: 'Nenhuma destas', trigger: 'end-no-deal2' },
        ],
      },


      {
        id: 'end-unknown-debt',
        message: 'Por gentileza, entre em contato com nossa central para maiores informações.',
        trigger: 'review-unknown-debt',
      },
      {
        id: 'review-unknown-debt',
        component: <ReviewUnknownDebt />,
        asMessage: true,
        trigger: 'end-message-simple',
        
      },

      {
        id: 'end-no-deal',
        message: 'Como não conseguimos fechar um acordo, por favor, entre em contato com a nossa central de atendimento nos telefones.',
        trigger: 'review'
        
      },
      {
        id: 'end-no-deal2',
        message: 'Como não conseguimos fechar um acordo, por favor, entre em contato com a nossa central de atendimento nos telefones.',
        trigger: 'review'
        
      },
      
      {
        id: 'review',
        component: <Review />,
        asMessage: true,
        trigger: 'end_remember',
        delay: 3300
      },

      {
        id: 'end_remember',
        message: 'Lembrando que os juros e encargos são diários e seu nome poderá ser incluso nos orgãos de proteção ao crédito. A VIVO agradece sua atenção!',
        end: true,
      },

      {
        id: 'end-paid-message',
        message: 'Ótimo! A confirmação do pagamento é registrada em nosso sistema em até 48 horas.',
        trigger: 'end-message',
      },
      {
        id: 'type_boleto',  
        options: [
          { value: 'sms', label: 'SMS', trigger: 'number-confirm' },
          { value: 'email', label: 'E-mail', trigger: 'email-confirm' },
          { value: 'gerarBoleto', label: 'Gerar Boleto (PDF)', trigger: 'gerar-boleto' },
        ],
      },
      {
        id: 'gerar-boleto',
        message: 'Em instantes iniciara um donwload do seu boleto',  
        trigger: 'end-message-simple',
      },
      {
        id: 'email-confirm',
        message: 'Qual o seu email?',
        trigger: 'options-email-confirm',
      },
      {
        id: 'options-email-confirm',  
        options: [
          { value: 'editar-email', label: 'Digitar o seu e-mail', trigger: 'email_edit' },
          { value: 'voltar-menu-anterior', label: 'Voltar ao menu anterior', trigger: 'type_boleto' },
        ],
      },
       {
        id: 'email_edit',
        user: true,
        message: ({ previousValue, steps }) => {
          let regex = previousValue
          console.log('[regex]', regex)
          console.log('[previus]', previousValue)
          if (regex.match(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/) == false) {
            // O email não foi validado.
            return 'Este não é um numero valido'
           } else {
           // Email válido.
           return 'Execelente so um momento.'
          }   

        },
        trigger: 'email-confirm-user'
      },
      {
        id: 'email-confirm-user',
        message: ({ previousValue, steps }) => {
          console.log('[previousValue]',previousValue)

          return `O email esta correto? ${previousValue}`
         },
        trigger: 'options-email-confirm2'
      },
      {
        id: 'options-email-confirm2',  
        options: [
          { value: 'edit', label: 'Sim', trigger: 'confirm-email-ok' },
          { value: 'não', label: 'Não', trigger: 'options-email-confirm' },
        ],
      },
      {
        id: 'confirm-email-ok',
        message: ({ previousValue, steps }) => {
          console.log('email?',steps)
        return `Pronto foi enviado no E-mail ${steps.email_edit.value} um email contendo o boleto para o pagamento do seu debito, confira sua caixa de email ou na caixa de span, apenas lembrando se ouver juros vira na proxima fatura` // o numero tem que ser uma variavel recebida da api aqui ta mosdo de demonstraçao
        },
        trigger: 'end-message',
      },
      {
        id: 'number-confirm',
        message: 'Posso enviar neste numero mesmo que você recebeu o link para este chat?',
        trigger: 'options-number-confirm'
      },
      {
        id: 'options-number-confirm',  
        options: [
          { value: 'sim', label: 'Sim', trigger: 'confirm-number-ok' },
          { value: 'não', label: 'Não', trigger: 'no-confirm-number' },
        ],
      },
      {
        id: 'no-confirm-number',
        message: 'Por favor digite um numero com DDD que você gostaria de enviar o SMS com o boleto',
        trigger: 'edit_number'
      },
      {
        id: 'edit_number',
        user: true,
        validator: (value) => {
            
            console.log('[value]',value)
            if (isNaN(value)) {
                return 'Por favor digite apenas número';
            } 
            return true;  
        },
        trigger: 'validator_number'
      },
      {
        id: 'validator_number',
          message: ({ previousValue, steps }) => {
              // console.log('[steps]',steps)
              // console.log('[previousValue]',previousValue)
              
                let regex = previousValue;

                if (regex.match(/^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/) == false) {
               // O número não foi validado.
               return 'Este não é um numero valido'
              } else {
              // Telefone válido.
              return 'Execelente so um momento.'
             }   
      },
      delay: 350,
          trigger: 'number-confirm-user'
        },
      {
          id: 'number-confirm-user',
          message: ({ previousValue, steps }) => {
            // console.log(previousValue)
            // console.log(steps)
            return `Este numero esta correto? ${steps.edit_number.value}`
           },
          trigger: 'options-number-confirm'
        },
      {
        id: 'confirm-number-ok',
        message: ({ previousValue, steps }) => {
        return `Pronto foi enviado no telefone ${steps.edit_number.value} um sms contendo o boleto para o pagamento do seu debito, apenas lembrando se ouver juros vira na proxima fatura` // o numero tem que ser uma variavel recebida da api aqui ta mosdo de demonstraçao
        },
        trigger: 'end-message',
      },
      {
        id: 'end-message',
        message: 'A VIVO agradece sua atenção.',
        end: true,
      },
      {
        id: 'end-message-simple',
        message: 'A VIVO agradece sua atenção.',
        end: true,
        delay: 1800
      },
      {
        id: 'end-error-message',
        message: 'Desculpe! VIVO agradece sua atenção!',
        end: true,
      },
      {
        id: 'end-message-1',
        message: 'Por gentileza, antes do pagamento retira um extrato de sua conta corrente para verificar o valor atualizado de sua pendência. Agradecemos o compromisso firmado aqui hoje comigo com VIVO e somente para informá-lo o agendamento da promessa não inibi a notificação nos órgãos de proteção ao crédito.',
        trigger: 'type_boleto',
      },
      {
        id: 'end-message-5',
        message: 'Por gentileza, antes do pagamento retirar um extrato de sua conta corrente para verificar o valor atualizado de sua pendência. Agradecemos o compromisso firmado com a VIVO e somente para informá-lo o agendamento da promessa não inibi a notificação nos órgãos de proteção ao crédito.',
        trigger: 'type_boleto',
      },
      {
        id: 'end-message-7',
        message: 'Por gentileza, antes do pagamento retirar um extrato de sua conta corrente para verificar o valor atualizado de sua pendência. Agradecemos o compromisso firmado com a VIVO e somente para informá-lo o agendamento da promessa não inibi a notificação nos órgãos de proteção ao crédito.',
        trigger: 'type_boleto',
      },
    ];
    
    return (
      <ThemeProvider theme={theme}> 
        <ChatBot
           headerComponent={
                <div class="sc-iqHYGH ieNPiA rsc-header">
                    <img class="sc-crrsfI lhHHeu rsc-header-title" src={headerImg} alt="VIVO" style={{width:"350px", height:"63px"}}/> 
                </div>
            }  
            // Avatar do Bot e do usuário //
            botAvatar='/avatar_VIVO.png'
            // userAvatar='https://i.imgur.com/6sm2DYv.png'

            handleEnd={this.handleEnd}
            recognitionEnable={false}
            recognitionPlaceholder="Escutando ..."
            placeholder="digite aqui..."    
            botDelay={500}
            headerTitle=""
            floating={false}
            steps={steps}

            // Estilo do Rodapé (Footer) //
            /* footerStyle={{ backgroundColor: '#fff', margin: 5, padding: 1, borderRadius: 7, elevation: 2, }}
            submitButtonStyle={{ backgroundColor: '#E89984', borderRadius: 4, width: 63, margin: 2 }} */
        />
      </ThemeProvider> 
    );
  }
}

export default VIVONegociacao;