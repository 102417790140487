export function whatIsThisNumbers(numbers) {
  const cpfPattern = /^((\d{3}).(\d{3}).(\d{3})-(\d{2}))*$/g;
  const cnpjPattern = /^((\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2}))*$/g;
  // const rgPattern = /(^(\d{2}\x2E\d{3}\x2E\d{3}[-]\d{1})$|^(\d{2}\x2E\d{3}\x2E\d{3})$)/g;
  // const cepPattern = /^\d{5}-\d{3}$/g;
  const noMask = numbers.replace(/\s|\.|-|\/|\\/g, '');

  if (Number.isNaN(+noMask)) {
    return 'NaN';
  }

  if (cpfPattern.test(numbers) || noMask.length <= 11) {
    return 'CPF';
  }

  if (cnpjPattern.test(numbers) || noMask.length === 14) {
    return 'CNPJ';
  }
}
