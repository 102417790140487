import styled from 'styled-components';

export const Container = styled.ul`
  width: 100%;

  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-top: 5px;

  li {
    min-width: 65%;
    width: 65%;
    list-style: none;
    margin-bottom: 10px;
    border: 2px solid  ${props => props.theme.borderColor};
    border-radius:25px;
    display: flex;
    padding: 5px;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: ${props => props.theme.fontColor};

      img {
        width: 30px;
        margin-right: 5px;
      }

      svg {
        margin-right: 5px;
        fill: ${props => props.theme.fontColor}
      }


      
    }

    &:hover {
      background-color: ${props => `${props.theme.fontColor}33`};
    }


    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
