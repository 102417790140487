export const header = (
  <a href="https://www.itau.com.br" target="_blank" rel="noreferrer noopened">
    <div
      className="sc-iqHYGH ieNPiA rsc-header"
      style={{
        background: '#F27405',
        height: '53px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <img
        src="/12/logo.png"
        alt="SKY"
        style={{
          width: '40px',

          paddingLeft: '18px',
        }}
      />
    </div>
  </a>
);
