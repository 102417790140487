import React, { Component } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';

import 'react-nice-dates/build/style.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import './App.css';
import { ResourcesProvider } from './context/ResourcesContext';

// Import Routes
import publicRoutes from './routes/';
import AppRoute from './routes/route';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  render() {
    return (
      <div className="app">
        <ResourcesProvider>
          <Router>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  component={route.component}
                  key={idx}
                  exact={!!route.exact}
                />
              ))}
            </Switch>
          </Router>
        </ResourcesProvider>
      </div>
    );
  }
}

export default App;
