import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  ul {
    background-color: #fff;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #ededed;

    box-shadow: 0 0 10px 0px #00000010;
    list-style: none;

    color: #333;

    li {
      width: 100%;
      display: flex;
      
      &:first-child {
        .icon {
          border-radius: 5px 0 0 0;
        }
      }
      &:last-child {
        .icon {
          border-radius: 0 0 0 5px;
        }
      }
     
      .icon {
        background: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        padding: 1rem;
      }
      .text {
        display: flex;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        justify-content: center;
        svg {
          margin-right: 5px;
        }
        h3 {
          font-size: 14px;
          color: #333;
          font-weight: 500
        }
      }

      a {
        text-decoration: none;
        font-weight: 300;
        font-size: 16px;
        color: #333;
        text-decoration: underline;
        font-size: 14px;
      }
    }
    }
  }
`;
