import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 2rem;

  label {
    border: 2px dashed #d3d3d3;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    &.has-thumbnail {
      border: none;
      svg {
        display: none;
      }
    }
  }

  input {
    display: none;
  }
`;
