import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  section {
    background-color: #fff;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #ededed;

    box-shadow: 0 0 10px 0px #00000010;

    padding: 10px;
    color: #333;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .row {
      display: flex;
      margin-bottom: 10px;
      flex-wrap: wrap;
    }

    button,
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      border-radius: 25px;
      margin-right: 10px;
      margin-bottom: 10px;
      color: #333;
      font-weight: 700;
      border: 1px solid #ededed;
      background-color: #fff;
      box-shadow: 0 0 10px 0px #00000010;
      transition: all 0.3s;
      font-size: 13px;

      &:hover {
        filter: brightness(0.9);
      }

      img {
        width: 20px;
        margin-right: 5px;
      }

      svg {
        margin-right: 5px;
      }

      cursor: pointer;
      transition: all 0.3s;

      &:last-child {
        margin-right: 0;
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;

      .calendar {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid #ededed;
        padding: 5px 10px;
        position: absolute;
        right: 20px;

        align-items: center;

        span {
          font-weight: 700;
          font-size: 12px;

          &.red {
            font-size: 16px;
            color: #ff5959;
          }
        }
      }

      h6 {
        font-size: 16px;
        font-weight: 500;
        color: #4f4f4f;
      }

      .icon-group {
        display: flex;
        padding-top: 10px;

        svg {
          margin-right: 5px;
        }
        span,
        p {
          font-weight: 300;
          color: #808080;
          font-size: 12px;
        }
      }
    }
  }
`;
