import axios from 'axios';
import { addDays, format, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import Chatbot, { Loading } from 'react-simple-chatbot';
import GrupoZelo from '../../lib/GrupoZelo';
import { billetApi } from '../../services/api';
import { flexFormat } from '../../utils/flexFormat';

// import { Container } from './styles';

function WaitBillet({ data, triggerNextStep, step }) {
  const handleProcessBillet = async () => {
    try {
      const grupoZelo = await GrupoZelo.init();
      const dueDate = addDays(new Date(), 3);

      const associacao = await grupoZelo.getAssociado(data.inscricao);
      // console.log('[associacao]', associacao);
      const [firstName, ...lastName] = associacao.Nome.split(' ');

      // const locationInfo = await grupoZelo.getCityByCode(data.cidade);


      const { barCode, fileUrl } = await grupoZelo.generateBillet(
        {
          paymentData: {
            paymentMethod: {
              type: 'boletobancario',
            },
            billingAddress: {
              street: associacao.Residencia,
              houseNumberOrName: associacao.AreaResid,
              city: associacao.Cidade,
              postalCode: associacao.CEPResid || associacao.CepCob,
              stateOrProvince: data.UF,
              country: 'BR',
            },
            shopperName: {
              firstName,
              lastName: lastName.join(' '),
            },
            socialSecurityNumber: data.cpf,
            clientStateDataIndicator: true,
          },
          reference: format(dueDate, 'MM/yy'),
          inscricao: data.inscricao,
          deliveryDate: dueDate,
          value: data.ValorTotal.replace(',', '').replace('.', ''),
        }
      );

      step.value = { barCode, fileUrl };
    } catch (error) {
      console.log('[WaitBillet]', error);
    }
    triggerNextStep();
  };

  useEffect(() => handleProcessBillet());

  return <Loading />;
}

export default WaitBillet;
