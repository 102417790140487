const saudacao = function saudacao() {
    let agora = new Date()
    let hora = agora.getHours()
    console.log(agora)
            
        if (hora <= 12) {
        return 'Bom dia!'
        } else if (hora <= 18) {
            return 'Boa Tarde!'
        } else if (hora <= 23) {
            return'Boa Noite!'
        }
    };


export {saudacao}