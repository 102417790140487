import { addDays } from 'date-fns';
import React, { useEffect } from 'react';
import { Loading } from 'react-simple-chatbot';
import { cslogApi } from '../../../services/api';
import { flexFormat } from '../../../utils/flexFormat';

// import { Container } from './styles';

function WaitEmail({ data, triggerNextStep, step, steps }) {
  const handleProcessBillet = async () => {
    try {
      const email = steps.digitar_email?.value || data.email;

      const opt = data.infoAdicional.find(
        (opt) => opt.idBoleto === steps.pagamento_opcoes.value
      );

      await cslogApi.post('/cslog/agreements', {
        idContract: data.id_contr,
        dueDate: flexFormat(opt.venc, 'YMD'),
        installment: +opt.qtdeParc,
        overdueInstallmentQty: opt.contratosOferta.length,
        typeSendingBillet: 'EMAIL',
        sendTo: email,
      });
    } catch (error) {
      console.log(error);
    }
    triggerNextStep();
  };

  useEffect(() => handleProcessBillet());

  return <Loading />;
}

export default WaitEmail;
