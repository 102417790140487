export const header = (
  <a
    href="https://www.credsystem.com.br/"
    target="_blank"
    rel="noreferrer noopened"
  >
    <div
      className="sc-iqHYGH ieNPiA rsc-header"
      style={{
        background: 'rgba(45, 43, 48, 0.9)',
        height: '53px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <img
        src="/2/logo.png"
        alt="Credsystem"
        style={{
          width: '125px',

          paddingLeft: '18px',
        }}
      />
    </div>
  </a>
);
