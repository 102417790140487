import React from 'react';
import { FiClock, FiFile } from 'react-icons/fi';
import { FcCalendar, FcDownload } from 'react-icons/fc';

import { Container } from './styles';

import GoogleCalendar from '../../assets/images/a.svg';
import Copy from '../../assets/images/copy.png';
import Share from '../../assets/images/share.png';
import transferTextToClipboard from '../../utils/transferTextToClipboard';
import openLinkInNewTab from '../../utils/openLinkInNewTab';
import { addHours, format, startOfDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';

function ExportV2({
  eventDay = new Date(),
  eventStart,
  eventEnd,
  eventTitle,
  eventLocation,
  eventDetails,
  eventDetailsLabel,
  barCode,
  billet,
  handleEnd,
  onDownloadBillet,
  copy,
  noCalendar,
}) {
  const eventDetailsWithBarcode = `${eventDetails}
Código de barras: <strong>${barCode}</strong>
  `;
  const eventDetailsLabelWithBarcode = `${eventDetailsLabel}. Código de barras: ${barCode}
  `;
  let sDate = addHours(startOfDay(eventDay), 10);
  let eDate = addHours(sDate, 1);

  sDate = sDate.toISOString().replace(/-|\.[0-9]{3}|:/g, '');
  eDate = eDate.toISOString().replace(/-|\.[0-9]{3}|:/g, '');

  let ics = '';

  ics += 'BEGIN:VCALENDAR';
  ics += '%0AVERSION:2.0';
  ics += '%0ABEGIN:VEVENT';
  ics += '%0ADTSTART:' + sDate;
  ics += '%0ADTEND:' + eDate;
  ics += '%0ASUMMARY:' + eventTitle;
  ics += '%0ADESCRIPTION:' + eventDetailsLabelWithBarcode;
  ics += '%0AEND:VEVENT';
  ics += '%0AEND:VCALENDAR';

  const share = async () => {
    if (navigator.share) {
      await navigator.share({
        text: `Segue o código de barras e link para o boleto.
código de barras: ${barCode.replace(/-/g, ' ')}
${billet ? `link para boleto: ${billet}` : ''}`,
        //url: 'https://fatura.io',
        title: 'Negociação Fatura.io',
      });
      handleEnd('compartilhar', 'Compartilhar código de barras');
    }
  };

  const openGoogleCalendar = () => {
    handleEnd('google_agenda', 'Google Agenda');
    let link = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${eventStart ||
      sDate}%2F${eventEnd || eDate}${eventLocation ? `&location=${encodeURI(eventLocation)}` : ''
      }&text=${encodeURI(eventTitle)}&details=${encodeURI(
        eventDetailsWithBarcode
      )}`;

    openLinkInNewTab(link);
  };

  const handleCopyBarCode = () => {
    handleEnd('copiar_código', 'Copiar código de barras');
    transferTextToClipboard(barCode);
  };

  const openBillet = () => {
    onDownloadBillet();

    openLinkInNewTab(billet);
  };

  return (
    <Container>
      <section>
        <h3>Boleto</h3>
        <div className="row">
          {copy && (
            <button onClick={handleCopyBarCode}>
              <img src={Copy} alt="copy" />
              <span>Copiar</span>
            </button>
          )}
          {navigator.share && (
            <button onClick={share}>
              <img src={Share} alt="share" />
              <span>Compartilhar</span>
            </button>
          )}
          {billet && (
            <button onClick={openBillet}>
              <FcDownload size={20} />
              <span>Baixar</span>
            </button>
          )}
        </div>
      </section>
      {!noCalendar && (
        <section>
          <h3>Adicionar lembrete</h3>
          <div className="card">
            <div className="calendar">
              <span>
                {format(eventEnd || eventDay, 'MMM', {
                  locale: ptBR,
                }).toUpperCase()}
              </span>
              <span className="red">
                {format(eventEnd || eventDay, 'd', {
                  locale: ptBR,
                })}
              </span>
            </div>
            <h6>{eventTitle}</h6>
            <div className="icon-group">
              <FiClock size={12} color="#808080" />
              <span>
                {format(eventEnd || eventDay, "dd 'de' MMMM 'de' yyyy", {
                  locale: ptBR,
                })}
              </span>
            </div>
            <div className="icon-group">
              <FiFile size={12} color="#808080" />
              <span>{eventDetailsLabel}</span>
            </div>
          </div>
          <div className="row">
            <button onClick={openGoogleCalendar}>
              <img src={GoogleCalendar} alt="google-calendar" />
              <span>Agenda</span>
            </button>
            <a
              href={`data:text/calendar;charset=utf8,${ics}`}
              download="lembrete_fatura"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleEnd('baixar_ics', 'Baixar .ics')}
            >
              <FcCalendar size={20} />
              <span>Outlook e Apple</span>
            </a>
          </div>
        </section>
      )}
    </Container>
  );
}

export default ExportV2;
